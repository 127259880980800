import React, { useState, useEffect, useContext } from "react";
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ProductContext } from "./context/AppContext";
import { Link, useParams, useLocation } from "react-router-dom";


import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Accordion from 'react-bootstrap/Accordion';
import ProgressBar from 'react-bootstrap/ProgressBar';

function Poll(props) {
  const{handleClickOpen}=props;
  const location = useLocation();
  const pollData = location.state?.pollData || null; // Access the passed data

    const [age, setAge] = React.useState('');
    const { Poll11, PollAPI, CreatePoll11, CreatePollGetAPI  } = useContext(ProductContext);

// console.log(CreatePoll11, "CreatePoll11 88");
// console.log(option60, "option60 88");

// const [CreatePoll11, setCreatePoll11] = useState([]);
// const createpoll2 = {
//   NumberOfFieldsView: 50,
//   filters: {},
//   isDownload: false,
//   index: 1,
//   pgSize: 100,
//   templateID: 25,
// };
// function CreatePollGetAPI() {
//   fetch(`${process.env.REACT_APP_BASE_URL}/api/api/getTemplateDataList`, {
//     method: "POST",
//     headers: {
//       "Authorization-Token": "7FaNAN5fgV+7l6gVY6M6vRzNeDPEUJHBDtIibnr1vHQodIJV95ki5i6B/JJCsigzgfA+UO0pJ8Tayd9MfZGV+XLYq26sqtuN7aBKuht7n1kR2AU9Vd37sejq0EZjfLwLVKphTFMzwmWBhcs561HxWQ==",
//       Accept: "application/json, text/plain",
//       "Content-Type": "application/json;charset=UTF-8",
//     },
//     body: JSON.stringify(createpoll2),
//   }).then((result) => {
//     result.json().then((resp) => {
//       setCreatePoll11(resp);

//        console.log(resp, "CreatePollGetAPI");
//     });
//   });
// }

// console.log(Poll11, "Poll11 88");
const option60 = CreatePoll11?.response?.records[0][0]?.value;

const option51 = CreatePoll11?.response?.records[0][4]?.value;
 const option52 = CreatePoll11?.response?.records[0][5]?.value;
 const option53 = CreatePoll11?.response?.records[0][6]?.value;
 const option54 = CreatePoll11?.response?.records[0][7]?.value;
 const option55 = CreatePoll11?.response?.records[0][8]?.value;
 const option56 = CreatePoll11?.response?.records[0][9]?.value;
 const option57 = CreatePoll11?.response?.records[0][10]?.value;
 const option58 = CreatePoll11?.response?.records[0][11]?.value;
 const option59 = CreatePoll11?.response?.records[0][12]?.value;


//  console.log(option51, "option51");
 
 const option101 = Poll11?.response?.records.filter((item) =>
 item.find((field) => field.name === "Question" && field.value === option51)
);
const option102 = Poll11?.response?.records.filter((item) =>
 item.find((field) => field.name === "Question" && field.value === option52)
);
const option103 = Poll11?.response?.records.filter((item) =>
 item.find((field) => field.name === "Question" && field.value === option53)
);
const option104 = Poll11?.response?.records.filter((item) =>
 item.find((field) => field.name === "Question" && field.value === option54)
);
const option105 = Poll11?.response?.records.filter((item) =>
 item.find((field) => field.name === "Question" && field.value === option55)
);
const option106 = Poll11?.response?.records.filter((item) =>
 item.find((field) => field.name === "Question" && field.value === option56)
);
const option107 = Poll11?.response?.records.filter((item) =>
 item.find((field) => field.name === "Question" && field.value === option57)
);
const option108 = Poll11?.response?.records.filter((item) =>
 item.find((field) => field.name === "Question" && field.value === option58)
);
const option109 = Poll11?.response?.records.filter((item) =>
 item.find((field) => field.name === "Question" && field.value === option59)
);

const option101Total = option101?.length ?? 0;
const option102Total = option102?.length ?? 0;
const option103Total = option103?.length ?? 0;
const option104Total = option104?.length ?? 0;
const option105Total = option105?.length ?? 0;
const option106Total = option106?.length ?? 0;
const option107Total = option107?.length ?? 0;
const option108Total = option108?.length ?? 0;
const option109Total = option109?.length ?? 0;

// const now = {option101Total};
const Option1Percentage = (option101Total / 100) * 100;
const Option2Percentage = (option102Total / 100) * 100;
const Option3Percentage = (option103Total / 100) * 100;
const Option4Percentage = (option104Total / 100) * 100;
const Option5Percentage = (option105Total / 100) * 100;
const Option6Percentage = (option106Total / 100) * 100;
const Option7Percentage = (option107Total / 100) * 100;
const Option8Percentage = (option108Total / 100) * 100;
const Option9Percentage = (option109Total / 100) * 100;


    const handleChange = (event) => {
      setAge(event.target.value);
    };

    const[Name,setName] = useState();
    const [Mid, setMID] = useState(option60);
    const[Email,setEmail] = useState();
  const[Ward,setWard] = useState();
   const[Signup,setSignup] = useState();

   const[Gender,setGender] = useState();
   const[Satet1,setState1] = useState();
    const[District,setDistrict] = useState();
    const[Constituency,setConstituency] = useState();
    const [showResultButton, setShowResultButton] = useState(false);
    const [hasVoted, setHasVoted] = useState(false);
    const incrementMid = () => {
      setMID(Mid);
    };
console.log(option60, "option60");
console.log(Mid, "Mid");

  const signUPpara = {
    templateID: "23",
    "_1": Satet1,
     "_2": Mid,
    "_3": Email,
    "_4": Ward,
    "_5": Signup,
    "_6": Gender,
    "_7": Name,
    "_8": Constituency,
  }
  function UpdateFormAPI() {
    fetch('https://api.dialerp.com/api/api/addUpdateTemplateDataWeb', {
      method: "POST",
      headers: {
        "Authorization-Token": "7FaNAN5fgV+7l6gVY6M6vRzNeDPEUJHBDtIibnr1vHQodIJV95ki5i6B/JJCsigzgfA+UO0pJ8Tayd9MfZGV+XLYq26sqtuN7aBKuht7n1kR2AU9Vd37sejq0EZjfLwLVKphTFMzwmWBhcs561HxWQ==",
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(signUPpara),
    }).then((result) => {
      result.json().then((resp) => {
        // setUpdateForm(resp);
        // const data55 = upcoming22?.response?.records;
        //  window.location.href = "/";
        //  handleClickOpen();
        PollAPI();
        incrementMid();
        setShowResultButton(true);
        console.log(resp, "CPoll");
        // if (!Satet1) {
        //   // Perform the voting logic here, e.g., make an API request
          
        //   // Assuming the voting is successful, set hasVoted to true
        //   setHasVoted(true);
          
        //   // You can also update your UI or show a confirmation message
        //   alert('Thank you for voting!');
        // } else {
        //   alert('You have already voted.');
        // }
        if (!hasVoted) {
          setHasVoted(true);

        }
      });
    });
  }
  useEffect(() => {
    CreatePollGetAPI();
  }, []);
  
  return (
    <section id="about" className="about bbb_viewed bbb_viewed-55">
    <div
      className="container-fluid aos-init aos-animate"
      data-aos="fade-up"
    >
      {/* <div class="section-title">
<h2>The Smart Way for Political Campaign</h2>
</div> */}
      <div className="row content-signup1">
        <div className="col-lg-6 col-md-6 content-signup-div1">
        <div className="">
                  <img className="signupimgae" src="../../images/vote.jpg" width="100%" />
                  </div>
        </div>
        <div className="col-lg-6 col-md-6 election-25"><br />
          <h4 className="signup-h4">{CreatePoll11?.response?.records[0][3]?.value} </h4><br />
          {/* <p>Please fill in this form to create an account.</p> */}
          <div className='signup-form2'>

          <FormControl>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="female"
        name="radio-buttons-group"
        value={Satet1}
          onChange={(e) => setState1(e.target.value)}
      >
        {option51 && <FormControlLabel value={option51} control={<Radio />} label={option51} />}
{option52 && <FormControlLabel value={option52} control={<Radio />} label={option52} />}
{option53 && <FormControlLabel value={option53} control={<Radio />} label={option53} />}
{option54 && <FormControlLabel value={option54} control={<Radio />} label={option54} />}
{option55 && <FormControlLabel value={option55} control={<Radio />} label={option55} />}

{option56 && <FormControlLabel value={option56} control={<Radio />} label={option56} />}
{option57 && <FormControlLabel value={option57} control={<Radio />} label={option57} />}
{option58 && <FormControlLabel value={option58} control={<Radio />} label={option58} />}
{option59 && <FormControlLabel value={option59} control={<Radio />} label={option59} />}

      </RadioGroup>
    </FormControl>
      <div className="vote-22">
         {!hasVoted ? ( 
          <button type="button" className="signupbtn" id="btnsave" onClick={UpdateFormAPI}>
              Vote
            </button>
         ) : (
          <>   
          <button type="button" className="signupbtn66" id="btnsave">
              Vote
            </button>
               <p>You have already voted for {Poll11?.response?.records?.[0][3]?.value}.</p>
          </>
         )}
         </div>

{/* <div className="vote-22">
      {!hasVoted ? (
        <button type="button" className="signupbtn" id="btnsave" onClick={handleVoteClick}>
          Vote
        </button>
      ) : (
        <p>You have already voted.</p>
      )}
    </div> */}
    
            
          
           

          <Accordion defaultActiveKey="1">
      <Accordion.Item eventKey="0">
        <Accordion.Header className="result1">Result</Accordion.Header>
        <Accordion.Body className="body-22">
            {/* {Poll11?.response?.records?.map((poll2,i)=>{
                return( */}
<>
{option51 && 
<p>{option51} <span>({Option1Percentage} Votes) </span>
         <ProgressBar now={Option1Percentage} label={`${Option1Percentage}%`} />
         </p>
}
{option52 &&
         <p>{option52} <span>({Option2Percentage} Votes) </span>
         <ProgressBar now={Option2Percentage} label={`${Option2Percentage}%`} />
         </p>
}
{option53 &&
         <p>{option53} <span>({Option3Percentage} Votes) </span>
         <ProgressBar now={Option3Percentage} label={`${Option3Percentage}%`} />
         </p>
}
{option54 &&
         <p>{option54} <span>({Option4Percentage} Votes) </span>
         <ProgressBar now={Option4Percentage} label={`${Option4Percentage}%`} />
         </p>
}
{option55 &&
         <p>{option55} <span>({Option5Percentage} Votes) </span>
         <ProgressBar now={Option5Percentage} label={`${Option5Percentage}%`} />
         </p>
}
{option56 &&
         <p>{option56} <span>({Option6Percentage} Votes) </span>
         <ProgressBar now={Option6Percentage} label={`${Option6Percentage}%`} />
         </p>
}
{option57 &&
         <p>{option57} <span>({Option7Percentage} Votes) </span>
         <ProgressBar now={Option7Percentage} label={`${Option7Percentage}%`} />
         </p>
}
{option58 &&
         <p>{option58} <span>({Option8Percentage} Votes) </span>
         <ProgressBar now={Option8Percentage} label={`${Option8Percentage}%`} />
         </p>
}
{option59 &&
         <p>{option59} <span>({Option9Percentage} Votes) </span>
         <ProgressBar now={Option9Percentage} label={`${Option9Percentage}%`} />
         </p>
}
</>
                {/* )
            })}
          */}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
 
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}


export default Poll
