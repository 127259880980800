import React, { Component, useEffect, useState } from "react";
import Footer from '../Footer'
import Header from '../Header'
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function TermsCondition() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  return (
    <>
          <Header />
    <div className="about-us">
      <Container className='about-2'>
        <Row>
          <Col xs={12} md={12} lg={12}>
            <div className=''>
            <h2><b>Terms & Conditions</b></h2>
            <p>
            Last updated on October 10, 2021
            </p>
            </div>
            </Col>  
            <Col xs={12} md={12} lg={12}>
<h3>Terms of use</h3>
<p>“Blinkit” is a trademark of “Blink Commerce Private Limited” (formerly known as Grofers India Private Limited), a company incorporated under the Companies Act, 2013 with its registered office at  Plot 64H, Sector 18, Gurgaon – 122001 (Company).
</p>
<p>The domain name www.blinkit.com  is owned by the Company. The Company holds a FSSAI license, bearing no. 10018064001545. Please feel free to reach out to us at our Customer Care helpline: 1-800-208-8888 (Toll Free).
</p>
<p>We would like to inform you that, we have undergone re-branding exercise and pursuant to the same our brand name has been changed from ‘grofers’ to ‘blinkit’, with effect from December 13, 2021. To that end, we are in the process of modifying our terms & conditions, policies and relevant documents. We request you to kindly keep watching this space for any further updates in this regard.
</p>
<p>"Blinkit" is owned & managed by "Blink Commerce Private Limited” (formerly known as Grofers India Private Limited) and is not related, linked or interconnected in whatsoever manner or nature, to “GROFFR.COM” which is a real estate services business operated by “Redstone Consultancy Services Private Limited”.
</p>
<h3>acceptance of terms</h3>
<p>Thank you for using Blinkit. These Terms of Use (the "Terms") are intended to make you aware of your legal rights and responsibilities with respect to your access to and use of the Blinkit website at www.blinkit.com (the "Site") and any related mobile or software applications ("Blinkit Platform") including but not limited to delivery of information via the website whether existing now or in the future that link to the Terms (collectively, the "Services").
</p>
<p>These Terms are effective for all existing and future Blinkit customers. By accessing this site (hereinafter the “Marketplace”), you agree to be bound by the same and acknowledge that it constitutes an agreement between you and the Company (hereinafter the “User Agreement”). You may not use the Services if you do not accept the Terms or are unable to be bound by the Terms. Your use of the Blinkit Platform is at your own risk, including the risk that you might be exposed to content that is objectionable, or otherwise inappropriate.
</p>
<p>This document is published in accordance with the provisions of Rule 3 of the Information Technology (Intermediaries Guidelines) Rules, 2011. The User Agreement may be updated from time to time by the Company without notice. It is therefore strongly recommended that you review the User Agreement, as available on the Marketplace, each time you access and/or use the Marketplace.
</p>
<p>The terms ‘visitor(s)’, ‘user(s)’, ‘you’ hereunder refer to the person visiting, accessing, browsing through and/or using the Marketplace at any point in time.
</p>
<p>Should you have need clarifications regarding the Terms of Use, please do write to us at at info@blinkit.com
</p>
<h3>services overview</h3>
<p>The Marketplace is a platform for domestic consumers to transact with third party sellers, who have been granted access to the Marketplace to display and offer products for sale through the Marketplace. For abundant clarity, the Company does not provide any services to users other than providing the Marketplace as a platform to transact at their own cost and risk, and other services as may be specifically notified in writing.
</p>
<p>The Company is not and cannot be a party to any transaction between you and the third party sellers, or have any control, involvement or influence over the products purchased by you from such third party sellers or the prices of such products charged by such third-party sellers. The Company therefore disclaims all warranties and liabilities associated with any products offered on the Marketplace.
</p>
<p>Services on the Marketplace are available to only select geographies in India, and are subject to restrictions based on business hours and days of third party sellers.
</p>
<p>Transactions through the Marketplace may be subject to a delivery charge where the minimum order value is not met. You will be informed of such delivery charges at the stage of check-out for a transaction through the Marketplace.
</p>
<p>Transactions through the Marketplace may be subject to a convenience & safety fee. You will be informed of such convenience & safety fee at the stage of check-out for a transaction through the Marketplace.
</p>
<h3>eligibility</h3>
<p>Persons who are “incompetent to contract” within the meaning of the Indian Contract Act, 1872 including minors, undischarged insolvents etc. are not eligible to use/access the Marketplace.
</p>
<p>However, if you are a minor, i.e. under the age of 18 years, you may use/access the Marketplace under the supervision of an adult parent or legal guardian who agrees to be bound by these Terms of Use. You are however prohibited (even under provision) from purchasing any product(s) which is for adult consumption, the sale of which to minors is prohibited.
</p>
<p>The Marketplace is intended to be a platform for end-consumers desirous of purchasing product(s) for domestic self-consumption. If you are a retailer, institution, wholesaler or any other business user, you are not eligible to use the Marketplace to purchase products from third-party sellers, who have been granted access to the Marketplace to display and offer their products for sale through the Marketplace.
</p>
<p>The Company, in its sole discretion and without liability, reserves the right to terminate or refuse your registration, or refuse to permit use/access to the Marketplace, if: (i) it is discovered or brought to notice that you do not conform to the eligibility criteria, or (ii) the Company has reason to believe (including through evaluating usage patterns) that the eligibility criteria is not met/is violated by a user, or (iii) may breach the terms of this User Agreement.
</p>
<p>In order to determine compliance with eligibility criteria, the Company inter alia uses an algorithm and/or pre-determined criteria based technology and accordingly, from time to time, your usage may be restricted or blocked on account of overlap with such algorithms/predetermined criteria. In such cases, if you are a genuine domestic user of the Platform, please contact us for assistance.
</p>
<h3>licence & access</h3>
<p>The Company grants you a limited sub-license to access and make personal use of the Marketplace, but not to download (other than page caching) or modify it, or any portion of it, except with express prior written consent of the Company. Such limited sublicense does not include/permit any resale or commercial use of the Marketplace or its contents; any collection and use of any product listings, descriptions, or prices; any derivative use of the Marketplace or its contents; any downloading or copying of information for the benefit of another third party; or any use of data mining, robots, or similar data gathering and extraction tools.
</p>
<p>The Marketplace or any portion of the Marketplace may not be reproduced, duplicated, copied, sold, resold, visited, or otherwise exploited for any commercial purpose without express prior written consent of the Company
</p>
<p>You may not frame or utilize framing techniques to enclose any trademark, logo, or other proprietary information (including images, text, page layout, or form) of the Marketplace or of the Company and/or its affiliates without the express prior written consent of the Company.
</p>
<p>You may not use any meta tags or any other “hidden text” utilizing the Company’s name or trademarks without the express prior written consent of the Company.
</p>
<p>You shall not attempt to gain unauthorized access to any portion or feature of the Marketplace, or any other systems or networks connected to the Marketplace or to any server, computer, network, or to any of the services offered on or through the Marketplace, by hacking, ‘password mining’ or any other illegitimate means.
</p>
<p>You hereby agree and undertake not to host, display, upload, modify, publish, transmit, update or share any information which:
</p>
<ul>
  <li>Belongs to another person and to which you do not have any right;
</li>
  <li>Is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libelous, invasive of another’s privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever;
</li>
  <li>Harms minors in any way;
</li>
  <li>Infringes any patent, trademark, copyright or another proprietary/intellectual property right;
</li>
  <li>Violates any law for the time being in force;
</li>
  <li>Deceives or misleads the addressee about the origin of such messages communicates any information which is grossly offensive or menacing in nature;
</li>
  <li>Contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource;
</li>
  <li>Threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offense or prevents investigation of any offense or is insulting any other nation;
</li>
  <li>Is misleading or known to be false in any way.
</li>
</ul>
<p>Any unauthorized use shall automatically terminate the permission or sub-license granted by the Company.
</p>
<h3>account & registration obligations</h3>
<p>All users must register and log in for placing orders on the Marketplace. You must keep your account and registration details current and correct for all communications related to your purchases from the Marketplace. By agreeing to the Terms of Use, you agree to receive promotional or transactional communication and newsletters from the Company and its partners. You can opt out from such communication and/or newsletters either by contacting the customer services team of Marketplace or by  placing a request for the same.
</p>
<p>As part of the registration process on the Marketplace, the Company may collect the following personally identifiable information about you, including but not limited to name, email address, age, address, mobile phone number, and other contact details, demographic profile (like your age, gender, occupation, education, address etc.) and information about the pages on the Marketplace you visit/access, the links you click on the Marketplace, the number of times you access a particular page/feature and any such information. Information collected about you is subject to the privacy policy of the Company (https://blinkit.com/privacy), which is incorporated in these Terms of Use by reference.
</p>
<h3>pricing</h3>
<p>The Company aims to ensure that prices of all products offered for sale are true and correct. However, from time to time, the prices of certain products may not be current or may be inaccurate on account of technical issues, typographical errors or incorrect product information provided to the Company by third-party sellers. In each such case, notwithstanding anything to the contrary, the Company reserves the right to cancel the order without any further liability.
</p>
<p>Subject to the foregoing, the price mentioned at the time of ordering a product shall be the price charged at the time of delivery, provided that no product offered for sale on the Marketplace will be sold at a price higher than its MRP (maximum retail price).
</p>
<h3>advertising</h3>
<p>1. Some of the Services are supported by advertising revenue and may display advertisements and promotions. These advertisements may be targeted to the content of information stored on the Services, queries made through the Services or other information. The manner, mode and extent of advertising by Blinkit on the Services are subject to change without specific notice to you. In consideration for Blinkit granting you access to and use of the Services, you agree that Blinkit may place such advertising on the Services</p>
<p>2. Part of the site may contain advertising information or promotional material or other material submitted to Blinkit by third parties or Customers. Responsibility for ensuring that material submitted for inclusion on the Marketplace and / or Blinkit Platform complies with applicable international and national law is exclusively on the party providing the information/material. Your correspondence or business dealings with, or participation in promotions of, advertisers other than Blinkit found on or through the Marketplace and / or Blinkit Platform, including payment and delivery of related goods or services, and any other terms, conditions, warranties or representations associated with such dealings, shall be solely between you and such advertiser. Blinkit will not be responsible or liable for any error or omission, inaccuracy in advertising material or any loss or damage of any sort incurred as a result of any such dealings or as a result of the presence of such other advertiser(s) on the Marketplace and / or Blinkit Platform.
</p>
<p>3. For any information related to a charitable campaign ("Charitable Campaign") sent to Customers and/or displayed on the Marketplace and / or Blinkit Platform where Customers have an option to donate money by way of (a) payment on a third party website; or (b) depositing funds to a third party bank account, Blinkit is not involved in any manner in the collection or utilisation of funds collected pursuant to the Charitable Campaign. Blinkit does not accept any responsibility or liability for the accuracy, completeness, legality or reliability of any information related to the Charitable Campaign. Information related to the Charitable Campaign is displayed for informational purposes only and Customers are advised to do an independent verification before taking any action in this regard.
</p>
<h3>disclaimers</h3>
<ul>
  <li>You acknowledge and undertake that you are accessing the services on the website and transacting at your own risk and are using your best and prudent judgement before entering into any transactions. The Company makes best efforts to display available products, including in respect of their colour, size, shape and appearance, as accurately as possible. However, the actual colour, size, shape and appearance may have variations from the depiction on your mobile/computer screen.
</li>
<li>The Company does not make any representation or warranties in respect of the products available on the Marketplace nor does the Company implicitly or explicitly support or endorse the sale or purchase of any products on the Marketplace. The Company accepts no liability for any error, inaccuracy or omission of third parties or for information/inputs received from third parties and provided to you through the Marketplace or otherwise.
</li>
<li>You acknowledge and agree that the Company shall not, at any time, have any right, title or interest to any product(s), which is subject to a bipartite sale and purchase contract between you and the relevant third party seller. The Company does not have any control over the quality, failure to provide or any other aspect whatsoever of the product(s) and is not responsible for damages or delays on account of products which are out of stock, unavailable or back ordered.
</li>
</ul>
<h3>reviews, feedback, submissions</h3>
<ul>
  <li>All reviews, comments, feedback, suggestions, ideas, and other submissions disclosed, submitted or offered on the Marketplace or otherwise disclosed, submitted or offered in connection with use of the Marketplace (collectively, the Comments) shall be and remain the property of the Company. Such disclosure, submission or offer of any Comments shall constitute an assignment to the Company of all worldwide rights, titles and interests in all copyrights and other intellectual properties in the Comments. Thus, the Company shall exclusively own all such rights, titles and interests in the Comments and shall not be limited in any way in its use, commercial or otherwise.
</li>
<li>The Company will be entitled to use, reproduce, disclose, modify, adapt, create derivative works from any Comments, and publish, display and distribute any Comments submitted for any purpose whatsoever without restriction and without compensating the user in any way. The Company is and shall be under no obligation to:
<ul>
  <li>(i) maintain any Comments in confidence; or
</li>
<li>(ii) pay compensation for any Comments; or
</li>
<li>(iii) respond to any Comments. You agree that any Comments submitted by you on the Marketplace will not violate the Terms of Use or any right of any third party, including copyright, trademark, privacy or other personal or proprietary right(s), and will not cause injury to any person or entity. You further agree that no Comments submitted by you on the Marketplace will be or contain libellous or otherwise unlawful, threatening, abusive or obscene material, or contain software viruses, political campaigning, commercial solicitation, chain letters, mass mails or any form of ‘spam’. The Company does reserve the right (but assumes no obligation) to monitor, edit and/or remove any Comments submitted on the Marketplace. You hereby grant the Company the right to use names that you submit in connection with any Comments. You agree not to use a false email address, impersonate any person or entity, or otherwise mislead as to the origin of any Comments you submit. You are, and shall remain, responsible for the content of any Comments you make and you agree to indemnify the Company and its affiliates against all claims, loss and liabilities resulting from any Comments you submit.
</li>
</ul>
</li>
<li>Further, any reliance placed on Comments available on the Marketplace from a third party shall be at your sole risk and expense.
</li>
</ul>
<h3>copyright & trademark</h3>
<ul>
  <li>The Company, its suppliers and licensors expressly reserve all intellectual property rights in all text, programs, products, processes, technology, images, content and other materials which appear on the Marketplace. Access to or use of the Marketplace does not confer and should not be considered as conferring upon anyone any license, sub-license to the Company’s intellectual property rights. All rights, including copyright, in and to the Marketplace are owned by or licensed to the Company. Any use of the Marketplace or its contents, including copying or storing it or them in whole or part is prohibited without the express prior written consent of the Company
</li>
<li>You may not modify, distribute or re-post anything on the Marketplace for any purpose. The names and logos and all related product and service names, design marks and slogans are the trademarks/service marks of the Company, its affiliates, its partners or its suppliers/service providers. All other marks are the property of their respective owners. No trademark or service mark license is granted in connection with the materials contained on the Marketplace. Access to or use of the Marketplace does not authorize anyone to use any name, logo or mark in any manner. References on the Marketplace to any names, marks, products or services of third parties or hypertext links to third party sites or information are provided solely as a convenience to you after having express consent from third parties and do not in any way constitute or imply the Company’s endorsement, sponsorship or recommendation of the third party, the information, its product or services
</li>
<li>The Company is not responsible for the content of any third-party sites and does not make any representations regarding the content or accuracy of material on such sites. If you decide to access a link of any third-party websites, you do so entirely at your own risk and expense.
</li>
</ul>
  <h3>report intellectual property rights infringement</h3>         
<p>"Blink Commerce Private Limited” (formerly known as Grofers India Private Limited) and its affiliates respect the intellectual property(ies) of other entities. If you believe that your intellectual property rights have been violated/used in any manner which results in infringement, then please inform us at grievance.officer@blinkit.com
</p>
<p>We will evaluate the contents of your notice and will respond within a reasonable period of time. We may take such action as we deem appropriate in keeping with applicable law and our policies, we may also reach out to you for additional details. It may be noted that reporting inaccurate, false or misleading information to Blinkit may result in civil and/or criminal liability.
</p>
<p>You agree to indemnify Blinkit for all claims brought by a third party(ies) against Blinkit arising out of or in connection with the submission of your email at grievance.officer@blinkit.com
</p>
<h3>objectionable material</h3>
<p>You understand that by using the Marketplace or any services provided on the Marketplace, you may encounter content that may be deemed by some to be offensive, indecent, or objectionable, which content may or may not be identified as such. You agree to use the Marketplace and any service at your sole risk and that to the fullest extent permitted under applicable law, the Company and its affiliates shall have no liability to you for any content that may be deemed offensive, indecent, or objectionable to you.
</p>
<h3>indemnity</h3>
<p>You agree to defend, indemnify and hold harmless the Company, its employees, directors, officers, agents and their successors and assigns from and against any and all claims, liabilities, damages, losses, costs and expenses, including attorney’s fees, caused by or arising out of claims based upon a breach of any warranty, representation or undertaking in this User Agreement, or arising out of a violation of any applicable law (including but not limited in relation to intellectual property rights, payment of statutory dues and taxes, claims of libel, defamation, violation of rights of privacy or publicity, loss of service by other subscribers and infringement of intellectual property or other rights). This clause shall survive the expiry or termination of this User Agreement.
</p>
<h3>limitation of liability</h3>
<ul>
  <li>The aggregate liability of the Company, if any, that is established and ordered by a court of competent jurisdiction pursuant to a claim, shall in no event extend beyond refund of the money charged from a user for purchases made pursuant to an order under which such liability has arisen and been established.
</li>
<li>It is acknowledged and agreed that notwithstanding anything to the contrary, the Company shall not be liable, under any circumstances, whether in contract or in tort, for any indirect, special, consequential or incidental losses or damages, including on grounds of loss of profit, loss of reputation or loss of business opportunities.
</li>
</ul>
<h3>termination</h3>
<ul>
  <li>This User Agreement is effective unless and until terminated, either by you or by the Company. You may terminate this User Agreement at any time, provided that you discontinue any further use of the Marketplace. The Company may terminate this User Agreement at any time and may do so immediately without notice, and accordingly deny you access to the Marketplace.
</li>
<li>The Company’s right to any comments and to be indemnified pursuant to the terms hereof, shall survive any termination of this User Agreement. Any such termination of the User Agreement shall not cancel your obligation to pay for product(s) already ordered from the Marketplace or affect any liability that may have arisen under the User Agreement prior to the date of termination.
</li>
</ul>
<h3>governing & jurisdiction</h3>
<ul>
  <li>The User Agreement shall be governed by and construed in accordance with the laws of India, without giving effect to the principles of conflict of laws thereunder.
</li>
<li>Any dispute or difference, whether on the interpretation or otherwise, in respect of any terms hereof shall be referred to an independent arbitrator to be appointed by the Company. Such an arbitrator’s decision shall be final and binding on the parties. The arbitration shall be in accordance with the Arbitration and Conciliation Act, 1996, as amended or replaced from time to time. The seat of arbitration shall be Delhi and the language of the arbitration shall be English.
</li>
<li>Subject to the aforesaid, the Courts at Delhi shall have exclusive jurisdiction over any proceedings arising in respect of the User Agreement.
</li>
</ul>
<h3>grievance redressal mechanism</h3>
<p>In accordance with Information Technology Act, 2000 and the Information Technology (Intermediaries Guidelines) Rules, 2011 and the Consumer Protection Act,2019 and the Consumer Protection (E-Commerce) Rules, 2020 the name and contact details of the Grievance Officer and Nodal Person who can be contacted for any complaints or concerns pertaining to the Marketplace, including those pertaining to breach of the Terms of Use or any other policies are published as under:
</p>
<p><b>i. Details of the Grievance Officer
</b></p>
<p><b>Dhananjay Shashidharan
</b><br /><span>Grievance Officer
</span></p>

<p>Blink Commerce Private Limited (formerly known as Grofers India Private Limited)
  <br />
  <span>Plot 64H, Sector 18, Gurugram-122011, Haryana
</span><br />
<span><b>Email address: grievance.officer@blinkit.com
</b></span><br />
<span><b>Time</b>: Monday - Friday (09:00 a.m. to 18:00 p.m.)
</span>
</p>

<p><b> ii. Details of the Nodal person
</b></p>
<p><b>Vikramjit Singh
</b><br /><span>Nodal Officer
</span></p>

<p>Blink Commerce Private Limited (formerly known as Grofers India Private Limited)
  <br />
  <span>Plot 64H, Sector 18, Gurugram-122011, Haryana
</span><br />
<span><b>Email address: nodal@blinkit.com
</b></span>
</p>
<p>Please note: Blinkit does not solicit confidential information such as OTP/CVV/PIN NUMBER/Card number either through call or mail or any other means. Please do not reveal these details to fraudsters and imposters claiming to be calling on Blinkit’s behalf. You may report such suspicious activities to info@blinkit.com.
</p>
<h3>communication with the Company</h3>
<p>If you wish to correct or update any information you have provided, you may do so online, on the website itself. Alternatively, you may contact the Company to correct or update such information by sending an email to: info@blinkit.com
</p>
<p>In the event of loss of access to the website, you may contact the Company by sending an email to: info@blinkit.com
</p>
          </Col>
          
        </Row> 
      </Container>
    </div>
    <Container className='about-2 div-15'>
        <Row>

        <Col xs={12} md={12} lg={12}>
            <h5>#1 instant delivery service in India</h5>
            <p>Shop on the go and get anything delivered in minutes. Buy everything from groceries to fresh fruits & vegetables, cakes and bakery items, to meats & seafood, cosmetics, mobiles & accessories, electronics, baby care products and much more. We get it delivered at your doorstep in the fastest and the safest way possible.
</p>
<h5>single app for all your daily needs</h5>
<p>Order thousands of products at just a tap - milk, eggs, bread, cooking oil, ghee, atta, rice, fresh fruits & vegetables, spices, chocolates, chips, biscuits, Maggi, cold drinks, shampoos, soaps, body wash, pet food, diapers, electronics, other organic and gourmet products from your neighbourhood stores and a lot more.
</p>
<h5>order online on Blinkit to enjoy instant delivery magic</h5>
<p>Cities we currently serve: Ahmedabad, Bengaluru, Chandigarh, Chennai, Delhi, Faridabad, Gurgaon, Haridwar, HR-NCR, Hyderabad, Jaipur, Jalandhar, Kanpur, Kolkata, Lucknow, Ludhiana, Meerut, Mohali, Mumbai, Panchkula, Pune, UP-NCR, Vadodara,
</p>
          </Col>
        </Row> 
      </Container>
    <Footer />

    </>
  )
}

export default TermsCondition
