import React, { useEffect, useContext, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import Header from "../Header";
import { ProductContext } from "./context/AppContext";
import { FallbackImage } from "./FallbackImage";
import Service1 from "./Service1";

function AboutUs1() {
  const { OurMinistersData } = useContext(ProductContext);
  const [allproduct2, setAllProduct2] = useState();

  const filteredDataMajorParty = OurMinistersData?.response?.records.filter(
    (item) =>
      item.find(
        (field) =>
          field.name === "Category" && field.value === "Major Political Parties"
      )
  );

  const filteredDatawhoiswho = OurMinistersData?.response?.records.filter(
    (item) =>
      item.find(
        (field) => field.name === "Category" && field.value === "Who is Who"
      )
  );
  const filteredDataAboutUs = OurMinistersData?.response?.records.filter(
    (item) =>
      item.find(
        (field) => field.name === "Category" && field.value === "About Us"
      )
  );

  const filteredDataWeManageYour = OurMinistersData?.response?.records.filter(
    (item) =>
      item.find(
        (field) => field.name === "Category" && field.value === "We Manage Your"
      )
  );
        // console.log(filteredDataWeManageYour, "filteredDataWeManageYour 17");
  const filteredDataSupercharge = OurMinistersData?.response?.records.filter(
    (item) =>
      item.find(
        (field) =>
          field.name === "Category" &&
          field.value === "Supercharge Your Political Compaign"
      )
  );

  
  return (
    <>
      <section id="skills" className="skills bbb_viewed">
        <div
          className="container-fluid aos-init aos-animate"
          data-aos="fade-up"
        >
          <div className="row">
            <div
              className="col-lg-6 pt-4 pt-lg-0 content aos-init aos-animate mv-150"
              data-aos="fade-left"
              data-aos-delay={100}
            >
              <h3>{filteredDataAboutUs?.[0][3]?.value}</h3>
              <p>{filteredDataAboutUs?.[0][8]?.value} </p>
              <p>{filteredDataAboutUs?.[0][9]?.value} </p>
              <p>{filteredDataAboutUs?.[0][10]?.value} </p>
              <p>{filteredDataAboutUs?.[0][11]?.value} </p>
              <p>{filteredDataAboutUs?.[0][12]?.value} </p>
              <p>{filteredDataAboutUs?.[0][13]?.value} </p>
              <p>{filteredDataAboutUs?.[0][14]?.value} </p>

              <p>{filteredDataAboutUs?.[0][15]?.value} </p>
              <p>{filteredDataAboutUs?.[0][16]?.value} </p>

              <p>{filteredDataAboutUs?.[0][17]?.value} </p>
              <p>{filteredDataAboutUs?.[0][18]?.value} </p>

              <p>{filteredDataAboutUs?.[0][19]?.value} </p>
              <p>{filteredDataAboutUs?.[0][20]?.value} </p>
              <p>{filteredDataAboutUs?.[0][21]?.value} </p>
              <p>{filteredDataAboutUs?.[0][22]?.value} </p>
              <p>{filteredDataAboutUs?.[0][23]?.value} </p>
              <p>{filteredDataAboutUs?.[0][24]?.value} </p>
              <p>{filteredDataAboutUs?.[0][25]?.value} </p>
              <p>{filteredDataAboutUs?.[0][26]?.value} </p>
              <p>{filteredDataAboutUs?.[0][27]?.value} </p>
            </div>
            <div
              className="col-lg-6 d-flex align-items-center aos-init aos-animate"
              data-aos="fade-right"
              data-aos-delay={100}
            >
              <FallbackImage
                src={filteredDataAboutUs?.[0][4]?.value}
                className="img-fluid"
                alt=""
                width="100%"
              />
            </div>
            <div
              className="col-lg-12 pt-4 pt-lg-0 content aos-init aos-animate mv-150"
              data-aos="fade-left"
              data-aos-delay={100}
            >
              {/* <p>
            We indulge in Strategic Planning, Mobilization and Organization at
            the grassroots and grasstops. We’ve always laid special emphasis on
            exhaustive geographic and demographic investigation in our Campaign
            Plan. Being data driven and incorporating the latest technology has
            always been at the the heart of our problem solving approach. Filled
            with zest and zeal, our team comprises of experts from different
            areas including: Strategists, Researchers &amp; Content Developers,
            Communicators, Design &amp; Creative thinkers, Audio-Visual
            Production, Media personnel, Event managers, Socio-Politco-Economic
            Analysts and Logistic managers. We are made up of people from the
            leading management and technical institutions like IIMs, IITs and
            VTU and we take pride in the diversity of thought and culture that
            we bring to the table. With this robust team in place, we have
            successfully involved in elections across India for more than 8
            years.
          </p> */}
            </div>
          </div>
        </div>
      </section>
      <section
        id="skills"
        className="skills bbb_viewed bbb_viewed-1 bbb_viewed-55"
      >
        <div
          className="container-fluid aos-init aos-animate"
          data-aos="fade-up"
        >
          <div className="row">
            <h3 className="mv-gov5">
              {filteredDataWeManageYour?.[0][3]?.value}
            </h3>
            <div className="bbb_viewed_slider_container-1">
              {/* <OwlCarousel className='owl-carousel owl-theme bbb_viewed_slider-11' loop margin={10} nav items = {3}
         dots = {false} autoWidth= {true}> */}

              <OwlCarousel
                className="owl-carousel  owl-theme bbb_viewed_slider-11"
                loop
                margin={10}
                nav
                items={3}
                dots={false}
                autoWidth={true}
              >
                {filteredDataWeManageYour?.map((wemanage, index) => {
                  return (
                    <div className="item item1" key={index}>
                      <div className="bbb_viewed_item discount d-flex flex-column align-items-center justify-content-center text-center">
                        <div className="bbb_viewed_image">
                          <FallbackImage src={wemanage?.[4]?.value} alt="" />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>

      <section id="about" className="about bbb_viewed bbb_viewed-1">
        <div
          className="container-fluid aos-init aos-animate"
          data-aos="fade-up"
        >
          {/* <div class="section-title">
    <h2>The Smart Way for Political Campaign</h2>
  </div> */}
          <div className="row content-1">
            <div className="col-lg-12 col-md-12">
              <h3 style={{ textAlign: "center" }}>
                {filteredDataSupercharge?.[0][3]?.value}
              </h3>
              <ul className="poli-ul-1">
                {filteredDataSupercharge?.map((superchange, index) => {
                  return (
                    <li key={index}>
                      <div className="election-22">
                        {superchange?.[5]?.value}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </section>
     <Service1 />
    </>
  );
}

export default AboutUs1;
