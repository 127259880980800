import React, { useState, useContext, useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { FallbackImage } from "./FallbackImage";
import { Link, useParams } from "react-router-dom";
import Banner from "./Banner";

function MLA(props) {
  const { handleClickOpen } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [updateform, setUpdateForm] = useState([]);

  const [BoothNO, setBoothNO] = useState();
  const [Name, setName] = useState();
  const [MobileNo, setMobileNo] = useState();

  const [Gender, setGender] = useState();
  const [Age, setAge] = useState();
  const [District, setDistrict] = useState();
  const [Constituency, setConstituency] = useState();
  const [selectedConstituency, setSelectedConstituency] = useState("");
  const [selectedState, setSelectedState] = useState();

  const signUPpara = {
    templateID: "13",
    _1: BoothNO,
    _2: Name,
    _3: MobileNo,
    _4: Age,
    _5: selectedState,
    _6: District,
    _7: selectedConstituency,
  };
  function UpdateFormAPI() {
    fetch("https://api.dialerp.com/api/api/addUpdateTemplateDataWeb", {
      method: "POST",
      headers: {
        "Authorization-Token":
          "7FaNAN5fgV+7l6gVY6M6vRzNeDPEUJHBDtIibnr1vHQodIJV95ki5i6B/JJCsigzgfA+UO0pJ8Tayd9MfZGV+XLYq26sqtuN7aBKuht7n1kR2AU9Vd37sejq0EZjfLwLVKphTFMzwmWBhcs561HxWQ==",
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(signUPpara),
    }).then((result) => {
      result.json().then((resp) => {
        // setUpdateForm(resp);
        // const data55 = upcoming22?.response?.records;
        //  window.location.href = "/";
        handleClickOpen();
      });
    });
  }

  const productdata = JSON.parse(localStorage.getItem("productdata"));

  const filteredDataMLA = productdata?.response?.records.filter((item) =>
    item.find((field) => field.name === "Category" && field.value === "MLA")
  );
  const uniqueStateNames = new Set();
  const handleClick = (stateName) => {
    if (selectedState === stateName) {
      // If the same state name is clicked again, hide the data

      setSelectedState(null);
    } else {
      // Otherwise, show the data for the clicked state
      setSelectedState(stateName);
      localStorage.setItem("AllData2", JSON.stringify(stateName));
    }
  };

  const FilterConstituency = filteredDataMLA?.filter((item) =>
    item.find(
      (field) => field.name === "State" && field.value === selectedState
    )
  );

  const FilterConstituency22 = filteredDataMLA?.filter((item) =>
    item.find(
      (field) => field.name === "Constituency" && field.value === selectedConstituency
    )
  );
// const district1 = FilterConstituency22?.[0][22]?.value;
  //  console.log(FilterConstituency22, "FilterConstituency22");

  return (
    <div>
      <Header />
      <Banner />

      <div className="bbb_viewed footer-1 footer-55 aboutus-2">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h2 className="aboutus-1">
                Member of the Legislative Assembly (MLA)
              </h2>
            </div>

            <div className="col-sm-12">
              <div className="row">
                <hr />

                <div className="col-sm-6 col-md-6">
                  <label htmlFor="email">
                    <b className="aboutus-1">Name</b>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Name"
                    id="email"
                    name="Name"
                    value={Name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>
                <div className="col-sm-6 col-md-6">
                  <label htmlFor="email">
                    <b className="aboutus-1">Mobile Number</b>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Mobile No."
                    id="email"
                    name="MobileNo"
                    value={MobileNo}
                    onChange={(e) => setMobileNo(e.target.value)}
                    required
                  />
                </div>

                <div className="col-sm-6 col-md-6">
                  <label htmlFor="email">
                    <b className="aboutus-1">Email</b>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Email"
                    required
                    id="email"
                    name="Age"
                    value={Age}
                    onChange={(e) => setAge(e.target.value)}
                  />
                </div>
                <div className="col-sm-6 col-md-6">
                  <label htmlFor="email">
                    <b className="aboutus-1">State</b>
                  </label>
                  <select
                    name="productCategory"
                    id="txtProductCategory"
                    value={selectedState}
                    required
                    onChange={(e) => {
                      const selectedStateName = e.target.value;
                      setSelectedState(selectedStateName);
                    }}
                  >
                    <option value="">--Select State--</option>
                    {filteredDataMLA?.map((ChiefMinisterCM, index) => {
                      const stateNameField = ChiefMinisterCM.find(
                        (field) => field.name === "State"
                      );
                      const stateName = stateNameField
                        ? stateNameField.value
                        : "";

                      if (!uniqueStateNames.has(stateName)) {
                        uniqueStateNames.add(stateName);
                        return (
                          <option key={index} value={stateName}>
                            {stateName}
                          </option>
                        );
                      }
                      return null;
                    })}
                  </select>
                </div>
                <div className="col-sm-6">
                  <label htmlFor="email">
                    <b>District</b>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter District"
                    name="District"
                    id="email"
                    required=""
                    value={District}
                    onChange={(e) => setDistrict(e.target.value)}
                  />
                </div>
                <div className="col-sm-6">
                  <label htmlFor="psw">
                    <b>Constituency</b>
                  </label>
                  <select
                    value={selectedConstituency}
                    onChange={(e) => setSelectedConstituency(e.target.value)}
                  >
                    <option value="">--Select Constituency--</option>
                    {FilterConstituency?.map((productitem12, i) => {
                      return (
                        <option key={i} value={productitem12?.[24]?.value}>
                          {productitem12?.[24]?.value}
                        </option>
                      );
                    })}
                  </select>
                </div>
                

                

                <hr />

                {/* {Constituency?.length > 0 ? ( */}
                  <button className="registerbtn" onClick={UpdateFormAPI}>
                    Submit
                  </button>
                {/* ) : (
                  <button className="registerbtn">Submit</button>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default MLA;
