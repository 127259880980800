import React, { useContext } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { FallbackImage } from "./FallbackImage";
import { ProductContext } from "./context/AppContext";
import { DummyBanner } from "./DummyBanner";

function Banner() {
  const { bannerData } = useContext(ProductContext);
  const filteredDataBanner = bannerData?.response?.records.filter((item) =>
    item.find(
      (field) => field.name === "Title" && field.value === "Front banner"
    )
  );
  return (
    <>
    {filteredDataBanner ? (
      <section className="banner-full11">
      <DummyBanner
        src={filteredDataBanner?.[0][5]?.value}
        width="100%"
        className="banner-mobile"
      />
    </section>
    ) : (
<></>
    )}
    </>
    
  );
}

export default Banner;
