import React, { Component, useEffect, useState } from "react";
import Footer from '../Footer'
import Header from '../Header'

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  return (
    <>
          <Header />
    <div className="about-us">
      <Container className='about-2'>
        <Row>
          <Col xs={12} md={12} lg={12}>
            <div className=''>
            <h2><b>Privacy Policy</b></h2>
            <p>
            Last updated on December 10, 2021
            </p>
            </div>
            </Col>  
            <Col xs={12} md={12} lg={12}>

            <p>
            We i.e. "Blink Commerce Private Limited" (formerly known as Grofers India Private Limited) (“Company”), are committed to protecting the privacy and security of your personal information. Your privacy is important to us and maintaining your trust is paramount.

            </p>
            <p>This privacy policy explains how we collect, use, process and disclose information about you. By using our website/ app/ platform and affiliated services, you consent to the terms of our privacy policy (“Privacy Policy”) in addition to our ‘Terms of Use.’ We encourage you to read this privacy policy to understand the collection, use, and disclosure of your information from time to time, to keep yourself updated with the changes and updates that we make to this policy.

            </p>
            <p>This privacy policy describes our privacy practices for all websites, products and services that are linked to it. However this policy does not apply to those affiliates and partners that have their own privacy policy. In such situations, we recommend that you read the privacy policy on the applicable site.
</p>
            <p>Should you have any clarifications regarding this privacy policy, please write to us at info@blinkart.com

</p>
<h3>the collection, storage and use of information related to you</h3>
            <p>We may automatically store certain information including but not limited to information about your web browser, IP address, cookies stored on your device and other information about you based upon your behaviour on the website. We use this information to do internal research on our users’ demographics, interests and behaviour to better understand, protect and serve our users. This information is compiled and analyzed on an aggregated basis. This information may include the URL that you just came from (whether this URL is on the website or not), which URL you next go to (whether this URL is on the website or not), your computer browser information, your IP address, and other information associated with your interaction with the website.
</p>
            <p>We may also share your Mobile IP/Device IP with third party(ies) and to the best of our knowledge, be-life and representations given to us by these third party(ies) this information is not stored by them.
</p>
            <p>We also collect and store personal information provided by you from time to time on the website/app. We only collect and use such information from you that we consider necessary for achieving a seamless, efficient and safe experience, customized to your needs including:
</p>
<ul className='terms-ul'>
    <li>To enable the provision of services opted by you;</li>
    <li>To communicate necessary account and product/service related information from time to time;</li>
    <li>To allow you to receive quality customer care services;</li>
    <li>To undertake necessary fraud and money laundering prevention checks, and comply with the highest security standards;</li>
    <li>To comply with applicable laws, rules and regulations; and</li>
    <li>To provide you with information and offers on products and services, on updates, on promotions, on related, affiliated or associated service providers and partners, that we believe would be of interest to you</li>
</ul>
            <p>Where any service requested by you involves a third party, such information as is reasonably necessary by the Company to carry out your service request, may be shared with such third party.
</p>
            <p>We also do use your contact information to send you notifications based on the tasks allotted to you and also based on your interests and prior activity. The Company may also use contact information internally to direct its efforts for product improvement, to contact you as a survey respondent, to notify you if you win any contest; and to send you promotional materials from its contest sponsors or advertisers.
</p>
            <p>Contacts Permissions: If you allow us to access your contact list information including you and your contacts’ e-mail ids and any other details saved therein, it enables us to provide social features to you and your friends/contacts such as sharing your orders with your friends/contacts, inviting your contacts to try our app, send across referral links to your friends/contacts, etc. We may also use this information to make recommendations for groceries. This information will be synced from your phone and stored on our servers.

</p>
            <p>Further, you may from time to time choose to provide payment related financial information (credit card, debit card, bank account details, name, billing address, shipping address, phone number etc.) on the website. We are committed to keeping all such sensitive data/information safe at all times and ensure that such data/information is only transacted over secure websites of approved payment gateways which are digitally encrypted and provide the highest possible degree of care available under the technology presently in use.

</p>
            <p>The Company will not use your financial information for any purpose other than to complete a transaction with you. Additionally, your information may be used to communicate with you, screen orders for potential risk or fraud, use of preferences related information for advertising purposes and site optimization.

</p>
            <p>Device information: We may need your device permission to get information about your device, like OS (operating system) name, mobile network, hardware model, unique device identifier, etc. Based on these inputs, we intend to optimize your order experience and thereby serve you better.

</p>
            <p>Location:  This permission enables us to give you the benefit of delivering the product to your address and also to provide location specific deals with personalized in-funnel experience.  
</p>
            <p>​​With Other Users: If you are a delivery partner, this permission enables us to verify your exact location information and locate your present location to align you at the preferred region when you begin or engage in a delivery and track the progress and completion of your trips.

</p>
            <p> This feature/permission helps in connecting the right delivery partner with the right delivery opportunity and the path helps in computing the right distance pay along with providing consumers visibility of delivery partners location during delivery for address interventions.

</p>
            <p>SMS: If you allow us to access your SMS, we read your SMS to autofill or pre-populate ‘OTP’ while making a transaction and to validate your mobile number. This provides you a seamless purchase experience while making an order and you do not need to move out of the app to read the SMS and then enter it in the app.

</p>
            <p>Phone: The app requires access to make phone calls so that you can make phone calls to our customer contact centres directly through the app.

</p>
            <p>Email ID: If you allow us to access your email id then, it enables us to send various deals and offers directly to your email.

</p>
            <p>Photo/ Media/ Files: We take media storage permissions to cache the blinkart images to provide seamless and fast experience while browsing blinkart app.

</p>
            <p>Wi-Fi connection information: When you allow us the permission to detect your Wi-Fi connection, we optimize your experience such as more detailing on maps, better image loading etc.

</p>
            <p>Camera: You can grant camera permission as well to take pictures from your camera like that of the invoice, defective products etc., in our customer chat feature.

</p>
            <p>To the extent possible, we provide you the option of not divulging any specific information that you wish for us not to collect, store or use. You may also choose not to use a particular service or feature on the website/application and opt out of any non-essential communications from the Company.

</p>
            <p>Further, transacting over the internet has inherent risks which can only be avoided by following security practices yourself, such as not revealing account/login related information to any other person and informing our customer care team about any suspicious activity or where your account has/may have been compromised.

</p>
            <p>The Company uses data collection devices such as “cookies” on certain pages of the website to help analyse our web page flow, measure promotional effectiveness, and promote trust and safety. “Cookies” are small data files placed on your hard drive or device that assist us in providing our services and may include an anonymous unique identifier. The Company offers certain features that are only available through the use of a “cookie”.

</p>
            <p>The Company also uses cookies to allow you to enter your password less frequently during a session. Cookies can also help the Company provide information that is targeted to your interests. Most cookies are “session cookies,” meaning that they are automatically deleted from your hard drive at the end of a session. You are always free to decline our cookies if your browser permits, although in that case you may not be able to use certain features on the website and you may be required to re-enter your password more frequently during a session.

</p>
            <p>Additionally, you may encounter “cookies” or other similar files on certain pages of the website that are placed by third parties. The Company does not control the use of cookies by third parties.

</p>
            <p>If you send the Company personal correspondence, such as emails or letters, or if other users or third parties send us correspondence about your activities on the website, the Company may collect such information into a file specific to you.

</p>
            <p>The Company does not retain any information collected for any longer than is reasonably considered necessary by us, or such period as may be required by applicable laws. The Company may be required to disclose any information that is lawfully sought from it by a judicial or other competent body pursuant to applicable laws.

</p>
            <p>The website may contain links to other websites. We are not responsible for the privacy practices of such websites which we do not manage and control

</p>
<h3>
choices available regarding the collection, use and distribution of information
</h3>
            <p>To protect against the loss, misuse and alteration of the information under its control, the Company has in place appropriate physical, electronic and managerial procedures. For example, the Company servers are accessible only to authorized personnel and your information is shared with employees and authorized personnel on a need to know basis to complete the transaction and to provide the services requested by you. Although the Company endeavours to safeguard the confidentiality of your personally identifiable information, transmissions made by means of the Internet cannot be made absolutely secure. By using the website, you agree that the Company will have no liability for disclosure of your information due to errors in transmission and/or unauthorized acts of third parties.
</p>
            <p>We may share certain information with governmental agencies or other third parties, where we are:
</p>
<ul className='terms-ul'>
    <li>Obligated under the applicable laws or in good faith to respond to court orders and processes; or</li>
    <li>Detecting and preventing against actual or potential occurrence of identity theft, fraud, abuse of Services and other illegal acts;
</li>
<li>Responding to claims that an advertisement, posting or other content violates the intellectual property rights of a third party;
</li>
<li>Under a duty to disclose or share your personal data in order to enforce our Terms of Use and other agreements, policies or to protect the rights, property, or safety of the Company, our customers, or others, or in the event of a claim or dispute relating to your use of our Services. This includes exchanging information with other companies and organisations for the purposes of fraud detection and credit risk reduction.
</li>
</ul>
            <p>Please note that the Company will not ask you to share any sensitive data or information via email or telephone. If you receive any such request by email or telephone, please do not respond/divulge any sensitive data or information and forward the information relating to the same to info@blinkart.com for necessary action.

</p>
<h3>changes to the privacy policy
</h3>
            <p>blinkart reserves the right to change this policy from time to time. Any changes shall be effective immediately upon the posting of the revised Privacy Policy. We encourage you to periodically review this page for the latest information on our privacy practices
</p>
<h3>privacy questions and access to your personal information</h3>
<p>If you have questions, concerns, or suggestions regarding our privacy policy, we can be reached using the contact information on our “Contact Us” page or at info@blinkart.com
</p>
<p>In certain cases, you may have the ability to view or edit your personal information online. In the event your information is not accessible online and you wish to obtain a copy of particular information you provided to us, or if you become aware the information is incorrect and you would like us to correct it, please contact us immediately
</p>
<p>Before we are able to provide you with any information or correct any inaccuracies, however, we may ask you to verify your identity and to provide other details to ascertain your identity and to help us to respond to your request. We will contact you within 30 days of your request
</p>
<h3>grievance redressal officer</h3>
<p>If you wish to correct or update any information you have provided, you may do so online, on the website itself. Alternatively, you may contact the Company to correct or update such information by writing to us at info@blinkart.com
</p>
<p>In the event of loss of access to the website, you may contact the Company by writing to us at info@blinkart.com
</p>
<p>In the event that you have a grievance regarding our privacy policy or data usage practices, you may reach out to our Grievance Redressal Officer at the details below:
</p>
<p>Grievance Redressal Officer: <b>Vikramjit Singh</b>

</p>

          </Col>
          
        </Row> 
      </Container>
    </div>
    <Container className='about-2 div-15'>
        <Row>

        <Col xs={12} md={12} lg={12}>
            <h5>#1 instant delivery service in India</h5>
            <p>Shop on the go and get anything delivered in minutes. Buy everything from groceries to fresh fruits & vegetables, cakes and bakery items, to meats & seafood, cosmetics, mobiles & accessories, electronics, baby care products and much more. We get it delivered at your doorstep in the fastest and the safest way possible.
</p>
<h5>single app for all your daily needs</h5>
<p>Order thousands of products at just a tap - milk, eggs, bread, cooking oil, ghee, atta, rice, fresh fruits & vegetables, spices, chocolates, chips, biscuits, Maggi, cold drinks, shampoos, soaps, body wash, pet food, diapers, electronics, other organic and gourmet products from your neighbourhood stores and a lot more.
</p>
<h5>order online on blinkart to enjoy instant delivery magic</h5>
<p>Cities we currently serve: Ahmedabad, Bengaluru, Chandigarh, Chennai, Delhi, Faridabad, Gurgaon, Haridwar, HR-NCR, Hyderabad, Jaipur, Jalandhar, Kanpur, Kolkata, Lucknow, Ludhiana, Meerut, Mohali, Mumbai, Panchkula, Pune, UP-NCR, Vadodara,
</p>
          </Col>
        </Row> 
      </Container>
    <Footer />

    </>
  )
}

export default PrivacyPolicy
