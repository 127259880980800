import React from 'react'
import Header from '../Header'
import Footer from '../Footer'
import { FallbackImage } from './FallbackImage';
import { Link, useParams } from "react-router-dom";
import Banner from './Banner';
import Service1 from './Service1';

function Service(props) {
  
  return (
    <div>
      <Header />
      <Banner />
{/* <section className="gateway-services bbb_viewed bbb_viewed-1 bbb_viewed-2">
    <div className="container-fluid aos-init aos-animate" data-aos="fade-up">
      <div className="row">
        <div className="col-lg-12 col-md-12">
          <h3 className="bbb_viewed_title" style={{ textAlign: "center" }}>
            Our Expertize
          </h3>
        </div>
        <div className="col-lg-4 col-md-4 mt-3">
          <div className="mv-11">
            <div className="mv-hover-class">
              <i className="fa fa-hand-o-right" />
              <p>
                In the connected world we live in, elections in one state cannot
                be treated in isolation from elections in another state.
                Likewise, elections in the 3-tier...
              </p>
              <h4 className="mv-hover-class-h4">Election Monitoring</h4>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 mt-3">
          <div className="mv-11">
            <div className="mv-hover-class">
              <i className="fa fa-hand-o-right" />
              <p>
                A neutral, un-biased Opinion Poll is a very important tool to
                gauge the mood of your voters, if elections were to happen
                today. The Swing...
              </p>
              <h4 className="mv-hover-class-h4">Opinion Polls</h4>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 mt-3">
          <div className="mv-11">
            <div className="mv-hover-class">
              <i className="fa fa-hand-o-right" />
              <p>
                With so much of data moving around and gathered everyday, it is
                all upto how much of sense we can make out of it. As Max Levchin
                said...
              </p>
              <h4 className="mv-hover-class-h4">Data &amp; Analytics</h4>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 mt-3">
          <div className="mv-11">
            <div className="mv-hover-class">
              <i className="fa fa-hand-o-right" />
              <p>
                Campaigning is the most important phase and effectively managing
                it becomes all the more crucial which can eventually decide the
                outcome...
              </p>
              <h4 className="mv-hover-class-h4">Campaign Management</h4>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 mt-3">
          <div className="mv-11">
            <div className="mv-hover-class">
              <i className="fa fa-hand-o-right" />
              <p>
                Successful Campaigns depend a lot on devising innovative methods
                to reach out and convince your electorate. Multiple reach out
                modes...
              </p>
              <h4 className="mv-hover-class-h4">
                Creative &amp; Innovative Content
              </h4>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 mt-3">
          <div className="mv-11">
            <div className="mv-hover-class">
              <i className="fa fa-hand-o-right" />
              <p>
                A successful manifesto creation is all about hearing to the
                needs of your electorate, understanding those needs and making
                sure that you recognise...
              </p>
              <h4 className="mv-hover-class-h4">Manifesto Creation</h4>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 mt-3">
          <div className="mv-11">
            <div className="mv-hover-class">
              <i className="fa fa-hand-o-right" />
              <p>
                The present era can rightfully be called as the “Information
                Era”. Hence Data Analysis and Content Formulation will remain in
                the forefront...
              </p>
              <h4 className="mv-hover-class-h4">
                Content minimum, connect maximum
              </h4>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 mt-3">
          <div className="mv-11">
            <div className="mv-hover-class">
              <i className="fa fa-hand-o-right" />
              <p>
                The truth of the matter is, the world is going digital. And it
                is imperative for you to be present where your target audience
                are present. Hence it is...
              </p>
              <h4 className="mv-hover-class-h4">Digital Marketing</h4>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 mt-3">
          <div className="mv-11">
            <div className="mv-hover-class">
              <i className="fa fa-hand-o-right" />
              <p>
                You have so delicately handcrafted a message which is full of
                potential to create an impact on the minds of your electorate.
                Now, it is all the about...
              </p>
              <h4 className="mv-hover-class-h4">PR &amp; Advertising</h4>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 mt-3">
          <div className="mv-11">
            <div className="mv-hover-class">
              <i className="fa fa-hand-o-right" />
              <p>
                The secret and perhaps the success of your campaign hinges upon
                knowing your electorate better, the ongoing trends along with
                the issues, identifying...
              </p>
              <h4 className="mv-hover-class-h4">Constituency Profiling</h4>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 mt-3">
          <div className="mv-11">
            <div className="mv-hover-class">
              <i className="fa fa-hand-o-right" />
              <p>
                Brand Building or image makeover is all about re-discovering the
                overall purpose of your brand. It is about how you can make
                yourself more relevant...
              </p>
              <h4 className="mv-hover-class-h4">Brand Building</h4>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 mt-3">
          <div className="mv-11">
            <div className="mv-hover-class">
              <i className="fa fa-hand-o-right" />
              <p>
                Leadership is not about a title or a designation. Its about
                impact, influence and inspiration. Great leaders always find a
                way to unite and engage people...
              </p>
              <h4 className="mv-hover-class-h4">Leadership Training</h4>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 mt-3">
          <div className="mv-11">
            <div className="mv-hover-class">
              <i className="fa fa-hand-o-right" />
              <p>
                Finance is generally regarded as the lifeblood of any
                organisation. The success of your Fund Raising campaigns is
                largely dependent on the effective...
              </p>
              <h4 className="mv-hover-class-h4">Fund Raising</h4>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 mt-3">
          <div className="mv-11">
            <div className="mv-hover-class">
              <i className="fa fa-hand-o-right" />
              <p>
                Victor Hugo said: “Nothing can stop an idea whose time has
                come”. So your message or an idea can become unstoppable only
                when it gets converted...
              </p>
              <h4 className="mv-hover-class-h4">Movement Building</h4>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 mt-3">
          <div className="mv-11">
            <div className="mv-hover-class">
              <i className="fa fa-hand-o-right" />
              <p>
                That’s precisely how technological advancements have happened
                over the last couple of decades. We’ve realised that the world
                is transforming fast...
              </p>
              <h4 className="mv-hover-class-h4">Tech Assistance</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>   */}
  <Service1 />

      <Footer />
    </div>
  )
}


export default Service
