import React, { useState, useEffect, useContext } from "react";
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ProductContext } from "./context/AppContext";
import { Link, useParams } from "react-router-dom";

function Signup(props) {
  const{handleClickOpen}=props;

    const [age, setAge] = React.useState('');
    const { headerIcon, headerMenu, headerMenu22 } = useContext(ProductContext);

    const handleChange = (event) => {
      setAge(event.target.value);
    };

    const[Name,setName] = useState();
  const[MobileNo,setMobileNo] = useState();
  const[Email,setEmail] = useState();
  const[Ward,setWard] = useState();
   const[Signup,setSignup] = useState();

   const[Gender,setGender] = useState();
   const[selectedState,setSelectedState] = useState();
    const[District,setDistrict] = useState();
    const[selectedConstituency,setSelectedConstituency] = useState();
 

  const signUPpara = {
    templateID: "16",
    "_1": Name,
     "_2": MobileNo,
    "_3": Email,
    "_4": Ward,
    "_5": Signup,
    "_6": Gender,
    "_7": selectedState,
    "_8": selectedConstituency,
  }
  function UpdateFormAPI() {
    fetch('https://api.dialerp.com/api/api/addUpdateTemplateDataWeb', {
      method: "POST",
      headers: {
        "Authorization-Token": "7FaNAN5fgV+7l6gVY6M6vRzNeDPEUJHBDtIibnr1vHQodIJV95ki5i6B/JJCsigzgfA+UO0pJ8Tayd9MfZGV+XLYq26sqtuN7aBKuht7n1kR2AU9Vd37sejq0EZjfLwLVKphTFMzwmWBhcs561HxWQ==",
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(signUPpara),
    }).then((result) => {
      result.json().then((resp) => {
        // setUpdateForm(resp);
        // const data55 = upcoming22?.response?.records;
        //  window.location.href = "/";
         handleClickOpen();
      });
    });
  }

  const productdata = JSON.parse(localStorage.getItem("productdata"));

  const filteredDataMLA = productdata?.response?.records.filter((item) =>
    item.find((field) => field.name === "Category" && field.value === "MLA")
  );
  const uniqueStateNames = new Set();
  const handleClick = (stateName) => {
    if (selectedState === stateName) {
      // If the same state name is clicked again, hide the data

      setSelectedState(null);
    } else {
      // Otherwise, show the data for the clicked state
      setSelectedState(stateName);
      localStorage.setItem("AllData2", JSON.stringify(stateName));
    }
  };

  const FilterConstituency = filteredDataMLA?.filter((item) =>
    item.find(
      (field) => field.name === "State" && field.value === selectedState
    )
  );

  return (
    <section id="about" className="about bbb_viewed bbb_viewed-55">
    <div
      className="container-fluid aos-init aos-animate"
      data-aos="fade-up"
    >
      {/* <div class="section-title">
<h2>The Smart Way for Political Campaign</h2>
</div> */}
      <div className="row content-signup1">
        <div className="col-lg-6 col-md-6 content-signup-div1">
        <div className="">
                  <img className="signupimgae" src="../../images/vote.jpg" width="100%" />
                  </div>
        </div>
        <div className="col-lg-6 col-md-6 election-25">
          <h4 className="signup-h4">
            Sign Up
          </h4>
          <p>Please fill in this form to create an account.</p>
          <div className='signup-form2'>
          <TextField  id="outlined-basic" label="Name" variant="outlined" value={Name}
          onChange={(e) => setName(e.target.value)} />

          <TextField  id="outlined-basic" label="Mobile" variant="outlined" value={MobileNo}
          onChange={(e) => setMobileNo(e.target.value)} />
          <TextField  id="outlined-basic" label="Email" variant="outlined" value={Email}
          onChange={(e) => setEmail(e.target.value)} />
          <FormControl fullWidth >
        <InputLabel id="demo-simple-select-label">Gender</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          className="select-51"
          id="demo-simple-select"
          label="Age"
          value={Gender}
          onChange={(e) => setGender(e.target.value)}>
            <MenuItem value="">--Select Gender--</MenuItem>
          <MenuItem value="Male">Male</MenuItem>
          <MenuItem value="Female">Female</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">State</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          className="select-51"
          label="Age"
          value={selectedState}
          onChange={(e) => {
            const selectedStateName = e.target.value;
            setSelectedState(selectedStateName);
          }}
          >
          <MenuItem value="">--Select State--</MenuItem>
          {filteredDataMLA?.map((ChiefMinisterCM, index) => {
                      const stateNameField = ChiefMinisterCM.find(
                        (field) => field.name === "State"
                      );
                      const stateName = stateNameField
                        ? stateNameField.value
                        : "";

                      if (!uniqueStateNames.has(stateName)) {
                        uniqueStateNames.add(stateName);
                        return (
                <MenuItem  key={index} value={stateName}>{stateName}
                </MenuItem>
                );
              }
              return null;
            })}
        </Select>
      </FormControl>

      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Constituency</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          className="select-51"
          label="Age"
          value={selectedConstituency}
          onChange={(e) => setSelectedConstituency(e.target.value)}
          >
          <MenuItem value="">--Select Constituency--</MenuItem>
          {FilterConstituency?.map((productitem12, i) => {
                      return (
      <MenuItem key={i} value={productitem12?.[24]?.value}>{productitem12?.[24]?.value}</MenuItem>
      );
                    })}
        </Select>
      </FormControl>

      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Sign up type</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          className="select-51"
          label="Age"
          value={Signup}
          onChange={(e) => setSignup(e.target.value)}>
            <MenuItem value="">--Select Sign up type--</MenuItem>
          <MenuItem value="Volunteer">Volunteer</MenuItem>
          <MenuItem value="Politician">Politician</MenuItem>
        </Select>
      </FormControl>
      <div className="clearfix">
           <Link to = "/"> <button
              type="button"
              className="cancelbtn"
            //   onClick={handleClose}
            >
              Cancel
            </button></Link>
            <button type="button" className="signupbtn" id="btnsave" onClick={UpdateFormAPI}>
              Sign Up
            </button>
          </div>
          <p>Do you already have an <Link to = "/login">Account?</Link></p>

          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Signup
