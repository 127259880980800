import React, {
  Component,
  useEffect,
  useState,
  useRe,
  useContext,
} from "react";
import Header from "../Header";
import Footer from "../Footer";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link, useParams } from "react-router-dom";
import { FallbackImage } from "./FallbackImage";

import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Form from "react-bootstrap/Form";
import Tabs from "react-bootstrap/Tabs";

import ListGroup from "react-bootstrap/ListGroup";
import Tab from "react-bootstrap/Tab";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import Button from "@mui/material/Button";
import { ProductContext } from "./context/AppContext";
import Banner from "./Banner";

function Details(props) {
  const { dataprint } = useContext(ProductContext);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const ViewData = JSON.parse(localStorage.getItem("SingleData"));

  return (
    <div className="">
      <Header />
     <Banner />

      <div className="bbb_viewed See-all2 See-all222 ">
        <div>
          <>
            <Row>
              <Col xs={12} md={8} lg={8}>
                <h2 className="mv-gov2">{ViewData?.[5]?.value}</h2>
                <h3 className="mv-gov3">{ViewData?.[6]?.value}</h3>
                {/* <p>{ViewData?.[6]?.value}</p> */}
                <p>{ViewData?.[7]?.value}</p>
                <p>{ViewData?.[8]?.value}</p>
                <p>{ViewData?.[9]?.value}</p>
              </Col>
              <Col xs={12} md={4} lg={4}>
                <FallbackImage
                  className="mv-pic-1"
                  src={ViewData?.[4]?.value}
                  width="100%"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={12}>
                <p>{ViewData?.[10]?.value}</p>
                <p>{ViewData?.[11]?.value}</p>
                <p>{ViewData?.[12]?.value}</p>
                <p>{ViewData?.[13]?.value}</p>
                <p>{ViewData?.[14]?.value}</p>
                <p>{ViewData?.[15]?.value}</p>
              </Col>
            </Row>
          </>
        </div>
      </div>

      <br />
      <br />
      <Footer />
    </div>
  );
}

export default Details;
