import React, { useState, useEffect, useContext } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { ProductContext } from "./context/AppContext";
import { Link, useParams, useHistory } from "react-router-dom";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Accordion from "react-bootstrap/Accordion";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Title } from "@mui/icons-material";
import AddIcon from '@mui/icons-material/Add';
function CreatePoll(props) {
  const { handleClickOpen } = props;

  const [age, setAge] = React.useState("");
  const { Poll11, PollAPI, CreatePollGetAPI } = useContext(ProductContext);

  const [Title, setTitle] = useState();
  const [Option1, setOption1] = useState();
  const [Option2, setOption2] = useState();
  const [Option3, setOption3] = useState();
  const [Option4, setOption4] = useState();
  const [Option5, setOption5] = useState();
  const [Option6, setOption6] = useState();
  const [Option7, setOption7] = useState();
  const [Option8, setOption8] = useState();
  const [Option9, setOption9] = useState();
  const [Option10, setOption10] = useState();
  const [showResultButton, setShowResultButton] = useState(false);

  const [optionsCount, setOptionsCount] = useState(2); // Start with 2 options (Option 1 and Option 2)
  const [options, setOptions] = useState(['', '']); // Initialize with empty values for the two options

  // Function to add a new option
  const addOption = () => {
    if (optionsCount < 8) { // Limit the number of options to 8
      setOptionsCount(optionsCount + 1);
      setOptions([...options, '']); // Add an empty option


    }
  };

  // Function to handle changes in option input fields
  const handleOptionChange = (index, value) => {
    const updatedOptions = [...options];
    updatedOptions[index] = value;
    setOptions(updatedOptions);
  };
 

  const signUPpara = {
    templateID: "25",
    _1: Title,
    _2: options[0],
    _3: options[1],
    _4: options[2],
    _5: options[3],
    _6: options[4],
    _7: options[5],
    _8: options[6],
    _9: options[7],
    _10: options[8],
    _11: options[9],
    _12: options[10],
  };
  function UpdateFormAPI() {
    fetch("https://api.dialerp.com/api/api/addUpdateTemplateDataWeb", {
      method: "POST",
      headers: {
        "Authorization-Token":
          "7FaNAN5fgV+7l6gVY6M6vRzNeDPEUJHBDtIibnr1vHQodIJV95ki5i6B/JJCsigzgfA+UO0pJ8Tayd9MfZGV+XLYq26sqtuN7aBKuht7n1kR2AU9Vd37sejq0EZjfLwLVKphTFMzwmWBhcs561HxWQ==",
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(signUPpara),
    }).then((result) => {
      result.json().then((resp) => {
        // setPID(pid+1);
        // setPID(pid+1); 
        CreatePollGetAPI();

          // window.location.href = (`/poll/${pollTitle}`);
        console.log(resp, "Poll");
      });
    });
  }
  function generateUniqueId() {
    return Math.floor(Math.random() * 1000000).toString();
  }

  return (
    <section id="about" className="about bbb_viewed bbb_viewed-55">
      <div className="container-fluid aos-init aos-animate" data-aos="fade-up">
      
        <div className="row content-signup1">
          <div className="col-lg-6 col-md-6 content-signup-div1">
            <div className="">
              <img
                className="signupimgae"
                src="../../images/vote.jpg"
                width="100%"
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-6 election-25">
            <br />
            <h4 className="signup-h4">
            Start creating your poll today
            </h4>
            <br />
            {/* <p>Please fill in this form to create an account.</p> */}
            <div className="signup-form2">
              {/* <p>{count1+1}</p> */}
              <TextField
                id="outlined-basic"
                label="Title"
                variant="outlined"
                value={Title}
                onChange={(e) => setTitle(e.target.value)}
              />
{/* 
              <TextField
                id="outlined-basic"
                label="Option 1"
                variant="outlined"
                value={Option1}
                onChange={(e) => setOption1(e.target.value)}
              />
              <TextField
                id="outlined-basic"
                label="Option 2"
                variant="outlined"
                value={Option2}
                onChange={(e) => setOption2(e.target.value)}
              /> */}

{options.map((option, index) => (

 <div>
     <TextField
     key={index}
     id="outlined-basic"
     label={`Option ${index + 1}`}
     variant="outlined"
     placeholder={`Option ${index + 1}`}
            value={option}
            onChange={(e) => handleOptionChange(index, e.target.value)}
   />
 </div>
       
        ))}
       
        {optionsCount < 8 && (
          <button type="button" className="createpoll1" onClick={addOption}><AddIcon className="add-poll-icon"  />Add Option</button>
        )}
              {/* <TextField
                id="outlined-basic"
                label="Option 3"
                variant="outlined"
                value={Option3}
                onChange={(e) => setOption3(e.target.value)}
              />
              <TextField
                id="outlined-basic"
                label="Option 4"
                variant="outlined"
                value={Option4}
                onChange={(e) => setOption4(e.target.value)}
              />
              <TextField
                id="outlined-basic"
                label="Option 5"
                variant="outlined"
                value={Option5}
                onChange={(e) => setOption5(e.target.value)}
              />
              <TextField
                id="outlined-basic"
                label="Option 6"
                variant="outlined"
                value={Option6}
                onChange={(e) => setOption6(e.target.value)}
              />
              <TextField
                id="outlined-basic"
                label="Option 7"
                variant="outlined"
                value={Option7}
                onChange={(e) => setOption7(e.target.value)}
              />
              <TextField
                id="outlined-basic"
                label="Option 8"
                variant="outlined"
                value={Option8}
                onChange={(e) => setOption8(e.target.value)}
              /> */}

{Title ? (
  <div className="vote-22">
  <Link to ={`../poll/${Title?.replace(/ /g, '-')}`}>
    <button
    type="button"
    className="signupbtn"
    id="btnsave"
    onClick={()=>{UpdateFormAPI();
      CreatePollGetAPI()}}
  >
    Create Poll
  </button></Link>
</div>
) : (
  <div className="vote-22">
                <button
                  type="button"
                  className="signupbtn"
                  id="btnsave"
                >
                  Create Poll
                </button>
              </div>
)}
              

            
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CreatePoll;
