import React,{useContext} from 'react'
import Header from '../Header'
import Footer from '../Footer'
import { FallbackImage } from './FallbackImage';
import { Link, useParams } from "react-router-dom";
import Banner from './Banner';
import { ProductContext } from "./context/AppContext";

function Surveys(props) {
  const { Surveys1 } = useContext(ProductContext);
  const surveyData=Surveys1?.response?.records


  return (
    <div>
      <Header />
     <Banner />
<div className="bbb_viewed footer-1 main-footer aboutus-2">
  <div className="container-fluid">
    {surveyData?.map((survey2,i)=>{
      return(
    <div className="row">
      <div className="col-sm-12">
        <h2 className="mv-gov2">{survey2?.[3]?.value}</h2>
      </div>
      <div className="col-sm-8">
        <p className="p-11">{survey2?.[4]?.value} </p>
        <p>{survey2?.[5]?.value}</p>
        <p>{survey2?.[6]?.value}</p>
        <p>{survey2?.[7]?.value}</p>
        <h2 className="mv-gov2">{survey2?.[9]?.value}</h2>
        <p>{survey2?.[10]?.value}</p>
        <h2 className="mv-gov2">{survey2?.[15]?.value}</h2>
        <p>{survey2?.[16]?.value}</p>
      </div>
      <div className="col-sm-4">
        <FallbackImage className="bjp-1" src={survey2?.[44]?.value} width="100%" />
      </div>

      <div className="col-sm-12">
        <h2 className="mv-gov2">{survey2?.[26]?.value}</h2>
        <p className="p-11">{survey2?.[27]?.value}</p>
        <h2 className="mv-gov2">{survey2?.[32]?.value}</h2>
        <p className="p-11">{survey2?.[33]?.value}</p>
        <h2 className="mv-gov2">{survey2?.[38]?.value}</h2>
        <p className="p-11">{survey2?.[39]?.value}</p>
      </div>
    </div>
       )
      })}
  </div>
</div>


      <Footer />
    </div>
  )
}

export default Surveys
