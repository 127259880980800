import React, { useEffect, useContext, useState } from "react";

import { FallbackImage } from './FallbackImage';
import { Link, useParams } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import { ProductContext } from './context/AppContext';
function LetestVideo() {
    const { videoData } = useContext(ProductContext);
    const video2 = videoData?.response?.records;

  return (
    <div className="bbb_viewed">
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <div className="bbb_main_container">
                  <div className="bbb_viewed_title_container">
                    <h3 className="bbb_viewed_title">
                      Latest Video
                      <span className="subscribe">
                        <i className="fa fa-bell" />
                        <Link
                          to="https://www.youtube.com/channel/UCrKt_MLTF-AhQNl9X0_1rBA"
                          target="_blank"
                        >
                          Please SubScribe
                        </Link>
                      </span>
                    </h3>
                  </div>
                  <div className="bbb_viewed_slider_container">
                    <OwlCarousel
                      className="owl-theme bbb_viewed_slider"
                      loop
                      margin={5}
                      nav
                      items={7}
                      dots={false}
                      autoWidth={true}
                    >
                      {video2?.map((video1, index) => {
                        return (
                          <div className="item item1" key={index}>
                            <div className="bbb_viewed_item discount d-flex flex-column align-items-center justify-content-center text-center">
                              <div className="bbb_viewed_image bbb_viewed_image-22">
                                <a href={video1?.[5]?.value} target="_blank">
                                  <FallbackImage
                                    src={video1?.[4]?.value}
                                    alt=""
                                  />
                                </a>
                              </div>
                              <div className="bbb_viewed_name-22">
                                <a href={video1?.[5]?.value} target="_blank">
                                  {video1?.[3]?.value}
                                </a>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </OwlCarousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  )
}

export default LetestVideo
