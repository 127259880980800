import {useEffect, useState} from 'react'
import Image from 'react-bootstrap/esm/Image'
export const DummyBanner=({src, ...rest})=>{
    const [imgSrc, setImgSrc]=useState(src)

    useEffect(()=>{
    setImgSrc(src)
    },[src])

    return(
        <Image
        {...rest}
        src={imgSrc ? imgSrc : '../../images/dummy-banner.jpeg'}
        onError={()=>{
        setImgSrc('../../images/dummy-banner.jpeg')
        }
        }
    
        />
    )

}
   

