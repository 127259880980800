import React, { useEffect, useContext, useState } from "react";
import Header from '../Header'
import Footer from '../Footer'
import { FallbackImage } from './FallbackImage';
import { Link, useParams } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import { ProductContext } from './context/AppContext';
import Banner from "./Banner";

function SurveyForm(props) {
  const{handleClickOpen}=props;

  const { Formfield } = useContext(ProductContext);


      const [updateform, setUpdateForm] = useState([]);
  
  const[BoothNO,setBoothNO] = useState();
  const[Name,setName] = useState();
  const[MobileNo,setMobileNo] = useState();

  const[Gender,setGender] = useState();
  const[Age,setAge] = useState();
  const[Occupation,setOccupation] = useState();
  const[PartyName,setPartyName] = useState();
  const[Mukhiya,setMukhiya] = useState();
  const[MukhiyaPercentOfHappy,setMukhiyaPercentOfHappy] = useState();

  const[Parshad,setParshad] = useState();
  const[ParshadPercentOfHappy,setParshadPercentOfHappy] = useState();
  const[MLA,setMLA] = useState();
  const[MLAPercentOfHappy,setMLAPercentOfHappy] = useState();
  const[CM,setCM] = useState();
  const[CmPercentOfHappy,setCmPercentOfHappy] = useState();
  const[AnyProblem,setAnyProblem] = useState();

  
  const signUPpara = {
    templateID: "24",
    "_1": BoothNO,
     "_2": Name,
    "_3": MobileNo,
    "_4": Gender,
    "_5": Age,
    "_6": Occupation,
    "_7": PartyName,
    "_8": Mukhiya,
    "_9": MukhiyaPercentOfHappy,
     "_10": Parshad,
    "_11": ParshadPercentOfHappy,
    "_12": MLA,
    "_13": MLAPercentOfHappy,
    "_14": CM,
    "_15": CmPercentOfHappy,
    "_16": AnyProblem
    // , "_17": TotalCountOfMale	
    // , "_18": TotalCountOfFemale	
    // , "_19": InFavorofParty
    // , "_20": InFavorofDesignation	
    // , "_21": Vidhansabha
    // , "_22": ZILA	
    // , "_23": DoneBy
    // , "_24": VerifiedBy
  }
  function UpdateFormAPI() {
    fetch('https://api.dialerp.com/api/api/addUpdateTemplateDataWeb', {
      method: "POST",
      headers: {
        "Authorization-Token": "7FaNAN5fgV+7l6gVY6M6vRzNeDPEUJHBDtIibnr1vHQodIJV95ki5i6B/JJCsigzgfA+UO0pJ8Tayd9MfZGV+XLYq26sqtuN7aBKuht7n1kR2AU9Vd37sejq0EZjfLwLVKphTFMzwmWBhcs561HxWQ==",
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(signUPpara),
    }).then((result) => {
      result.json().then((resp) => {
        // setUpdateForm(resp);
        // const data55 = upcoming22?.response?.records;
        // window.location.href = "/";
        handleClickOpen();

      });
    });
  }

  return (
    <div>
      <Header />
     <Banner />

      <div className="bbb_viewed footer-1 footer-55 aboutus-2">
  <div className="container">
    <div className="row">
      <div className="col-sm-12">
        <h2 className='aboutus-1'>Survey Form</h2>
      </div>
    
      <div className="col-sm-12">
        
          <div className="row">
            <hr />
          
      <div className="col-sm-6 col-md-4">
        <label htmlFor="email">
          <b className='aboutus-1'>Booth NO</b>
        </label>
        <input
          type="text"
          placeholder="Enter Booth NO."
          id="email"
          name="BoothNO"
          value={BoothNO}
          onChange={(e) => setBoothNO(e.target.value)}
          required
        />
      </div>
      
      <div className="col-sm-6 col-md-4">
        <label htmlFor="email">
          <b className='aboutus-1'>Name</b>
        </label>
        <input
          type="text"
          placeholder="Enter Name"
          id="email"
          name="Name"
          value={Name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </div>
      <div className="col-sm-6 col-md-4">
        <label htmlFor="email">
          <b className='aboutus-1'>Mobile Number</b>
        </label>
        <input
          type="text"
          placeholder="Enter Mobile No."
          id="email"
          name="MobileNo"
          value={MobileNo}
          onChange={(e) => setMobileNo(e.target.value)}
          required
        />
      </div>
      <div className="col-sm-6 col-md-4">
              <label htmlFor="email">
                <b className='aboutus-1'>Gender</b>
              </label>
              <select id="txtProductCategory" name="Gender" value={Gender}
               onChange={(e) => setGender(e.target.value)} >
                <option value="">--Select Gender--</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </select>
            </div>
            
      
      <div className="col-sm-6 col-md-4">
        <label htmlFor="email">
          <b className='aboutus-1'>Age</b>
        </label>
        <input
          type="text"
          placeholder="Enter Age"
          id="email"
          name="Age"
          value={Age}
          onChange={(e) => setAge(e.target.value)}
          required
        />
      </div>
      <div className="col-sm-6 col-md-4">
        <label htmlFor="email">
          <b className='aboutus-1'>Occupation</b>
        </label>
        <input
          type="text"
          placeholder="Enter Occupation"
          id="email"
          name="Occupation"
          value={Occupation}
          onChange={(e) => setOccupation(e.target.value)}
          required
        />
      </div>
      <div className="col-sm-6 col-md-4">
        <label htmlFor="email">
          <b className='aboutus-1'>Party Name</b>
        </label>
        <input
          type="text"
          placeholder="Enter Party Name"
          id="email"
          name="PartyName"
          value={PartyName}
          onChange={(e) => setPartyName(e.target.value)}
          required
        />
      </div>
      <div className="col-sm-6 col-md-4">
        <label htmlFor="email">
          <b className='aboutus-1'>Mukhiya</b>
        </label>
        <input
          type="text"
          placeholder="Enter Mukhiya"
          id="email"
          name="Mukhiya"
          value={Mukhiya}
          onChange={(e) => setMukhiya(e.target.value)}
          required
        />
      </div>
      <div className="col-sm-6 col-md-4">
        <label htmlFor="email">
          <b className='aboutus-1'>Mukhiya Percent Of Happy	</b>
        </label>
        <input
          type="text"
          placeholder="Enter Mukhiya Percent Of Happy	"
          id="email"
          name="MukhiyaPercentOfHappy"
          value={MukhiyaPercentOfHappy}
          onChange={(e) => setMukhiyaPercentOfHappy(e.target.value)}
          required
        />
      </div>
      <div className="col-sm-6 col-md-4">
        <label htmlFor="email">
          <b className='aboutus-1'>Parshad</b>
        </label>
        <input
          type="text"
          placeholder="Enter Parshad"
          id="email"
          name="Parshad"
          value={Parshad}
          onChange={(e) => setParshad(e.target.value)}
          required
        />
      </div>
      <div className="col-sm-6 col-md-4">
        <label htmlFor="email">
          <b className='aboutus-1'>Parshad Percent Of Happy	</b>
        </label>
        <input
          type="text"
          placeholder="Enter Parshad Percent Of Happy	"
          id="email"
          name="ParshadPercentOfHappy"
          value={ParshadPercentOfHappy}
          onChange={(e) => setParshadPercentOfHappy(e.target.value)}
          required
        />
      </div>
      <div className="col-sm-6 col-md-4">
        <label htmlFor="email">
          <b className='aboutus-1'>MLA</b>
        </label>
        <input
          type="text"
          placeholder="Enter MLA"
          id="email"
          name="MLA"
          value={MLA}
          onChange={(e) => setMLA(e.target.value)}
          required
        />
      </div>

      <div className="col-sm-6 col-md-4">
        <label htmlFor="email">
          <b className='aboutus-1'>MLA Percent Of Happy	</b>
        </label>
        <input
          type="text"
          placeholder="Enter MLA Percent Of Happy	"
          id="email"
          name="MLAPercentOfHappy"
          value={MLAPercentOfHappy}
          onChange={(e) => setMLAPercentOfHappy(e.target.value)}
          required
        />
      </div>
      <div className="col-sm-6 col-md-4">
        <label htmlFor="email">
          <b className='aboutus-1'>Cheif Minister (CM)</b>
        </label>
        <input
          type="text"
          placeholder="Enter Cheif Minister (CM)"
          id="email"
          name="CM"
          value={CM}
          onChange={(e) => setCM(e.target.value)}
          required
        />
      </div>
      <div className="col-sm-6 col-md-4">
        <label htmlFor="email">
          <b className='aboutus-1'>Cm Percent Of Happy</b>
        </label>
        <input
          type="text"
          placeholder="Enter Cm Percent Of Happy"
          id="email"
          name="CmPercentOfHappy"
          value={CmPercentOfHappy}
          onChange={(e) => setCmPercentOfHappy(e.target.value)}
          required
        />
      </div>
      <div className="col-sm-6 col-md-4">
        <label htmlFor="email">
          <b className='aboutus-1'>Any Problem</b>
        </label>
        <input
          type="text"
          placeholder="Enter Any Problem"
          id="email"
          name="AnyProblem"
          value={AnyProblem}
          onChange={(e) => setAnyProblem(e.target.value)}
          required
        />
      </div>

            <hr />
            {/* <button className="registerbtn" onClick={UpdateFormAPI}>
              Submit
            </button> */}

            {AnyProblem?.length > 0 ? (
            <button className="registerbtn" onClick={UpdateFormAPI}>
              Submit
            </button>
            ) : (
<button className="registerbtn">
              Submit
            </button>
            )
}
            
           
          </div>
        
      </div>
      
    </div>
  </div>
</div>

      <Footer />
    </div>
  )
}


export default SurveyForm
