import React, { useEffect, useContext, useState } from "react";

import { FallbackImage } from './FallbackImage';
import { Link, useParams } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import { ProductContext } from './context/AppContext';
function OurHereosList() {
    const [allproduct2, setAllProduct2] = useState();

    const { setSelectedProduct, OurMinistersData } = useContext(ProductContext);
    const productdata = JSON.parse(localStorage.getItem("productdata"));


const filteredDataGovernor = OurMinistersData?.response?.records.filter((item) =>
  item.find(
    (field) => field.name === "Category" && field.value === "Our Heros"
  )
);
console.log(filteredDataGovernor, "filteredDataGovernor New");


  const handleItemClick = (ChiefMinisterCM) => {
    setSelectedProduct(ChiefMinisterCM); // Save the selected product details in the context state.
    localStorage.setItem("SingleData", JSON.stringify(ChiefMinisterCM));
  };

  
 

  const sortGovernor = (templateID) => {
    let dataName = [...filteredDataGovernor];
    if (dataName?.length > 0) {
      let resultFilterName = dataName.filter(
        (value) => value.templateID === templateID
      );
      setAllProduct2(resultFilterName);
      localStorage.setItem("AllData", JSON.stringify(resultFilterName));
    }
  };


  return (
   
  
    <div>

<div className="bbb_viewed">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12">
                <div className="bbb_viewed_title_container">
                    <h3 className="bbb_viewed_title bbb_viewed_title-leader">
                      {filteredDataGovernor?.[0][3]?.value}
                      <span>
                      <Link to={`../see-all/${filteredDataGovernor?.[0][3].value.replace(/ /g, '-')}`} >

                          <span
                            onClick={() =>
                              sortGovernor(filteredDataGovernor?.templateID)
                            }
                          >
                            See All
                          </span>
                        </Link>
                      </span>
                    </h3>
                  </div>
                  <ul className="images-cat">
                  {filteredDataGovernor?.slice(0,10)?.map((aappartylist, index) => {
                    return (
                      <li key={index}>
                        <Link
                          to={`../profileview/${aappartylist?.[3]?.value.replace(/ /g, '-')}/${aappartylist?.[5]?.value.replace(/ /g, '-')}`}  
                          onClick={() => handleItemClick(aappartylist)}
                        >
                          <FallbackImage
                            src={aappartylist?.[4]?.value}
                            alt=""
                          />
                          <Link
                          to={`../profileview/${aappartylist?.[3]?.value.replace(/ /g, '-')}/${aappartylist?.[5]?.value.replace(/ /g, '-')}`}  
                          onClick={() => handleItemClick(aappartylist)}
                        >
                            <p
                              className="mp-name1"
                              onClick={() =>
                                handleItemClick(aappartylist)
                              }
                            >
                              {aappartylist?.[5]?.value}
                            </p>
                            {/* <p
                              className="mp-name"
                              onClick={() =>
                                handleItemClick(aappartylist)
                              }
                            >
                              {aappartylist?.[21]?.value}<br />{aappartylist?.[6]?.value}
                            </p> */}
                          </Link>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      
     

      
        
    </div>
  )
}

export default OurHereosList
