import React, { useEffect, useContext, useState } from "react";

import { FallbackImage } from './FallbackImage';
import { Link, useParams } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import { ProductContext } from './context/AppContext';
function RajyaSabhaMP() {
    const [allproduct2, setAllProduct2] = useState();

    const { setSelectedProduct } = useContext(ProductContext);
    const productdata = JSON.parse(localStorage.getItem("productdata"));
    const filteredDataCM = productdata?.response?.records.filter((item) =>
    item.find(
      (field) =>
        field.name === "Category" && field.value === "Chief Minister (CM)"
    )
  );
    const filteredDataLokSabha = productdata?.response?.records.filter((item) =>
    item.find(
      (field) => field.name === "Category" && field.value === "Lok Sabha" 
    )
  );


  const filteredDatarajyaSabha = productdata?.response?.records.filter((item) =>
  item.find(
    (field) => field.name === "Category" && field.value === "Rajya Sabha"
  )
);
localStorage.setItem("AllData", JSON.stringify(filteredDatarajyaSabha));




  const handleItemClick = (ChiefMinisterCM) => {
    setSelectedProduct(ChiefMinisterCM); // Save the selected product details in the context state.
    localStorage.setItem("SingleData", JSON.stringify(ChiefMinisterCM));
  };
  const sortMainCategort = (templateID) => {
    let dataName = [...filteredDataCM];
    if (dataName?.length > 0) {
      let resultFilterName = dataName.filter(
        (value) => value.templateID === templateID
      );
      setAllProduct2(resultFilterName);
      localStorage.setItem("AllData", JSON.stringify(resultFilterName));
    }
  };
  const sortMainCategort2 = (templateID) => {
    let dataName = [...filteredDataLokSabha];
    if (dataName?.length > 0) {
      let resultFilterName = dataName.filter(
        (value) => value.templateID === templateID
      );
      setAllProduct2(resultFilterName);
      // localStorage.setItem("AllData", JSON.stringify(resultFilterName));
    }
  };
  
  const sortRajyaSabha = (templateID) => {
    let dataName = [...filteredDatarajyaSabha];
    if (dataName.length > 0) {
      let resultFilterName = dataName.filter(
        (value) => value.templateID === templateID
      );
      setAllProduct2(resultFilterName);
      localStorage.setItem("AllData", JSON.stringify(resultFilterName));
    }
  };

  const uniqueStateNames = new Set();
  const[selectedState,setSelectedState]= useState();
  const handleClick = (stateName) => {
    if (selectedState === stateName) {
      // If the same state name is clicked again, hide the data

      setSelectedState(null);
    } else {
      // Otherwise, show the data for the clicked state
      setSelectedState(stateName);
      localStorage.setItem("AllData2", JSON.stringify(stateName));
    }
  };
  return (
   
  
    <div>

         <div className="bbb_viewed">
          <div className="">
            <div className="">
              <div className="">
                <div className="">
                  <div className="bbb_viewed_title_container">
                    <h3 className="bbb_viewed_title">
                      {filteredDatarajyaSabha?.[0][3].value}

                      <span>
                      <Link to={`../viewrajyasabhamp/${filteredDatarajyaSabha?.[0][3].value.replace(/ /g, '-')}`}   >

                      {/* <Link to={`../see-all/${filteredDatarajyaSabha?.[0][3].value.replace(/ /g, '-')}`}   > */}

                          <span
                            onClick={() =>
                              sortMainCategort2(filteredDataLokSabha?.templateID)
                            }
                          >
                            See All
                          </span>
                        </Link>
                      </span>
                    </h3>
                  </div>
                  <div className="bbb_viewed_slider_container">
                  {/* <OwlCarousel className='owl-theme' loop margin={5} items={10}
                  center = {true}
                  autoWidth={true}
                  dots={false}  nav autoplay>   */}
                    <OwlCarousel
                      className="owl-theme"
                      loop
                      margin={5}
                      nav
                      items={7}
                      dots={false}
                      autoWidth={true}
                    >
                      {filteredDatarajyaSabha?.map((ChiefMinisterCM, index) => {
            const stateNameField = ChiefMinisterCM.find(
              (field) => field.name === "State"
            );
            const stateName = stateNameField ? stateNameField.value : "";

            // Check if the state name is unique and add it to the set
            if (!uniqueStateNames.has(stateName)) {
              uniqueStateNames.add(stateName);
              return (
                            <div className="item item1" 
                            key={index}>
                              <Link
                to={`/rajyasabhamplist/${stateName}`} // Include state data as a URL parameter
                onClick={() => handleClick(stateName)} // Handle click event
              >
                                {/* <Link to={ "../details/"} > */}
                                <div
                                  // onClick={() =>handleItemClick2(cabinet)}
                                  className="bbb_viewed_item discount d-flex flex-column align-items-center justify-content-center text-center"
                                >
                                  <div className="bbb_viewed_image">
                                    <FallbackImage
                                      src={ChiefMinisterCM?.[20]?.value}
                                      alt=""
                                    />
                                  </div>
                                  <div className="bbb_viewed_content text-center">
                                    <div className="bbb_viewed_name">
                                      <Link to={"../details/"}>
                                        <h5
                                          className="cm-list1"
                                          
                                        >
                                          {stateName}
                                        </h5>
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          );
                        }
                        return null; // Skip rendering if it's not unique
                      })}
                    </OwlCarousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      


      
        
    </div>
  )
}

export default RajyaSabhaMP
