import React, { useEffect, useContext, useState } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { FallbackImage } from "./FallbackImage";
import { Link, useParams } from "react-router-dom";
import Banner from "./Banner";
import { ProductContext } from './context/AppContext';

function AboutUs(props) {
  const { aboutus5 } = useContext(ProductContext);
  // console.log(aboutus5, "aboutus5 15");
  const filteredDataAboutUs = aboutus5?.response?.record;
  
  return (
    <div>
      <Header />
     <Banner />

      <div className="bbb_viewed footer-1 aboutus-2">
        <div className="container-fluid">
        <div className="row">
            <div className="col-sm-12">
              <h2 className="aboutus-1">About Us</h2>
              </div>
              </div>
          {aboutus5?.response?.records?.map((aboutus2,i)=>{
            return(
<>
<div className="row">

            <div className="col-sm-12">
             
            </div>
            <div className="col-sm-8">
            <p style={{ textAlign: "justify" }}>
                <b>{aboutus2?.[4]?.value} ({aboutus2?.[5]?.value})
                </b>
              </p>
              <p style={{ textAlign: "justify" }}>
                {aboutus2?.[7]?.value}
                
              </p>
            <p>{aboutus2?.[8]?.value}
              </p>
              
              <p>{aboutus2?.[9]?.value}
              </p>
              
              
            </div>
            <div className="col-sm-4">
              <img className="bjp-1" src={aboutus2?.[6]?.value} width="100%" /><br /><br/>
            </div>
            
          
            <hr />
          </div>
          </>  
            )
          })}
          
          
         
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default AboutUs;
