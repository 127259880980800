import React, { useState, useContext, useEffect } from "react";
import Header from "../Header";
import Footer from "../Footer";
import { FallbackImage } from "./FallbackImage";
import { Link, useParams } from "react-router-dom";
import Banner from "./Banner";
import Form from "react-bootstrap/Form";

function ContactUs(props) {
  const { handleClickOpen } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [Name, setName] = useState();
  const [MobileNo, setMobileNo] = useState();
  const [Email, setEmail] = useState();
  const [Message, setMessage] = useState();
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const regex = /^[0-9]*$/;
  const [error, setError] = useState("");
  const handleChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, "");
    setMobileNo(value);
  };
  const fun_Contact = async () => {
    if (Name === undefined || Name === "") {
      alert("Please enter name.");
      return;
    } else if (MobileNo === undefined || MobileNo === "") {
      alert('Please enter mobile no."');
      return;
    } else if (MobileNo.length != 10) {
      alert("Please enter a valid 10-digit mobile number"); // Set error message if invalid
      return;
    } else if (Email === undefined || Email === "") {
      alert("Please enter email id.");
      return;
    } else if (!emailRegex.test(Email)) {
      alert("Please enter valid email id.");
      return;
    } else if (Message === undefined || Message === "") {
      alert('Please enter your message"');
      return;
    } else {
      try {
        const response = await fetch(
          "https://crmleads.erpthemes.com/savecrmlead?=null",
          {
            method: "POST",
            headers: {
              clientid: "a49baad4-43e8-4f7d-be3a-97b11bf47da8",
              "page-name": "Contact_Us",
              //'sendtoemail': 'Chandrakant111420@gmail.com',
              //'emailsubject': 'Contact Form Submission',
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              Query: Message,
              Name: Name,
              Email: Email,
              Mobile: MobileNo,
              Subject: "Query",
              ClientId: "a49baad4-43e8-4f7d-be3a-97b11bf47da8",
            }),
          }
        );

        const data = await response.json();
        alert(data);
        console.log("Success:", data);
      } catch (error) {
        alert(error?.Message);
        console.error("Error:", error);
      }
    }
  };

  return (
    <div>
      <Header />
      <Banner />
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open responsive dialog
      </Button> */}
      <div className="bbb_viewed footer-1 footer-55 aboutus-2">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h2 className="aboutus-1">Contact Us</h2>
            </div>

            <div className="row">
              <hr />

              <div className="col-sm-6 col-md-4">
                <label htmlFor="name">
                  <b className="aboutus-1">Name</b>
                </label>
                <input
                  type="text"
                  placeholder="Enter Name"
                  id="name"
                  name="Name"
                  value={Name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
              <div className="col-sm-6 col-md-4">
                <label htmlFor="mobile">
                  <b className="aboutus-1">Mobile Number</b>
                </label>
                <input
                  type="text"
                  placeholder="Enter Mobile No."
                  id="mobile"
                  name="MobileNo"
                  value={MobileNo}
                  //onChange={(e) => setMobileNo(e.target.value)}
                  onChange={handleChange}
                  maxLength={10}
                />
                {error && <p style={{ color: "red" }}>{error}</p>}
              </div>

              <div className="col-sm-6 col-md-4">
                <label htmlFor="email">
                  <b className="aboutus-1">Email</b>
                </label>
                <input
                  type="text"
                  placeholder="Enter Email"
                  required
                  id="email"
                  name="email"
                  value={Email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="col-sm-12 col-md-12">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>
                    <b className="aboutus-1">Message</b>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Enter Message"
                    value={Message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                  />
                </Form.Group>
              </div>

              <hr />

              <button className="registerbtn" onClick={() => fun_Contact()}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default ContactUs;
