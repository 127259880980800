import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Button from "@mui/material/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SearchIcon from "@mui/icons-material/Search";
import Modal from "react-bootstrap/Modal";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import OTPInput, { ResendOTP } from "otp-input-react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import TextField from "@mui/material/TextField";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import CancelIcon from "@mui/icons-material/Cancel";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ClearIcon from '@mui/icons-material/Clear';
import { ProductContext } from "./Component/context/AppContext";
function Header() {
  const { headerIcon, headerMenu, headerMenu22 } = useContext(ProductContext);
  const socialList = headerIcon?.response?.records;
  const phoneNumber = socialList?.[0][5]?.value;
  console.log(headerMenu22, "headerMenu22");
  const whatsappLink = `https://wa.me/91${phoneNumber}?text=I%20m%20interested%20in%20Political%20Party`;

  const [value, setValue] = useState();
  const [OTP, setOTP] = useState("");

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  const [visible, setVisible] = React.useState(false);


  const popover = (
    <Popover id="popover-basic" className="tool-1">
      <Popover.Body className="pop-1">Edit</Popover.Body>
    </Popover>
  );
  const [checked, setChecked] = useState(false);
  const [radioValue, setRadioValue] = useState("1");

  const radios = [
    { name: "Home", value: "1" },
    { name: "Work", value: "2" },
    { name: "Other", value: "3" },
  ];

  const[Name,setName] = useState();
  const[MobileNo,setMobileNo] = useState();
  const[Email,setEmail] = useState();
  const[Ward,setWard] = useState();
   const[Signup,setSignup] = useState();

   const[Gender,setGender] = useState();
   const[Satet1,setState1] = useState();
    const[District,setDistrict] = useState();
    const[Constituency,setConstituency] = useState();
 

  const signUPpara = {
    templateID: "16",
    "_1": Name,
     "_2": MobileNo,
    "_3": Email,
    "_4": Ward,
    "_5": Signup,
    "_6": Gender,
    "_7": Satet1,
    "_8": Constituency,
  }
  function UpdateFormAPI() {
    fetch('https://api.dialerp.com/api/api/addUpdateTemplateDataWeb', {
      method: "POST",
      headers: {
        "Authorization-Token": "7FaNAN5fgV+7l6gVY6M6vRzNeDPEUJHBDtIibnr1vHQodIJV95ki5i6B/JJCsigzgfA+UO0pJ8Tayd9MfZGV+XLYq26sqtuN7aBKuht7n1kR2AU9Vd37sejq0EZjfLwLVKphTFMzwmWBhcs561HxWQ==",
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(signUPpara),
    }).then((result) => {
      result.json().then((resp) => {
        // setUpdateForm(resp);
        // const data55 = upcoming22?.response?.records;
         window.location.href = "/";
        // handleClickOpen();
      });
    });
  }

  return (
    <div className="navbar2">
      
      <Container fluid>
      <Row className="mv-border-11">
        
      <Col xs={6} md={6} lg={6}>
          <ul className="footer-3">
                
            <li>
              <a href={socialList?.[6][4].value} target="_blank">
                <i className="fa fa-youtube" />
              </a>
            </li>
            <li>
              <a href ={socialList?.[5][4].value} target="_blank">
                <i className="fa fa-twitter" />
              </a>
            </li>
            <li>
              <a href={socialList?.[4][4].value} target="_blank">
                <i className="fa fa-linkedin" />
              </a>
            </li>
            <li>
            <a href={socialList?.[3][4].value} target="_blank">
                <i className="fa fa-facebook-f" />
              </a>
            </li>
            <li>
            <a href={socialList?.[2][4].value} target="_blank">
                <i className="fa fa-instagram" />
              </a>
            </li>
            <li>
            <a href={socialList?.[1][4].value} target="_blank">
                <i className="fa fa-pinterest" />
              </a>
            </li>
            {/* <li>
            <Link to="/poll">Poll
              </Link>
            </li> */}
          
          </ul>
        </Col>
          
          <Col xs={6} md={6} lg={6}>
          <p className="number-link">
            <a
              href={whatsappLink}
              target="_blank"
            >
              Call Us : {phoneNumber}
            </a>
          </p>
            </Col>
          </Row>
        <Row>
         
          <Col xs={12} md={12} lg={12} className="navbar-nav-1">
          <Navbar collapseOnSelect expand="lg"  className="your-navbar-class">
     
          <Link to = "/"><Navbar.Brand>
        <img src="../../images/logo.svg" />
        </Navbar.Brand></Link>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto nav me-auto51">
          <Nav.Link><Link to = "/">{headerMenu22?.response?.records[0]?.fieldName}</Link></Nav.Link>
            <Nav.Link><Link to = "/about-us">{headerMenu22?.response?.records[1]?.fieldName}</Link></Nav.Link>
            <Nav.Link><Link to = "/service">{headerMenu22?.response?.records[2]?.fieldName}</Link></Nav.Link>
            <Nav.Link><Link to = "/surveys">{headerMenu22?.response?.records[3]?.fieldName}</Link></Nav.Link>
            <NavDropdown title={headerMenu22?.response?.records[4]?.fieldName} id="election-dropdown">
              <NavDropdown.Item href="">{headerMenu22?.response?.records[4]?.fieldDefaultValues[0]}</NavDropdown.Item>
              <NavDropdown.Item href="">{headerMenu22?.response?.records[4]?.fieldDefaultValues[1]}</NavDropdown.Item>
              <NavDropdown.Item href="">{headerMenu22?.response?.records[4]?.fieldDefaultValues[2]}</NavDropdown.Item>
              <NavDropdown.Item href="">{headerMenu22?.response?.records[4]?.fieldDefaultValues[3]}</NavDropdown.Item>
              {/* <NavDropdown.Item><Link to = "/poll">Poll</Link></NavDropdown.Item> */}
              <NavDropdown.Item><Link to = "/createpoll">{headerMenu22?.response?.records[4]?.fieldDefaultValues[4]}</Link></NavDropdown.Item>
              
            </NavDropdown>
            <NavDropdown title={headerMenu22?.response?.records[5]?.fieldName} id="election-dropdown">
              <NavDropdown.Item><Link to ="/chief-minister"> {headerMenu22?.response?.records[5]?.fieldDefaultValues[0]}</Link></NavDropdown.Item>
              <NavDropdown.Item><Link to ="/lok-sabha">{headerMenu22?.response?.records[5]?.fieldDefaultValues[1]}</Link></NavDropdown.Item>
              <NavDropdown.Item><Link to ="/rajya-sabha">{headerMenu22?.response?.records[5]?.fieldDefaultValues[2]}</Link></NavDropdown.Item>
              <NavDropdown.Item><Link to ="/mla">{headerMenu22?.response?.records[5]?.fieldDefaultValues[3]}</Link></NavDropdown.Item>
              <NavDropdown.Item><Link to ="/councillor">{headerMenu22?.response?.records[5]?.fieldDefaultValues[4]}</Link></NavDropdown.Item>
            </NavDropdown>
            <Nav.Link><Link to = "/survey-form">{headerMenu22?.response?.records[6]?.fieldName}</Link></Nav.Link>
            <Nav.Link><Link to = "/feedback-form">{headerMenu22?.response?.records[11]?.fieldName}</Link></Nav.Link>
            <Nav.Link><Link to = "/paynow">{headerMenu22?.response?.records[12]?.fieldName}</Link></Nav.Link>

            <Nav.Link><Link to = "/contact-us">{headerMenu22?.response?.records[7]?.fieldName}</Link></Nav.Link>


            
          </Nav>
          <Nav className="me-auto1">
          <Nav.Link><Link to = "/signup">{headerMenu22?.response?.records[9]?.fieldName}</Link></Nav.Link>
            <Nav.Link><Link to = "/login">{headerMenu22?.response?.records[10]?.fieldName}</Link></Nav.Link>
          </Nav>
        </Navbar.Collapse>
    </Navbar>
          </Col>
          <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="signup-155">Sign Up</Modal.Title>
          
        </Modal.Header>
        <div className="container container-login-1">
  
  <div className="row">
    <div className="col-md-6 no-space-1">
      <div className="login-left-1">
        <h2>A few clicks Your Political Compaign</h2>
        <h4>
          Register with your phone number and get exclusive discounts. Be
          updated about technology in your Political Compaign
        </h4>
        {/* <img src="images/product/login.png"> */}
      </div>
    </div>
    <div className="col-md-6">
      <div className="row">


        <div className="mv-sign-up">
          {/* <h1>Sign Up</h1> */}
          <p className="fill1">Please fill in this form to create an account.</p>
          <label htmlFor="email">
            <b>Name</b>
          </label>
          <input
            type="text"
            placeholder="Enter Name"
            name="firstname"
            id="txtFirstName"
            value={Name}
          onChange={(e) => setName(e.target.value)}
          required
          />
          <div className="row">
          <div className="col-md-6">
          <label htmlFor="email">
            <b>Mobile</b>
          </label>
          <input
            type="text"
            placeholder="Enter Mobile"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            maxLength={10}
            name="mobile"
            id="txtMobile"
            value={MobileNo}
          onChange={(e) => setMobileNo(e.target.value)}
          required
          />
          </div>
          <div className="col-md-6">
          <label htmlFor="email">
            <b>Email</b>
          </label>
          <input
            type="text"
            placeholder="Enter Email"
            name="email"
            id="txtEmail"
            value={Email}
          onChange={(e) => setEmail(e.target.value)}
          required
          />
          </div>
          </div>
          <div className="col-sm-12 col-md-12">
              <label htmlFor="email">
                <b className='aboutus-1'>Gender</b>
              </label>
              <select name="productCategory" id="txtProductCategory" value={Gender}
               onChange={(e) => setGender(e.target.value)}>
                <option value="">--Select Gender--</option>
                <option value="Male">Male
                </option>
                <option value="Female">Female</option>
              </select>
            </div>
           <div className="col-sm-12 col-md-12">
              <label htmlFor="email">
                <b className='aboutus-1'>State</b>
              </label>
              <select name="productCategory" id="txtProductCategory" value={Satet1}
              required
               onChange={(e) => setState1(e.target.value)}>
                <option value="">--Select State--</option>
                <option value="Andaman & Nicobar Islands">
                  Andaman &amp; Nicobar Islands
                </option>
                <option value="Andhra Pradesh">Andhra Pradesh</option>
                <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                <option value="Assam">Assam</option>
                <option value="Bihar">Bihar</option>
                <option value="Chandigarh">Chandigarh</option>
                <option value="Chhattisgarh">Chhattisgarh</option>
                <option value="Dadra & Nagar Haveli & Daman & Diu">
                  Dadra &amp; Nagar Haveli &amp; Daman &amp; Diu
                </option>
                <option value="Delhi">Delhi</option>
                <option value="Goa">Goa</option>
                <option value="Gujarat">Gujarat</option>
                <option value="Haryana">Haryana</option>
                <option value="Himachal Pradesh">Himachal Pradesh</option>
                <option value="Jammu & Kashmir">Jammu &amp; Kashmir</option>
                <option value="Jharkhand">Jharkhand</option>
                <option value="Karnataka">Karnataka</option>
                <option value="Kerala">Kerala</option>
                <option value="Ladakh">Ladakh</option>
                <option value="Lakshadweep">Lakshadweep</option>
                <option value="Madhya Pradesh">Madhya Pradesh</option>
                <option value="Maharashtra">Maharashtra</option>
                <option value="Manipur">Manipur</option>
                <option value="Meghalaya">Meghalaya</option>
                <option value="Mizoram">Mizoram</option>
                <option value="Nagaland">Nagaland</option>
                <option value="Odisha">Odisha</option>
                <option value="Puducherry">Puducherry</option>
                <option value="Punjab">Punjab</option>
                <option value="Rajasthan">Rajasthan</option>
                <option value="Sikkim">Sikkim</option>
                <option value="Tamil Nadu">Tamil Nadu</option>
                <option value="Telangana">Telangana</option>
                <option value="Tripura">Tripura</option>
                <option value="Uttarakhand">Uttarakhand</option>
                <option value="Uttar Pradesh">Uttar Pradesh</option>
                <option value="West Bengal">West Bengal</option>
              </select>
            </div>
            <div className="col-sm-12">
    <label htmlFor="psw">
      <b>Constituency</b>
    </label>
    {/* <input type="text" placeholder="Enter Constituency" name="email" id="email" required> */}
    <select value={Constituency}
          onChange={(e) => setConstituency(e.target.value)}>
      <option value="">--Select Constituency--</option>
      <option value="Behat">Behat</option>
      <option value="Nakur">Nakur</option>
      <option value="Saharanpur Nagar">Saharanpur Nagar</option>
      <option value="Saharanpur">Saharanpur</option>
      <option value="Deoband">Deoband</option>
      <option value="Rampur Maniharan">Rampur Maniharan</option>
      <option value="Gangoh">Gangoh</option>
      <option value="Kairana">Kairana</option>
      <option value="Thana Bhawan">Thana Bhawan</option>
      <option value=" Shamli"> Shamli</option>
    </select>
  </div>
         
          <label htmlFor="psw">
            <b>Sign up type</b>
          </label>
          <select name="productCategory" id="txtProductCategory" value={Signup}
          onChange={(e) => setSignup(e.target.value)}
          required>
            <option value="Ward-Type-Voter">Type-Voter</option>
            <option value="volunteer">Volunteer</option>
            <option value="politician">Politician</option>
          </select>
          <br />
          <div className="clearfix">
            <button
              type="button"
              onclick="document.getElementById('id01').style.display='none'"
              className="cancelbtn"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button type="button" className="signupbtn" id="btnsave" onClick={UpdateFormAPI}>
              Sign Up
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

      
       
      </Modal>

       <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title className="signup-155">Login</Modal.Title>
          
        </Modal.Header>
        
      
  <div className="container container-login">
    <div className="row">
      <div className="col-md-6 no-space-1">
        <div className="login-left">
          <h2>A few clicks Your Political Compaign</h2>
          <h4>
            Register with your phone number and get exclusive discounts. Be
            updated about technology in your Political Compaign
          </h4>
          {/* <img src="images/product/login.png"> */}
        </div>
      </div>
      <div className="col-md-6">
        <form className="" action="">
          <div className="container-2">
            {/* <h1>Login</h1> */}
            <p className="fill1">Please fill in this form to create an account.</p><br />
            <label htmlFor="email">
              <b>Email</b>
            </label>
            <input
              type="text"
              placeholder="Enter Email"
              name="email"
              required=""
            />
            <label htmlFor="psw">
              <b>Password</b>
            </label>
            <input
              type="password"
              placeholder="Enter Password"
              name="psw"
              required=""
            />
            {/* <label for="psw-repeat"><b>Repeat Password</b></label>
<input type="password" placeholder="Repeat Password" name="psw-repeat" required> */}
            <label>
              <input
                type="checkbox"
                defaultChecked="checked"
                name="remember"
                style={{ marginBottom: 15 }}
              />{" "}
              Remember me
            </label>
            <div className="clearfix">
              <button
                type="button"
                onclick="document.getElementById('id02').style.display='none'"
                className="cancelbtn"
              >
                Cancel
              </button>
              <button type="submit" className="signupbtn">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

      
       
      </Modal>  
       
        </Row>
       
      </Container>
    </div>
  );
}

export default Header;
