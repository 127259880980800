import React, { useEffect, useContext, useState } from "react";
import { FallbackImage } from './FallbackImage';
import { Link, useParams } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import { ProductContext } from './context/AppContext';
function LetestNews() {
    const [allproduct2, setAllProduct2] = useState();

    const { letestNews, setSelectedProduct } = useContext(ProductContext);

    const filteredPartyPresidentList = letestNews?.response?.records.filter((item) =>
    item.find(
      (field) => field.name === "Category" && field.value === "Party President List"
    )
  );
  const filteredLatestNews = letestNews?.response?.records.filter((item) =>
    item.find(
      (field) => field.name === "Category" && field.value === "Latest News"
    )
  );
  const filteredWeWorkFor = letestNews?.response?.records.filter((item) =>
    item.find(
      (field) => field.name === "Category" && field.value === "We Work For"
    )
  );
  const sortPartyPresident = (templateID) => {
    let dataName = [...filteredPartyPresidentList];
    if (dataName?.length > 0) {
      let resultFilterName = dataName.filter(
        (value) => value.templateID === templateID
      );
      setAllProduct2(resultFilterName);
      localStorage.setItem("AllData", JSON.stringify(resultFilterName));
    }
  };
  const sortedWeWorkFor = (templateID) => {
    let dataName = [...filteredWeWorkFor];
    if (dataName?.length > 0) {
      let resultFilterName = dataName.filter(
        (value) => value.templateID === templateID
      );
      setAllProduct2(resultFilterName);
      localStorage.setItem("AllData", JSON.stringify(resultFilterName));
    }
  };
  const sortLatestNews = (templateID) => {
    let dataName = [...filteredLatestNews];
    if (dataName?.length > 0) {
      let resultFilterName = dataName.filter(
        (value) => value.templateID === templateID
      );
      setAllProduct2(resultFilterName);
      localStorage.setItem("AllData", JSON.stringify(resultFilterName));
    }
  };
  const handleItemClick = (ChiefMinisterCM) => {
    setSelectedProduct(ChiefMinisterCM); // Save the selected product details in the context state.
    localStorage.setItem("SingleData", JSON.stringify(ChiefMinisterCM));
  };
  return (
    <div>
       <div className="bbb_viewed">
          <div className="">
            <div className="">
              <div className="">
                <div className="">
                  <div className="bbb_viewed_title_container">
                    <h3 className="bbb_viewed_title">
                      {filteredPartyPresidentList?.[0][3]?.value}

                      <span>
                      <Link to={`../see-all/${filteredPartyPresidentList?.[0][3].value.replace(/ /g, '-')}`}   >
                          <span
                            onClick={() => sortPartyPresident(filteredPartyPresidentList?.templateID)}
                          >
                            See All
                          </span>
                        </Link>
                      </span>
                    </h3>
                  </div>
                  <div className="bbb_viewed_slider_container">
                    <OwlCarousel
                      className="owl-theme"
                      loop
                      margin={5}
                      nav
                      items={4}
                      dots={false}
                      arrow= {false}
                      autoWidth={true}
                    >
                      {filteredPartyPresidentList?.map((mlalist, index) => {
                        return (
                          <div className="item item1" key={index}>
                           {/* <Link to={`../details/${mlalist?.[5]?.value.replace(/ /g, '-')}`} 
                           onClick={() => handleItemClick(mlalist)}> */}

<Link to={`../details/${mlalist?.[3]?.value.replace(/ /g, '-')}/${mlalist?.[5]?.value.replace(/ /g, '-')}`} 
                           onClick={() => handleItemClick(mlalist)}>

                            {/* <Link
                              to={"../details/"}
                              onClick={() => handleItemClick(mlalist)}
                            > */}
                              <div className="bbb_viewed_item discount d-flex flex-column align-items-center justify-content-center text-center">
                                <div className="bbb_viewed_image">
                                  <FallbackImage
                                    src={mlalist?.[4]?.value}
                                    alt=""
                                  />
                                </div>
                                <div className="bbb_viewed_content text-center">
                                  <div className="bbb_viewed_name">
                                  <Link to={`../details/${mlalist?.[3]?.value.replace(/ /g, '-')}/${mlalist?.[5]?.value.replace(/ /g, '-')}`} 
                           onClick={() => handleItemClick(mlalist)}>
                                      <h5
                                        className="cm-list1"
                                        onClick={() =>
                                            sortPartyPresident(mlalist)
                                        }
                                      >
                                        {mlalist?.[5]?.value}
                                      </h5>
                                    </Link>
                                    {/* <p className="cm-15">Ministry of Defence</p> */}
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                        );
                      })}
                    </OwlCarousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bbb_viewed">
          <div className="">
            <div className="">
              <div className="">
                <div className="">
                  <div className="bbb_viewed_title_container">
                    <h3 className="bbb_viewed_title">
                      {filteredLatestNews?.[0][3]?.value}

                      <span>
                      <Link to={`../see-all/${filteredLatestNews?.[0][3].value.replace(/ /g, '-')}`}   >
                          <span
                            onClick={() => sortLatestNews(filteredLatestNews?.templateID)}
                          >
                            See All
                          </span>
                        </Link>
                      </span>
                    </h3>
                  </div>
                  <div className="bbb_viewed_slider_container">
                    <OwlCarousel
                      className="owl-theme"
                      loop
                      margin={5}
                      nav
                      items={4}
                      dots={false}
                      arrow= {false}
                      autoWidth={true}
                    >
                      {filteredLatestNews?.map((mlalist, index) => {
                        return (
                          <div className="item item1" key={index}>
                           <Link to={`../details/${mlalist?.[3]?.value.replace(/ /g, '-')}/${mlalist?.[5]?.value.replace(/ /g, '-')}`} 
                           onClick={() => handleItemClick(mlalist)}>

                              <div className="bbb_viewed_item discount d-flex flex-column align-items-center justify-content-center text-center">
                                <div className="bbb_viewed_image">
                                  <FallbackImage
                                    src={mlalist?.[4]?.value}
                                    alt=""
                                  />
                                </div>
                                <div className="bbb_viewed_content text-center">
                                  <div className="bbb_viewed_name">
                                  <Link to={`../details/${mlalist?.[3]?.value.replace(/ /g, '-')}/${mlalist?.[5]?.value.replace(/ /g, '-')}`} 
                           onClick={() => handleItemClick(mlalist)}>
                                      <h5
                                        className="cm-list1"
                                        onClick={() =>
                                            sortLatestNews(mlalist)
                                        }
                                      >
                                        {mlalist?.[5]?.value}
                                      </h5>
                                    </Link>
                                    {/* <p className="cm-15">Ministry of Defence</p> */}
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                        );
                      })}
                    </OwlCarousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bbb_viewed">
          <div className="">
            <div className="">
              <div className="">
                <div className="">
                  <div className="bbb_viewed_title_container">
                    <h3 className="bbb_viewed_title">
                      {filteredWeWorkFor?.[0][3]?.value}

                      <span>
                      <Link to={`../see-all/${filteredWeWorkFor?.[0][3].value.replace(/ /g, '-')}`}   >

                          <span
                            onClick={() => sortedWeWorkFor(filteredWeWorkFor?.templateID)}
                          >
                            See All
                          </span>
                        </Link>
                      </span>
                    </h3>
                  </div>
                  <div className="bbb_viewed_slider_container">
                    <OwlCarousel
                      className="owl-theme"
                      loop
                      margin={5}
                      nav
                      items={10}
                      dots={false}
                      arrow= {false}
                      autoWidth={true}
                    >
                      {filteredWeWorkFor?.map((mlalist, index) => {
                        return (
                          <div className="item item1" key={index}>

<Link to={`../details/${mlalist?.[3]?.value.replace(/ /g, '-')}/${mlalist?.[5]?.value.replace(/ /g, '-')}`} 
                           onClick={() => handleItemClick(mlalist)}>

                            {/* <Link to={`../details/${mlalist?.[5]?.value.replace(/ /g, '-')}`} 
                           onClick={() => handleItemClick(mlalist)}> */}
                              <div className="bbb_viewed_item discount d-flex flex-column align-items-center justify-content-center text-center">
                                <div className="bbb_viewed_image">
                                  <FallbackImage
                                    src={mlalist?.[4]?.value}
                                    alt=""
                                  />
                                </div>
                                <div className="bbb_viewed_content text-center">
                                  <div className="bbb_viewed_name">
                                  <Link to={`../details/${mlalist?.[3]?.value.replace(/ /g, '-')}/${mlalist?.[5]?.value.replace(/ /g, '-')}`} 
                           onClick={() => handleItemClick(mlalist)}>

                                  {/* <Link to={`../details/${mlalist?.[5]?.value.replace(/ /g, '-')}`} 
                           onClick={() => handleItemClick(mlalist)}> */}
                                      <h5
                                        className="cm-list1"
                                        onClick={() =>
                                          sortedWeWorkFor(mlalist?.mainCatID)
                                        }
                                      >
                                        {mlalist?.[5]?.value}
                                      </h5>
                                    </Link>
                                    {/* <p className="cm-15">Ministry of Defence</p> */}
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                        );
                      })}
                    </OwlCarousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default LetestNews
