import React,{useState, useContext, useEffect} from 'react'
import Header from '../Header'
import Footer from '../Footer'
import { FallbackImage } from './FallbackImage';
import { Link, useParams } from "react-router-dom";
import Banner from './Banner';

function LokSabha(props) {
  const{handleClickOpen}=props;

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [updateform, setUpdateForm] = useState([]);
  
  const[BoothNO,setBoothNO] = useState();
  const[Name,setName] = useState();
  const[MobileNo,setMobileNo] = useState();

  const[Gender,setGender] = useState();
  const[Age,setAge] = useState();
   const[District,setDistrict] = useState();
   const[Constituency,setConstituency] = useState();
  // const[Mukhiya,setMukhiya] = useState();
  // const[MukhiyaPercentOfHappy,setMukhiyaPercentOfHappy] = useState();

  // const[Parshad,setParshad] = useState();
  // const[ParshadPercentOfHappy,setParshadPercentOfHappy] = useState();
  // const[MLA,setMLA] = useState();
  // const[MLAPercentOfHappy,setMLAPercentOfHappy] = useState();
  // const[CM,setCM] = useState();
  // const[CmPercentOfHappy,setCmPercentOfHappy] = useState();
  // const[AnyProblem,setAnyProblem] = useState();

  const signUPpara = {
    templateID: "13",
    "_1": BoothNO,
     "_2": Name,
    "_3": MobileNo,
    "_4": Gender,
    "_5": Age,
     "_6": District,
     "_7": Constituency,
    // "_8": Mukhiya,
    // "_9": MukhiyaPercentOfHappy,
    //  "_10": Parshad,
    // "_11": ParshadPercentOfHappy,
    // "_12": MLA,
    // "_13": MLAPercentOfHappy,
    // "_14": CM,
    // "_15": CmPercentOfHappy,
    // "_16": AnyProblem
  }
  function UpdateFormAPI() {
    fetch('https://api.dialerp.com/api/api/addUpdateTemplateDataWeb', {
      method: "POST",
      headers: {
        "Authorization-Token": "7FaNAN5fgV+7l6gVY6M6vRzNeDPEUJHBDtIibnr1vHQodIJV95ki5i6B/JJCsigzgfA+UO0pJ8Tayd9MfZGV+XLYq26sqtuN7aBKuht7n1kR2AU9Vd37sejq0EZjfLwLVKphTFMzwmWBhcs561HxWQ==",
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(signUPpara),
    }).then((result) => {
      result.json().then((resp) => {
        // setUpdateForm(resp);
        // const data55 = upcoming22?.response?.records;
        //  window.location.href = "/";
         handleClickOpen();
      });
    });
  }
  
  return (
    <div>
      <Header />
      <Banner />
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open responsive dialog
      </Button> */}
      <div className="bbb_viewed footer-1 footer-55 aboutus-2">
  <div className="container">
    <div className="row">
      <div className="col-sm-12">
        <h2 className='aboutus-1'>Rajya Sabha Member (MP)</h2>
      </div>
    
      <div className="col-sm-12">
      
          <div className="row">
            <hr />
      
      <div className="col-sm-6 col-md-6">
        <label htmlFor="email">
          <b className='aboutus-1'>Name</b>
        </label>
        <input
          type="text"
          placeholder="Enter Name"
          id="email"
          name="Name"
          value={Name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </div>
      <div className="col-sm-6 col-md-6">
        <label htmlFor="email">
          <b className='aboutus-1'>Mobile Number</b>
        </label>
        <input
          type="text"
          placeholder="Enter Mobile No."
          id="email"
          name="MobileNo"
          value={MobileNo}
          onChange={(e) => setMobileNo(e.target.value)}
          required
        />
      </div>
      
        
      
      <div className="col-sm-6 col-md-6">
        <label htmlFor="email">
          <b className='aboutus-1'>Email</b>
        </label>
        <input
          type="text"
          placeholder="Enter Email"
          required
          id="email"
          name="Age"
          value={Age}
          onChange={(e) => setAge(e.target.value)}
          
        />
      </div>
      <div className="col-sm-6 col-md-6">
              <label htmlFor="email">
                <b className='aboutus-1'>State</b>
              </label>
              <select name="productCategory" id="txtProductCategory" value={Gender}
              required
               onChange={(e) => setGender(e.target.value)}>
                <option value="">--Select State--</option>
                <option value="Andaman & Nicobar Islands">
                  Andaman &amp; Nicobar Islands
                </option>
                <option value="Andhra Pradesh">Andhra Pradesh</option>
                <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                <option value="Assam">Assam</option>
                <option value="Bihar">Bihar</option>
                <option value="Chandigarh">Chandigarh</option>
                <option value="Chhattisgarh">Chhattisgarh</option>
                <option value="Dadra & Nagar Haveli & Daman & Diu">
                  Dadra &amp; Nagar Haveli &amp; Daman &amp; Diu
                </option>
                <option value="Delhi">Delhi</option>
                <option value="Goa">Goa</option>
                <option value="Gujarat">Gujarat</option>
                <option value="Haryana">Haryana</option>
                <option value="Himachal Pradesh">Himachal Pradesh</option>
                <option value="Jammu & Kashmir">Jammu &amp; Kashmir</option>
                <option value="Jharkhand">Jharkhand</option>
                <option value="Karnataka">Karnataka</option>
                <option value="Kerala">Kerala</option>
                <option value="Ladakh">Ladakh</option>
                <option value="Lakshadweep">Lakshadweep</option>
                <option value="Madhya Pradesh">Madhya Pradesh</option>
                <option value="Maharashtra">Maharashtra</option>
                <option value="Manipur">Manipur</option>
                <option value="Meghalaya">Meghalaya</option>
                <option value="Mizoram">Mizoram</option>
                <option value="Nagaland">Nagaland</option>
                <option value="Odisha">Odisha</option>
                <option value="Puducherry">Puducherry</option>
                <option value="Punjab">Punjab</option>
                <option value="Rajasthan">Rajasthan</option>
                <option value="Sikkim">Sikkim</option>
                <option value="Tamil Nadu">Tamil Nadu</option>
                <option value="Telangana">Telangana</option>
                <option value="Tripura">Tripura</option>
                <option value="Uttarakhand">Uttarakhand</option>
                <option value="Uttar Pradesh">Uttar Pradesh</option>
                <option value="West Bengal">West Bengal</option>
              </select>
            </div>

            <div className="col-sm-6">
    <label htmlFor="email">
      <b>District</b>
    </label>
    <input
      type="text"
      placeholder="Enter District"
      name="District"
      id="email"
      required=""
      value={District}
          onChange={(e) => setDistrict(e.target.value)}
    />
  </div>
  <div className="col-sm-6">
    <label htmlFor="psw">
      <b>Constituency</b>
    </label>
    {/* <input type="text" placeholder="Enter Constituency" name="email" id="email" required> */}
    <select value={Constituency}
          onChange={(e) => setConstituency(e.target.value)}>
      <option value="">--Select Constituency--</option>
      <option value="Behat">Behat</option>
      <option value="Nakur">Nakur</option>
      <option value="Saharanpur Nagar">Saharanpur Nagar</option>
      <option value="Saharanpur">Saharanpur</option>
      <option value="Deoband">Deoband</option>
      <option value="Rampur Maniharan">Rampur Maniharan</option>
      <option value="Gangoh">Gangoh</option>
      <option value="Kairana">Kairana</option>
      <option value="Thana Bhawan">Thana Bhawan</option>
      <option value=" Shamli"> Shamli</option>
    </select>
  </div>
      

            <hr />
            {/* <button className="registerbtn" onClick={UpdateFormAPI}>
              Submit
            </button> */}

{Constituency?.length > 0 ? (
            <button className="registerbtn" onClick={UpdateFormAPI}>
              Submit
            </button>
            ) : (
              <button className="registerbtn">
              Submit
            </button>
            )
            }
           
          </div>
          
        
      </div>
      
    </div>
  </div>
</div>


      <Footer />
    </div>
  )
}

export default LokSabha
