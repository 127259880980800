import React, {Component,useEffect, useState, useContext} from "react";
  import Header from "../Header";
  import Footer from "../Footer";
  import Container from "react-bootstrap/Container";
  import Row from "react-bootstrap/Row";
  import Col from "react-bootstrap/Col";
  import { Link, useParams } from "react-router-dom";
  import { FallbackImage } from "./FallbackImage";
  import { ProductContext } from "./context/AppContext";
import Banner from "./Banner";
function OurHerosList() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      const [allproduct2, setAllProduct2] = useState();
      const { setSelectedProduct, politicalData, headerIcon } = useContext(ProductContext);
      const socialList = headerIcon?.response?.records;
    
      const filteredPolitical = politicalData?.response?.records.filter(
        (item) =>
          item.find(
            (field) =>
              field.name === "Category" && field.value === "Political Report Card"
          )
      );
    
      const filteredOurHeroes = politicalData?.response?.records.filter(
        (item) =>
          item.find(
            (field) =>
              field.name === "Category" && field.value === "Our Heroes"
          )
      );
      
      const handleItemClick = (ChiefMinisterCM) => {
        setSelectedProduct(ChiefMinisterCM); // Save the selected product details in the context state.
        localStorage.setItem("SingleData", JSON.stringify(ChiefMinisterCM));
      };
  return (
    <div>
        <Header />
        <Banner />
        <section id="about" className="about bbb_viewed bbb_viewed-1">
        <div
          className="container-fluid aos-init aos-animate"
          data-aos="fade-up"
        >
          {/* <div class="section-title">
    <h2>The Smart Way for Political Campaign</h2>
  </div> */}
          <div className="row content-1">
            <div className="col-lg-12 col-md-12">
              <h3 style={{ textAlign: "center" }}>
                {filteredOurHeroes?.[0][3]?.value}
              </h3>
              <ul className="poli-ul-1">
                {/* {filteredOurHeroes?.map((ChiefMinisterCM, index) => {
                  return (
                    <li key={index}>

                      <div className="election-22">
                      <Link to="../our-heroes" onClick={() =>handleItemClick(ChiefMinisterCM)}
                        >{ChiefMinisterCM?.[11]?.value}
                        </Link>
                      </div>
                    </li>
                    
                  );
                })} */}
                {filteredOurHeroes?.sort((a, b) => a[11]?.value.localeCompare(b[11]?.value)).map((ChiefMinisterCM, index) => {
    return (
      <li key={index}>
        <div className="election-22 our-hereos1">
          <Link
            to={`../our-heroes/${filteredOurHeroes?.[0][11]?.value.replace(/ /g, '-')}`}
            onClick={() => handleItemClick(ChiefMinisterCM)}
          >
            {ChiefMinisterCM?.[11]?.value}
          </Link>
        </div>
      </li>
    );
  })}

              </ul>
            </div>
          </div>
        </div>
      </section>
        
        <Footer />
      
    </div>
  )
}

export default OurHerosList
