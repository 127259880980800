import React, { useContext } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { FallbackImage } from "./FallbackImage";
import { ProductContext } from "./context/AppContext";
import { DummyBanner } from "./DummyBanner";

function Banner3() {
  const { bannerData } = useContext(ProductContext);
  const Mannkibaat = bannerData?.response?.records.filter((item) =>
    item.find(
      (field) => field.name === "Title" && field.value === "Mann ki baat"
    )
  );
  return (
    <div className="bbb_viewed">
      <div className="container-fluid">
        {Mannkibaat ? (
        <div className="row">
          <h3 className="mv-gov2">{Mannkibaat?.[0][3]?.value}</h3>
          <a href="https://www.narendramodi.in/mann-ki-baat#morePlay"
            target="_blank"
          >
            <DummyBanner src={Mannkibaat?.[0][5]?.value} width="100%" />
          </a>
        </div>
        ) : (
<></>
        )
}
      </div>
    </div>
  );
}

export default Banner3;
