import React, { useContext, useEffect, useState } from "react";
import Header from "../Header";
import { ProductContext } from "./context/AppContext";
import { FallbackImage } from "./FallbackImage";
import Footer from "../Footer";

function Data() {
  const { FooerService } = useContext(ProductContext);
  const FooterService = JSON.parse(localStorage.getItem("SingleData"));

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div>
      <>
        <Header />
        <div className="bbb_viewed footer-1 main-footer aboutus-2">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <h2 className="mv-gov2">{FooterService?.name}</h2>
              </div>
              <div className="col-sm-12">
                <p className="p-11">{FooterService?.value} </p>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default Data;
