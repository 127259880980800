import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useParams,
  Routes,
  Link,
} from "react-router-dom";

import React, {
  Component,
  useEffect,
  useState,
  useCallback,
  useContext,
} from "react";
import { Button, ModalHeader, Table } from "react-bootstrap";
import Home from "./Component/Home";
import Banner from "./Component/Banner";
import Footer from "./Footer";
import Header from "./Header";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { FallbackImage } from "./Component/FallbackImage";
import SeeAll from "./Component/SeeAll";
import Details from "./Component/Details";
import AboutUs1 from "./Component/AboutUs1";
import AboutUs from "./Component/AboutUs";
import Service from "./Component/Service";
import Surveys from "./Component/Surveys";
import ContactUs from "./Component/ContactUs";
import ChiefMinister from "./Component/ChiefMinister";
import LokSabha from "./Component/LokSabha";
import RajyaSabha from "./Component/RajyaSabha";
import Councillor from "./Component/Councillor";
import MLA from "./Component/MLA";
import { ProductContext } from "./Component/context/AppContext";
import PrivacyPolicy from "./Component/PrivacyPolicy";
import TermsCondition from "./Component/TermsCondition";
import ProfileView from "./Component/ProfileView";
import SurveyForm from "./Component/SurveyForm";
import LetestVideo from "./Component/LetestVideo";
import Mp from "./Component/Mp";
import LetestNews from "./Component/LetestNews";
import PartyWiseLeaders from "./Component/PartyWiseLeaders";
import WhoIsWho from "./Component/WhoIsWho";
import CabinetMinister from "./Component/CabinetMinister";
import Banner1 from "./Component/Banner1";
import Banner2 from "./Component/Banner2";
import Banner3 from "./Component/Banner3";
import Banner4 from "./Component/Banner4";
import Banner5 from "./Component/Banner5";
import PoliticalCardPreview from "./Component/PoliticalCardPreview";
import CMList from "./Component/CMList";
import Service1 from "./Component/Service1";
import SeeAllMLA from "./Component/SeeAllMLA";
import OurHeroes from "./Component/OurHeroes";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import OurHerosList from "./Component/OurHerosList";
import Signup from "./Component/Signup";
import Login from "./Component/Login";
import MPList from "./Component/MPList";
import FeedbackForm from "./Component/FeedbackForm";
import RajyaSabhaMP from "./Component/RajyaSabhaMP";
import RajyaSabhaMPList from "./Component/RajyaSabhaMPList";
import MLAData from "./Component/MLAData";
import MLAList from "./Component/MLAList";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PayNow from "./Component/PayNow";
import ViewMpList from "./Component/ViewMpList";
import ViewRajyaSabhaMP from "./Component/ViewRajyaSabhaMP";
import Poll from "./Component/Poll";
import CreatePoll from "./Component/CreatePoll";
import Data from "./Component/Data";
function App() {
  const [allproduct2, setAllProduct2] = useState();
  const productdata = JSON.parse(localStorage.getItem("productdata"));
  const { setSelectedProduct } = useContext(ProductContext);

  const filteredMLA = productdata?.response?.records.filter((item) =>
    item.find((field) => field.name === "Category" && field.value === "MLA")
  );
  const sortMLAData = (templateID) => {
    let dataName = [...filteredMLA];
    if (dataName?.length > 0) {
      let resultFilterName = dataName.filter(
        (value) => value.templateID === templateID
      );
      setAllProduct2(resultFilterName);
      // localStorage.setItem("AllData", JSON.stringify(resultFilterName));
    }
  };
  const handleItemClick = (ChiefMinisterCM) => {
    setSelectedProduct(ChiefMinisterCM); // Save the selected product details in the context state.
    localStorage.setItem("SingleData", JSON.stringify(ChiefMinisterCM));
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="App">
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        className="thankyou-popup"
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" className="thank-1">
          {"Thank You"}
          <span className="close-555">
            <IconButton
              className="close-button-23"
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon className="close-button-22" />
            </IconButton>
          </span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText className="thank-2">
            Your Form Data has been submitted.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Link to="/" className="popup-10">
            <Button autoFocus onClick={handleClose}>
              Go To Home Page
            </Button>
          </Link>
        </DialogActions>
      </Dialog>
      <Routes>
        <Route
          path="/"
          element={
            <Home
              filteredMLA={filteredMLA}
              sortMLAData={sortMLAData}
              handleItemClick={handleItemClick}
            />
          }
        />

        <Route path="/see-all/:value" element={<SeeAll />} />
        <Route path="/mplist/:stateName" element={<MPList />} />
        <Route
          path="/see-all-mla/:stateName"
          element={<SeeAllMLA allproduct2={allproduct2} />}
        />
        <Route
          path="/viewmplist/:value"
          element={<ViewMpList allproduct2={allproduct2} />}
        />
        <Route
          path="/viewrajyasabhamp/:value"
          element={<ViewRajyaSabhaMP allproduct2={allproduct2} />}
        />

        <Route path="/details/:value/:value" element={<Details />} />

        <Route path="/profileview/:value/:value" element={<ProfileView />} />

        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/service" element={<Service />} />
        <Route path="/surveys" element={<Surveys />} />
        <Route
          path="/survey-form"
          element={<SurveyForm handleClickOpen={handleClickOpen} />}
        />
        <Route
          path="/contact-us"
          element={<ContactUs handleClickOpen={handleClickOpen} />}
        />
        <Route
          path="/chief-minister"
          element={<ChiefMinister handleClickOpen={handleClickOpen} />}
        />

        <Route
          path="/lok-sabha"
          element={<LokSabha handleClickOpen={handleClickOpen} />}
        />
        <Route
          path="/rajya-sabha"
          element={<RajyaSabha handleClickOpen={handleClickOpen} />}
        />
        <Route
          path="/mla"
          element={<MLA handleClickOpen={handleClickOpen} />}
        />
        <Route
          path="/councillor"
          element={<Councillor handleClickOpen={handleClickOpen} />}
        />

        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-condition" element={<TermsCondition />} />
        <Route
          path="/political-report-card"
          element={<PoliticalCardPreview />}
        />
        <Route path="/our-heroes/:value" element={<OurHeroes />} />
        <Route path="/our-heroes-list" element={<OurHerosList />} />

        <Route
          path="/signup"
          element={<Signup handleClickOpen={handleClickOpen} />}
        />
        <Route path="/login" element={<Login />} />
        <Route
          path="/feedback-form"
          element={<FeedbackForm handleClickOpen={handleClickOpen} />}
        />
        <Route path="/rajyasabhamp" element={<RajyaSabhaMP />} />
        <Route
          path="/rajyasabhamplist/:stateName"
          element={<RajyaSabhaMPList />}
        />
        <Route
          path="/mladata"
          element={
            <MLAData
              filteredMLA={filteredMLA}
              sortMLAData={sortMLAData}
              handleItemClick={handleItemClick}
            />
          }
        />

        <Route path="/mlaList/:stateName" element={<MLAList />} />
        <Route path="/paynow" element={<PayNow />} />
        <Route path="/poll/:title" element={<Poll />} />
        <Route path="/createpoll" element={<CreatePoll />} />
        <Route path="/data/:name" element={<Data />} />
      </Routes>
    </div>
  );
}

export default App;
