import React, { useEffect, useContext, useState } from "react";
import { FallbackImage } from './FallbackImage';
import { Link, useParams } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";

import { ProductContext } from './context/AppContext';
function CabinetMinister() {
    const [allproduct2, setAllProduct2] = useState();

    const { OurMinistersData, setSelectedProduct } = useContext(ProductContext);
   
    const filteredCabinetMinister = OurMinistersData?.response?.records.filter(
        (item) =>
          item.find(
            (field) =>
              field.name === "Category" && field.value === "Cabinet Ministers"
          )
      );
      const filteredDataMajorParty = OurMinistersData?.response?.records.filter(
        (item) =>
          item.find(
            (field) =>
              field.name === "Category" && field.value === "Major Political Parties"
          )
      );
        
      const sortMainCabinet = (templateID) => {
        let dataName = [...filteredCabinetMinister];
        if (dataName?.length > 0) {
          let resultFilterName = dataName.filter(
            (value) => value.templateID === templateID
          );
          setAllProduct2(resultFilterName);
          localStorage.setItem("AllData", JSON.stringify(resultFilterName));
        }
      };
     
  const handleItemClick2 = (ChiefMinisterCM) => {
    setSelectedProduct(ChiefMinisterCM); // Save the selected product details in the context state.
    localStorage.setItem("SingleData", JSON.stringify(ChiefMinisterCM));
  };
  return (
   <>
   <div className="bbb_viewed">
          <div className="">
            <div className="">
              <div className="">
                <div className="">
                  <div className="bbb_viewed_title_container">
                    <h3 className="bbb_viewed_title">
                      {filteredCabinetMinister?.[0][3].value}

                      <span>
                        <Link to={`../see-all/${filteredCabinetMinister?.[0][3].value.replace(/ /g, '-')}`}   >
                          <span
                            onClick={() =>
                              sortMainCabinet(
                                filteredCabinetMinister?.templateID
                              )
                            }
                          >
                            See All
                          </span>
                        </Link>
                      </span>
                    </h3>
                  </div>
                  <div className="bbb_viewed_slider_container">
                  {/* <OwlCarousel className='owl-theme' loop margin={5} items={10}
                  center = {true}
                  autoWidth={true}
                  dots={false}  nav autoplay>   */}
                    <OwlCarousel
                      className="owl-theme"
                      loop
                      margin={5}
                      nav
                      items={7}
                      dots={false}
                      autoWidth={true}
                    >
                      {filteredCabinetMinister?.map(
                        (ChiefMinisterCM, index) => {
                          return (
                            <div className="item item1">
                      <Link to={`../details/${ChiefMinisterCM?.[3]?.value.replace(/ /g, '-')}/${ChiefMinisterCM?.[5]?.value.replace(/ /g, '-')}`} 
                      onClick={() => handleItemClick2(ChiefMinisterCM)}>



                              {/* <Link
                                to={"../details/"}
                                onClick={() =>
                                  handleItemClick2(ChiefMinisterCM)
                                }
                              > */}
                                {/* <Link to={ "../details/"} > */}
                                <div
                                  // onClick={() =>handleItemClick2(cabinet)}
                                  className="bbb_viewed_item discount d-flex flex-column align-items-center justify-content-center text-center"
                                >
                                  <div className="bbb_viewed_image">
                                    <FallbackImage
                                      src={ChiefMinisterCM?.[4]?.value}
                                      alt=""
                                    />
                                  </div>
                                  <div className="bbb_viewed_content text-center">
                                    <div className="bbb_viewed_name">
                                    <Link to={`../details/${ChiefMinisterCM?.[3]?.value.replace(/ /g, '-')}/${ChiefMinisterCM?.[5]?.value.replace(/ /g, '-')}`} 
                      onClick={() => handleItemClick2(ChiefMinisterCM)}>
                                        <h5
                                          className="cm-list1"
                                          onClick={() =>
                                            handleItemClick2(ChiefMinisterCM)
                                          }
                                        >
                                          {ChiefMinisterCM?.[5]?.value}
                                          <br />
                                          {ChiefMinisterCM?.[6]?.value}
                                        </h5>
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          );
                        }
                      )}
                    </OwlCarousel>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bbb_viewed">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <h3 className="mv-gov2">
                  {filteredDataMajorParty?.[0][3]?.value}
                </h3>
              </div>

              {filteredDataMajorParty?.map((ChiefMinisterCM, index) => {
                return (
                  <>
                    <div className="col-sm-2" key={index}>
                    <Link to={`../details/${ChiefMinisterCM?.[3]?.value.replace(/ /g, '-')}/${ChiefMinisterCM?.[5]?.value.replace(/ /g, '-')}`} 
                      onClick={() => handleItemClick2(ChiefMinisterCM)}>

                      {/* <Link
                        to={"../details/"}
                        onClick={() => handleItemClick2(ChiefMinisterCM)}
                      > */}
                        <FallbackImage
                          src={ChiefMinisterCM?.[4]?.value}
                          alt=""
                          width="100%"
                          className="bjp-1"
                        />
                      </Link>
                      <h5 className="mv-gov1 mv-gov110">
                      <Link to={`../details/${ChiefMinisterCM?.[3]?.value.replace(/ /g, '-')}/${ChiefMinisterCM?.[5]?.value.replace(/ /g, '-')}`} 
                      onClick={() => handleItemClick2(ChiefMinisterCM)}>
                          {ChiefMinisterCM?.[5]?.value}
                        </Link>
                      </h5>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
   </>
  )
}


export default CabinetMinister
