import React,{useState, useContext, useEffect} from 'react'
import Header from '../Header'
import Footer from '../Footer'
import { FallbackImage } from './FallbackImage';
import { Link, useParams } from "react-router-dom";
import Banner from './Banner';
import Form from 'react-bootstrap/Form';

function FeedbackForm(props) {
  const{handleClickOpen}=props;

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [updateform, setUpdateForm] = useState([]);
  
  const[Name,setName] = useState();
  const[MobileNo,setMobileNo] = useState();
  const[Email,setEmail] = useState();
  const[Message,setMessage] = useState();
  const[photo,setPhoto] = useState();
  const[selectedState,setSelectedState] = useState();
  const[selectedConstituency,setSelectedConstituency] = useState();

  const signUPpara = {
    templateID: "22",
     "_1": Name,
     "_2": MobileNo,
    "_3": Email,
    "_4": photo,
    "_5": selectedState,
     "_6": selectedConstituency,
     "_7": Message,
  }
  function UpdateFormAPI() {
    fetch('https://api.dialerp.com/api/api/addUpdateTemplateDataWeb', {
      method: "POST",
      headers: {
        "Authorization-Token": "7FaNAN5fgV+7l6gVY6M6vRzNeDPEUJHBDtIibnr1vHQodIJV95ki5i6B/JJCsigzgfA+UO0pJ8Tayd9MfZGV+XLYq26sqtuN7aBKuht7n1kR2AU9Vd37sejq0EZjfLwLVKphTFMzwmWBhcs561HxWQ==",
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(signUPpara),
    }).then((result) => {
      result.json().then((resp) => {
        // console.log(resp, "hello");
        // setUpdateForm(resp);
        // const data55 = upcoming22?.response?.records;
        // window.location.href = "/";
          handleClickOpen();
      });
    });
  }
  const productdata = JSON.parse(localStorage.getItem("productdata"));

  const filteredDataMLA = productdata?.response?.records.filter((item) =>
    item.find((field) => field.name === "Category" && field.value === "MLA")
  );
  const uniqueStateNames = new Set();
  const handleClick = (stateName) => {
    if (selectedState === stateName) {
      // If the same state name is clicked again, hide the data

      setSelectedState(null);
    } else {
      // Otherwise, show the data for the clicked state
      setSelectedState(stateName);
      localStorage.setItem("AllData2", JSON.stringify(stateName));
    }
  };

  const FilterConstituency = filteredDataMLA?.filter((item) =>
    item.find(
      (field) => field.name === "State" && field.value === selectedState
    )
  );
  
  return (
    <div>
      <Header />
      <Banner />
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open responsive dialog
      </Button> */}
      <div className="bbb_viewed footer-1 footer-55 aboutus-2">
  <div className="container">
    <div className="row">
      <div className="col-sm-12">
        <h2 className='aboutus-1'>Problem / Achievement of your Constituency</h2>
      </div>
      
      
          <div className="row">
            <hr />
      
      <div className="col-sm-6 col-md-4">
        <label htmlFor="email">
          <b className='aboutus-1'>Name</b>
        </label>
        <input
          type="text"
          placeholder="Enter Name"
          id="email"
          name="Name"
          value={Name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </div>
      <div className="col-sm-6 col-md-4">
        <label htmlFor="email">
          <b className='aboutus-1'>Mobile Number</b>
        </label>
        <input
          type="text"
          placeholder="Enter Mobile No."
          id="email"
          name="MobileNo"
          value={MobileNo}
          onChange={(e) => setMobileNo(e.target.value)}
          required
        />
      </div>
      
        
      
      <div className="col-sm-6 col-md-4">
        <label htmlFor="email">
          <b className='aboutus-1'>Problem / Achievement</b>
        </label>
        <input
          type="text"
          placeholder="Enter Problem / Achievement"
          required
          id="email"
          name="Age"
          value={Email}
          onChange={(e) => setEmail(e.target.value)} 
        />
      </div>
      <div className="col-sm-6 col-md-4">
        <label htmlFor="email">
          <b className='aboutus-1'>Photo</b>
        </label>
        <input
          type="file"
          placeholder="Enter Problem / Achievement"
          required
          id="email"
          className='photo11'
          name="Age"
          value={photo}
          onChange={(e) => setPhoto(e.target.value)} 
        />
      </div>

      <div className="col-sm-6 col-md-4">
              <label htmlFor="email">
                <b className='aboutus-1'>State</b>
              </label>
              <select name="productCategory" id="txtProductCategory" 
              value={selectedState}
              onChange={(e) => {
                const selectedStateName = e.target.value;
                setSelectedState(selectedStateName);
              }}
               >
                <option value="">--Select State--</option>
                {filteredDataMLA?.map((ChiefMinisterCM, index) => {
                      const stateNameField = ChiefMinisterCM.find(
                        (field) => field.name === "State"
                      );
                      const stateName = stateNameField
                        ? stateNameField.value
                        : "";

                      if (!uniqueStateNames.has(stateName)) {
                        uniqueStateNames.add(stateName);
                        return (
                <option key={index} value={stateName}>{stateName}
                </option>
                 );
                }
                return null;
              })}
              </select>
            </div>

            <div className="col-sm-4">
                  <label htmlFor="psw">
                    <b>Constituency</b>
                  </label>
                  <select
                    value={selectedConstituency}
                    onChange={(e) => setSelectedConstituency(e.target.value)}
                  >
                    <option value="">--Select Constituency--</option>
                    {FilterConstituency?.map((productitem12, i) => {
                      return (
                        <option key={i} value={productitem12?.[24]?.value}>
                          {productitem12?.[24]?.value}
                        </option>
                      );
                    })}
                  </select>
                </div>

           
  <div className="col-sm-6 col-md-4">
        <Form.Label><b className='aboutus-1'>Remarks</b></Form.Label>
        <input
          type="text"
          placeholder="Enter Remarks"
          required
          id="email"
          name="Age"
          value={Message}
          onChange={(e) => setMessage(e.target.value)}
        />
            </div>
      

            <hr />
            {/* {Message?.length > 0 ? ( */}
            <button className="registerbtn" onClick={UpdateFormAPI}>
              Submit
            </button>
            {/* ) : (
<button className="registerbtn">
              Submit
            </button>
            )
} */}

            
           
          </div>
          
       
      
    </div>
  </div>
</div>


      <Footer />
    </div>
  )
}
export default FeedbackForm
