import React,{useState, useContext, useEffect} from 'react'
import Header from '../Header'
import Footer from '../Footer'
import { FallbackImage } from './FallbackImage';
import { Link, useParams } from "react-router-dom";
import Banner from './Banner';
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';

function PayNow(props) {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [updateform, setUpdateForm] = useState([]);
  
  const[Name,setName] = useState();
  const[MobileNo,setMobileNo] = useState();
  const[Email,setEmail] = useState();
  const[Message,setMessage] = useState();

  const signUPpara = {
    templateID: "14",
     "_1": Name,
    "_2": Email,
    "_3": MobileNo	,
    "_4": Message,
  }
  function UpdateFormAPI() {
    fetch('https://api.dialerp.com/api/api/addUpdateTemplateDataWeb', {
      method: "POST",
      headers: {
        "Authorization-Token": "7FaNAN5fgV+7l6gVY6M6vRzNeDPEUJHBDtIibnr1vHQodIJV95ki5i6B/JJCsigzgfA+UO0pJ8Tayd9MfZGV+XLYq26sqtuN7aBKuht7n1kR2AU9Vd37sejq0EZjfLwLVKphTFMzwmWBhcs561HxWQ==",
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(signUPpara),
    }).then((result) => {
      result.json().then((resp) => {
        // setUpdateForm(resp);
        // const data55 = upcoming22?.response?.records;
        //  window.location.href = "/";
      });
    });
  }
  
  return (
    <div>
      <Header />
      <Banner />
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open responsive dialog
      </Button> */}
      <div className="bbb_viewed footer-1 footer-55 aboutus-2">
  <div className="container">
    <div className="row">
      <div className="col-sm-12">
        <h2 className='aboutus-1'>Pay Now</h2>
      </div>
      
      
          <div className="row">
            <hr />
      
      <div className="col-sm-12 col-md-4">
      <img src="../../images/paynow2.jpg" width='100%' />
       
      </div>
      <div className="col-sm-12 col-md-4">
        <div className='box-2'>
        <h2 className='account-heading'>ICICI Bank Account Detail</h2>
        <p>RTGS/NEFT/IMPS</p>
        <p><b>Name : </b> Rajnitti Service</p>
        <p><b>Account : </b> 082905002021</p>
        <p><b>IFSC Code : </b> ICIC0000829</p>
        <p><b>Branch : </b> Vivek Vihar Delhi </p>
        <p><b>Bank : </b> icici Bank Ltd </p>
        <p><b>Account Type : </b> Current </p>
      </div>
      </div>
      <div className="col-sm-12 col-md-4">
      <div className='box-2'>
        <h2 className='account-heading'>HDFC Bank Account Detail</h2>
        <p>RTGS/NEFT/IMPS</p>
        <p><b>Name : </b> Rajnitti Services</p>
        <p><b>Account : </b> 50200076983172</p>
        <p><b>IFSC Code : </b> HDFC0001672</p>
        <p><b>Branch : </b> Vivek Vihar </p>
        <p><b>Bank : </b> HDFC BANK </p>
        <p><b>Account Type : </b> Current </p>
      </div>
      </div>
      
        
      
     
      

        
            
           
          </div>
          
       
      
    </div>
  </div>
</div>
<div className="bbb_viewed footer-1 footer-55 aboutus-2">
  <div className="container box-3">
      
      
          <div className="row">
      
      <div className="col-sm-12 col-md-12">
      <div className="">
  <div className="columns">
    <ul className="price">
      <li className="header">Service 1</li>
      <li>SMS Service</li>
      <li>IVR Service</li>
      <li>WhatsApp Service</li>
      <li>Bulk Voice calls by recorded line</li>
      <li>Facebook Promotion</li>
      <li>Insta Promotion</li>
      <li>Post on Election Website</li>
      <li>Direct voice calls by live agent for vote appeal.</li>
      <li>Online Survey</li>
      <li>Physical Survey</li>
      <li>Live Facebook and Instagram comments by human not by bot.</li>
      <li className="grey">Free</li>
      <li className="">
        <a
          href="https://api.whatsapp.com/send?phone=+919667798002&text=Hi%2CI%20m%20looking%20for%20Pay%20Now%20Service."
          target="_blank"
          className="buttons1"
        >
          WhatsApp
        </a>
      </li>
    </ul>
  </div>
  <div className="columns">
    <ul className="price">
      <li className="header">Service 2</li>
      <li>SMS Service</li>
      <li>IVR Service</li>
      <li>WhatsApp Service</li>
      <li>Bulk Voice calls by recorded line</li>
      <li>Facebook Promotion</li>
      <li>Insta Promotion</li>
      <li>Post on Election Website</li>
      <li>Direct voice calls by live agent for vote appeal.</li>
      <li>Online Survey</li>
      <li>Physical Survey</li>
      <li>Live Facebook and Instagram comments by human not by bot.</li>
      <li className="grey">Rs.2.5 Lakh</li>
      <li className="">
        <a
          href="https://api.whatsapp.com/send?phone=+919667798002&text=Hi%2CI%20m%20looking%20for%20Rs.2.5%20Lakh%20Service."
          target="_blank"
          className="buttons1"
        >
          WhatsApp
        </a>
      </li>
    </ul>
  </div>
  <div className="columns">
    <ul className="price">
      <li className="header">Service 3</li>
      <li>SMS Service</li>
      <li>IVR Service</li>
      <li>WhatsApp Service</li>
      <li>Bulk Voice calls by recorded line</li>
      <li>Facebook Promotion</li>
      <li>Insta Promotion</li>
      <li>Post on Election Website</li>
      <li>Direct voice calls by live agent for vote appeal.</li>
      <li>Online Survey</li>
      <li>Physical Survey</li>
      <li>Live Facebook and Instagram comments by human not by bot.</li>
      <li className="grey">Rs.5 Lakh</li>
      <li className="">
        <a
          href="https://api.whatsapp.com/send?phone=+919667798002&text=Hi%2CI%20m%20looking%20for%20Rs.5%20Lakh%20Service."
          target="_blank"
          className="buttons1"
        >
          WhatsApp
        </a>
      </li>
    </ul>
  </div>
  <div className="columns">
    <ul className="price">
      <li className="header">Service 4</li>
      <li>SMS Service</li>
      <li>IVR Service</li>
      <li>WhatsApp Service</li>
      <li>Bulk Voice calls by recorded line</li>
      <li>Facebook Promotion</li>
      <li>Insta Promotion</li>
      <li>Post on Election Website</li>
      <li>Direct voice calls by live agent for vote appeal.</li>
      <li>Online Survey</li>
      <li>Physical Survey</li>
      <li>Live Facebook and Instagram comments by human not by bot.</li>
      <li className="grey">Rs.10 Lakh</li>
      <li>
        <a
          href="https://api.whatsapp.com/send?phone=+919667798002&text=Hi%2CI%20m%20looking%20for%20Rs.10%20Lakh%20Service."
          target="_blank"
          className="buttons1"
        >
          WhatsApp
        </a>
      </li>
    </ul>
  </div>
</div>
      {/* <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Service 1</Accordion.Header>
        <Accordion.Body>
        <>
  <div className="columns">
    <ul className="price">
      <li className="header">Service 1</li>
      <li>SMS Service</li>
      <li>IVR Service</li>
      <li>WhatsApp Service</li>
      <li>Bulk Voice calls by recorded line</li>
      <li>Facebook Promotion</li>
      <li>Insta Promotion</li>
      <li>Post on Election Website</li>
      <li>Direct voice calls by live agent for vote appeal.</li>
      <li>Online Survey</li>
      <li>Physical Survey</li>
      <li>Live Facebook and Instagram comments by human not by bot.</li>
      <li className="grey">Free</li>
      <li className="">
        <a
          href="https://api.whatsapp.com/send?phone=+919667798002&text=Hi%2CI%20m%20looking%20for%20Pay%20Now%20Service."
          target="_blank"
          className="buttons1"
        >
          WhatsApp
        </a>
      </li>
    </ul>
  </div>
  <div className="columns">
    <ul className="price">
      <li className="header">Service 2</li>
      <li>SMS Service</li>
      <li>IVR Service</li>
      <li>WhatsApp Service</li>
      <li>Bulk Voice calls by recorded line</li>
      <li>Facebook Promotion</li>
      <li>Insta Promotion</li>
      <li>Post on Election Website</li>
      <li>Direct voice calls by live agent for vote appeal.</li>
      <li>Online Survey</li>
      <li>Physical Survey</li>
      <li>Live Facebook and Instagram comments by human not by bot.</li>
      <li className="grey">Rs.2.5 Lakh</li>
      <li className="">
        <a
          href="https://api.whatsapp.com/send?phone=+919667798002&text=Hi%2CI%20m%20looking%20for%20Rs.2.5%20Lakh%20Service."
          target="_blank"
          className="buttons1"
        >
          WhatsApp
        </a>
      </li>
    </ul>
  </div>
  <div className="columns">
    <ul className="price">
      <li className="header">Service 3</li>
      <li>SMS Service</li>
      <li>IVR Service</li>
      <li>WhatsApp Service</li>
      <li>Bulk Voice calls by recorded line</li>
      <li>Facebook Promotion</li>
      <li>Insta Promotion</li>
      <li>Post on Election Website</li>
      <li>Direct voice calls by live agent for vote appeal.</li>
      <li>Online Survey</li>
      <li>Physical Survey</li>
      <li>Live Facebook and Instagram comments by human not by bot.</li>
      <li className="grey">Rs.5 Lakh</li>
      <li className="">
        <a
          href="https://api.whatsapp.com/send?phone=+919667798002&text=Hi%2CI%20m%20looking%20for%20Rs.5%20Lakh%20Service."
          target="_blank"
          className="buttons1"
        >
          WhatsApp
        </a>
      </li>
    </ul>
  </div>
  <div className="columns">
    <ul className="price">
      <li className="header">Service 4</li>
      <li>SMS Service</li>
      <li>IVR Service</li>
      <li>WhatsApp Service</li>
      <li>Bulk Voice calls by recorded line</li>
      <li>Facebook Promotion</li>
      <li>Insta Promotion</li>
      <li>Post on Election Website</li>
      <li>Direct voice calls by live agent for vote appeal.</li>
      <li>Online Survey</li>
      <li>Physical Survey</li>
      <li>Live Facebook and Instagram comments by human not by bot.</li>
      <li className="grey">Rs.10 Lakh</li>
      <li>
        <a
          href="https://api.whatsapp.com/send?phone=+919667798002&text=Hi%2CI%20m%20looking%20for%20Rs.10%20Lakh%20Service."
          target="_blank"
          className="buttons1"
        >
          WhatsApp
        </a>
      </li>
    </ul>
  </div>
</>

        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Service 2</Accordion.Header>
        <Accordion.Body>
        <>
  <div className="columns">
    <ul className="price">
      <li className="header">Service 1</li>
      <li>SMS Service</li>
      <li>IVR Service</li>
      <li>WhatsApp Service</li>
      <li>Bulk Voice calls by recorded line</li>
      <li>Facebook Promotion</li>
      <li>Insta Promotion</li>
      <li>Post on Election Website</li>
      <li>Direct voice calls by live agent for vote appeal.</li>
      <li>Online Survey</li>
      <li>Physical Survey</li>
      <li>Live Facebook and Instagram comments by human not by bot.</li>
      <li className="grey">Free</li>
      <li className="">
        <a
          href="https://api.whatsapp.com/send?phone=+919667798002&text=Hi%2CI%20m%20looking%20for%20Pay%20Now%20Service."
          target="_blank"
          className="buttons1"
        >
          WhatsApp
        </a>
      </li>
    </ul>
  </div>
  <div className="columns">
    <ul className="price">
      <li className="header">Service 2</li>
      <li>SMS Service</li>
      <li>IVR Service</li>
      <li>WhatsApp Service</li>
      <li>Bulk Voice calls by recorded line</li>
      <li>Facebook Promotion</li>
      <li>Insta Promotion</li>
      <li>Post on Election Website</li>
      <li>Direct voice calls by live agent for vote appeal.</li>
      <li>Online Survey</li>
      <li>Physical Survey</li>
      <li>Live Facebook and Instagram comments by human not by bot.</li>
      <li className="grey">Rs.2.5 Lakh</li>
      <li className="">
        <a
          href="https://api.whatsapp.com/send?phone=+919667798002&text=Hi%2CI%20m%20looking%20for%20Rs.2.5%20Lakh%20Service."
          target="_blank"
          className="buttons1"
        >
          WhatsApp
        </a>
      </li>
    </ul>
  </div>
  <div className="columns">
    <ul className="price">
      <li className="header">Service 3</li>
      <li>SMS Service</li>
      <li>IVR Service</li>
      <li>WhatsApp Service</li>
      <li>Bulk Voice calls by recorded line</li>
      <li>Facebook Promotion</li>
      <li>Insta Promotion</li>
      <li>Post on Election Website</li>
      <li>Direct voice calls by live agent for vote appeal.</li>
      <li>Online Survey</li>
      <li>Physical Survey</li>
      <li>Live Facebook and Instagram comments by human not by bot.</li>
      <li className="grey">Rs.5 Lakh</li>
      <li className="">
        <a
          href="https://api.whatsapp.com/send?phone=+919667798002&text=Hi%2CI%20m%20looking%20for%20Rs.5%20Lakh%20Service."
          target="_blank"
          className="buttons1"
        >
          WhatsApp
        </a>
      </li>
    </ul>
  </div>
  <div className="columns">
    <ul className="price">
      <li className="header">Service 4</li>
      <li>SMS Service</li>
      <li>IVR Service</li>
      <li>WhatsApp Service</li>
      <li>Bulk Voice calls by recorded line</li>
      <li>Facebook Promotion</li>
      <li>Insta Promotion</li>
      <li>Post on Election Website</li>
      <li>Direct voice calls by live agent for vote appeal.</li>
      <li>Online Survey</li>
      <li>Physical Survey</li>
      <li>Live Facebook and Instagram comments by human not by bot.</li>
      <li className="grey">Rs.10 Lakh</li>
      <li>
        <a
          href="https://api.whatsapp.com/send?phone=+919667798002&text=Hi%2CI%20m%20looking%20for%20Rs.10%20Lakh%20Service."
          target="_blank"
          className="buttons1"
        >
          WhatsApp
        </a>
      </li>
    </ul>
  </div>
</>

        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Service 3</Accordion.Header>
        <Accordion.Body>
        <>
  <div className="columns">
    <ul className="price">
      <li className="header">Service 1</li>
      <li>SMS Service</li>
      <li>IVR Service</li>
      <li>WhatsApp Service</li>
      <li>Bulk Voice calls by recorded line</li>
      <li>Facebook Promotion</li>
      <li>Insta Promotion</li>
      <li>Post on Election Website</li>
      <li>Direct voice calls by live agent for vote appeal.</li>
      <li>Online Survey</li>
      <li>Physical Survey</li>
      <li>Live Facebook and Instagram comments by human not by bot.</li>
      <li className="grey">Free</li>
      <li className="">
        <a
          href="https://api.whatsapp.com/send?phone=+919667798002&text=Hi%2CI%20m%20looking%20for%20Pay%20Now%20Service."
          target="_blank"
          className="buttons1"
        >
          WhatsApp
        </a>
      </li>
    </ul>
  </div>
  <div className="columns">
    <ul className="price">
      <li className="header">Service 2</li>
      <li>SMS Service</li>
      <li>IVR Service</li>
      <li>WhatsApp Service</li>
      <li>Bulk Voice calls by recorded line</li>
      <li>Facebook Promotion</li>
      <li>Insta Promotion</li>
      <li>Post on Election Website</li>
      <li>Direct voice calls by live agent for vote appeal.</li>
      <li>Online Survey</li>
      <li>Physical Survey</li>
      <li>Live Facebook and Instagram comments by human not by bot.</li>
      <li className="grey">Rs.2.5 Lakh</li>
      <li className="">
        <a
          href="https://api.whatsapp.com/send?phone=+919667798002&text=Hi%2CI%20m%20looking%20for%20Rs.2.5%20Lakh%20Service."
          target="_blank"
          className="buttons1"
        >
          WhatsApp
        </a>
      </li>
    </ul>
  </div>
  <div className="columns">
    <ul className="price">
      <li className="header">Service 3</li>
      <li>SMS Service</li>
      <li>IVR Service</li>
      <li>WhatsApp Service</li>
      <li>Bulk Voice calls by recorded line</li>
      <li>Facebook Promotion</li>
      <li>Insta Promotion</li>
      <li>Post on Election Website</li>
      <li>Direct voice calls by live agent for vote appeal.</li>
      <li>Online Survey</li>
      <li>Physical Survey</li>
      <li>Live Facebook and Instagram comments by human not by bot.</li>
      <li className="grey">Rs.5 Lakh</li>
      <li className="">
        <a
          href="https://api.whatsapp.com/send?phone=+919667798002&text=Hi%2CI%20m%20looking%20for%20Rs.5%20Lakh%20Service."
          target="_blank"
          className="buttons1"
        >
          WhatsApp
        </a>
      </li>
    </ul>
  </div>
  <div className="columns">
    <ul className="price">
      <li className="header">Service 4</li>
      <li>SMS Service</li>
      <li>IVR Service</li>
      <li>WhatsApp Service</li>
      <li>Bulk Voice calls by recorded line</li>
      <li>Facebook Promotion</li>
      <li>Insta Promotion</li>
      <li>Post on Election Website</li>
      <li>Direct voice calls by live agent for vote appeal.</li>
      <li>Online Survey</li>
      <li>Physical Survey</li>
      <li>Live Facebook and Instagram comments by human not by bot.</li>
      <li className="grey">Rs.10 Lakh</li>
      <li>
        <a
          href="https://api.whatsapp.com/send?phone=+919667798002&text=Hi%2CI%20m%20looking%20for%20Rs.10%20Lakh%20Service."
          target="_blank"
          className="buttons1"
        >
          WhatsApp
        </a>
      </li>
    </ul>
  </div>
</>

        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>Service 4</Accordion.Header>
        <Accordion.Body>
        <>
  <div className="columns">
    <ul className="price">
      <li className="header">Service 1</li>
      <li>SMS Service</li>
      <li>IVR Service</li>
      <li>WhatsApp Service</li>
      <li>Bulk Voice calls by recorded line</li>
      <li>Facebook Promotion</li>
      <li>Insta Promotion</li>
      <li>Post on Election Website</li>
      <li>Direct voice calls by live agent for vote appeal.</li>
      <li>Online Survey</li>
      <li>Physical Survey</li>
      <li>Live Facebook and Instagram comments by human not by bot.</li>
      <li className="grey">Free</li>
      <li className="">
        <a
          href="https://api.whatsapp.com/send?phone=+919667798002&text=Hi%2CI%20m%20looking%20for%20Pay%20Now%20Service."
          target="_blank"
          className="buttons1"
        >
          WhatsApp
        </a>
      </li>
    </ul>
  </div>
  <div className="columns">
    <ul className="price">
      <li className="header">Service 2</li>
      <li>SMS Service</li>
      <li>IVR Service</li>
      <li>WhatsApp Service</li>
      <li>Bulk Voice calls by recorded line</li>
      <li>Facebook Promotion</li>
      <li>Insta Promotion</li>
      <li>Post on Election Website</li>
      <li>Direct voice calls by live agent for vote appeal.</li>
      <li>Online Survey</li>
      <li>Physical Survey</li>
      <li>Live Facebook and Instagram comments by human not by bot.</li>
      <li className="grey">Rs.2.5 Lakh</li>
      <li className="">
        <a
          href="https://api.whatsapp.com/send?phone=+919667798002&text=Hi%2CI%20m%20looking%20for%20Rs.2.5%20Lakh%20Service."
          target="_blank"
          className="buttons1"
        >
          WhatsApp
        </a>
      </li>
    </ul>
  </div>
  <div className="columns">
    <ul className="price">
      <li className="header">Service 3</li>
      <li>SMS Service</li>
      <li>IVR Service</li>
      <li>WhatsApp Service</li>
      <li>Bulk Voice calls by recorded line</li>
      <li>Facebook Promotion</li>
      <li>Insta Promotion</li>
      <li>Post on Election Website</li>
      <li>Direct voice calls by live agent for vote appeal.</li>
      <li>Online Survey</li>
      <li>Physical Survey</li>
      <li>Live Facebook and Instagram comments by human not by bot.</li>
      <li className="grey">Rs.5 Lakh</li>
      <li className="">
        <a
          href="https://api.whatsapp.com/send?phone=+919667798002&text=Hi%2CI%20m%20looking%20for%20Rs.5%20Lakh%20Service."
          target="_blank"
          className="buttons1"
        >
          WhatsApp
        </a>
      </li>
    </ul>
  </div>
  <div className="columns">
    <ul className="price">
      <li className="header">Service 4</li>
      <li>SMS Service</li>
      <li>IVR Service</li>
      <li>WhatsApp Service</li>
      <li>Bulk Voice calls by recorded line</li>
      <li>Facebook Promotion</li>
      <li>Insta Promotion</li>
      <li>Post on Election Website</li>
      <li>Direct voice calls by live agent for vote appeal.</li>
      <li>Online Survey</li>
      <li>Physical Survey</li>
      <li>Live Facebook and Instagram comments by human not by bot.</li>
      <li className="grey">Rs.10 Lakh</li>
      <li>
        <a
          href="https://api.whatsapp.com/send?phone=+919667798002&text=Hi%2CI%20m%20looking%20for%20Rs.10%20Lakh%20Service."
          target="_blank"
          className="buttons1"
        >
          WhatsApp
        </a>
      </li>
    </ul>
  </div>
</>

        </Accordion.Body>
      </Accordion.Item>
    </Accordion> */}
       
      </div>
      
        
      
     
      

        
            
           
          </div>
  </div>
</div>



      <Footer />
    </div>
  )
}

export default PayNow
