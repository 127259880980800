import React, { Component, useEffect, useState, useRef, useContext } from "react";
import Header from '../Header'
import Footer from '../Footer'
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link, useParams } from "react-router-dom";
import { FallbackImage } from "./FallbackImage";

import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Form from "react-bootstrap/Form";
import Tabs from "react-bootstrap/Tabs";

import ListGroup from "react-bootstrap/ListGroup";
import Tab from "react-bootstrap/Tab";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import Button from "@mui/material/Button";
import { ProductContext } from './context/AppContext';
import Banner from "./Banner";


function ViewMpList(props) {
  const {templateID} = props;
   const { selectedProduct2, setSelectedProduct, bannerData } = useContext(ProductContext);
   const productdata = JSON.parse(localStorage.getItem("productdata"));

  // const dataprint = useContext(ProductContext);
  //  console.log(allproduct2, "allproduct2 See All");


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
 
 
  const filteredDataLokSabha = productdata?.response?.records.filter((item) =>
  item.find(
    (field) => field.name === "Category" && field.value === "Lok Sabha" 
  )
);
const filteredDataRajyaSabha = productdata?.response?.records.filter((item) =>
  item.find(
    (field) => field.name === "Category" && field.value === "Rajya Sabha" 
  )
);
const uniqueStateNames = new Set();
const[selectedState,setSelectedState]= useState();
const handleClick = (stateName) => {
  if (selectedState === stateName) {
    // If the same state name is clicked again, hide the data

    setSelectedState(null);
  } else {
    // Otherwise, show the data for the clicked state
    setSelectedState(stateName);
    localStorage.setItem("AllData2", JSON.stringify(stateName));
  }
};


  const allproduct2 = JSON.parse(localStorage.getItem("AllData"));


  const handleItemClick = (ChiefMinisterCM) => {
    setSelectedProduct(ChiefMinisterCM); // Save the selected product details in the context state.
      localStorage.setItem('SingleData', JSON.stringify(ChiefMinisterCM));
  };
  return (
    <div className="">
        <Header /> 
        <Banner />
     <div className="bbb_viewed See-all2">
     <div>
      <Row xs={1} md={1} lg={1}>
        <Col>
        <div className="bbb_viewed_title_container">
        <h3 className="bbb_viewed_title">
              {filteredDataLokSabha?.[0][3].value}
              </h3>
           
          </div>
        </Col>
      </Row>
      <Row xs={2} md={4} lg={6}>
      {filteredDataLokSabha?.map((ChiefMinisterCM, index) => {
            const stateNameField = ChiefMinisterCM.find(
              (field) => field.name === "State"
            );
            const stateName = stateNameField ? stateNameField.value : "";

            // Check if the state name is unique and add it to the set
            if (!uniqueStateNames.has(stateName)) {
              uniqueStateNames.add(stateName);
              return (
        <Col>
        <div className="bbb_viewed_item bbb_viewed_item-cabinet-1 discount d-flex flex-column align-items-center justify-content-center text-center">
                <div className="bbb_viewed_image">
                {/* <Link
                          to={`../profileview/${ChiefMinisterCM?.[5]?.value.replace(/ /g, '-')}`}  
                          onClick={() => handleItemClick(ChiefMinisterCM)}
                        > */}

<Link
                to={`../mplist/${stateName.replace(/ /g, '-')}`} // Include state data as a URL parameter
                onClick={() => handleClick(stateName)} // Handle click event
              >

                {/* <Link to={"../profileview/"} onClick={() => handleItemClick(ChiefMinisterCM)}> */}
                <FallbackImage src={ChiefMinisterCM?.[20]?.value} alt="" />
                  </Link>
                </div>
                <div className="bbb_viewed_content text-center">
                  <div className="bbb_viewed_name">
                  <Link
                to={`../mplist/${stateName.replace(/ /g, '-')}`} // Include state data as a URL parameter
                onClick={() => handleClick(stateName)} // Handle click event
              >
                      <h5 className="cm-list1">
                      {stateName}</h5>
                      </Link>
                      {/* <p className="mp-name">{ChiefMinisterCM?.[21]?.value}</p> */}
                  </div>
                </div>
              </div>
        </Col>
       );
    }
    return null; // Skip rendering if it's not unique
  })}
      </Row>
    </div>  
  </div>
      
  <br />
      <br />
      <Footer />
    </div>
  );
}


export default ViewMpList
