import React, { useEffect, useContext, useState } from "react";
import { FallbackImage } from './FallbackImage';
import { Link, useParams } from "react-router-dom";
import { ProductContext } from './context/AppContext';
function WhoIsWho() {
    const [allproduct2, setAllProduct2] = useState();

    const { OurMinistersData, setSelectedProduct } = useContext(ProductContext);
    const filteredDatawhoiswho = OurMinistersData?.response?.records.filter((item) =>
    item.find(
      (field) => field.name === "Category" && field.value === "Who is Who"
    )
  );
  const handleItemClick2 = (ChiefMinisterCM) => {
    setSelectedProduct(ChiefMinisterCM); // Save the selected product details in the context state.
    localStorage.setItem("SingleData", JSON.stringify(ChiefMinisterCM));
  };
  return (
    <div className="bbb_viewed">
    <div className="container-fluid">
    <div className="row">
        <div className="col-lg-12">
          <h3 className="mv-gov2">{filteredDatawhoiswho?.[0][3]?.value}</h3>
        </div>
        
        {filteredDatawhoiswho?.map((ChiefMinisterCM,index)=>{
          return(
            <>

        <div className="col-md-2 col-sm-2 col-xs-6">
          {/* <Link to={ `../details/${ChiefMinisterCM?.[5]?.value}`} onClick={() =>handleItemClick2(ChiefMinisterCM)}> */}

          <Link to={`../details/${ChiefMinisterCM?.[3]?.value.replace(/ /g, '-')}/${ChiefMinisterCM?.[5]?.value.replace(/ /g, '-')}`} onClick={() => handleItemClick2(ChiefMinisterCM)}>

          <FallbackImage src={ChiefMinisterCM?.[4]?.value} alt="" 
              width="100%"
              className="bjp-1" key={index} />
          </Link>
          <h5 className="mv-gov1">
          <Link to={`../details/${ChiefMinisterCM?.[3]?.value.replace(/ /g, '-')}/${ChiefMinisterCM?.[5]?.value.replace(/ /g, '-')}`} onClick={() => handleItemClick2(ChiefMinisterCM)}>
              {ChiefMinisterCM?.[5]?.value}</Link>
          </h5>
          <h4 className="mv-gov">
          <Link to={`../details/${ChiefMinisterCM?.[3]?.value.replace(/ /g, '-')}/${ChiefMinisterCM?.[5]?.value.replace(/ /g, '-')}`} onClick={() => handleItemClick2(ChiefMinisterCM)}>
            {ChiefMinisterCM?.[6]?.value}</Link>
          </h4>
        </div>
        </>
          )
        })
        }
        
      </div>
    </div>
  </div>
  )
}

export default WhoIsWho
