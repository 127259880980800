import React, { useEffect, useContext, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import Header from "../Header";
import { ProductContext } from "./context/AppContext";
import { FallbackImage } from "./FallbackImage";

function Service1() {
    const { OurMinistersData } = useContext(ProductContext);
  const [allproduct2, setAllProduct2] = useState();
    const filteredDataOurExpertize = OurMinistersData?.response?.records.filter(
        (item) =>
          item.find(
            (field) => field.name === "Category" && field.value === "Our Expertize"
          )
      );
      useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }, []);
  return (
    <section className="gateway-services bbb_viewed bbb_viewed-1 bbb_viewed-2">
    <div
      className="container-fluid aos-init aos-animate"
      data-aos="fade-up"
    >
      <div className="row">
        <div className="col-lg-12 col-md-12">
          <h3 className="bbb_viewed_title" style={{ textAlign: "center" }}>
            {filteredDataOurExpertize?.[0][3]?.value}
          </h3>
        </div>
        {filteredDataOurExpertize?.map((expertize, index) => {
          return (
            <div className="col-lg-4 col-md-4 mt-3" key={index}>
              <div className="mv-11">
                <div className="mv-hover-class">
                  <i className="fa fa-hand-o-right" />
                  <p>{expertize?.[8]?.value} </p>
                  <h4 className="mv-hover-class-h4">
                    {expertize?.[5]?.value}
                  </h4>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  </section>
  )
}

export default Service1
