import React, { Component, useEffect, useState, useContext } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import Header from "../Header";
import Banner from "./Banner";
import AboutUs1 from "./AboutUs1";
import Footer from "../Footer";
import { Link, useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { FallbackImage } from "./FallbackImage";
import Container from "react-bootstrap/Container";
import CountdownTimer from "react-component-countdown-timer";
import { ProductContext } from "./context/AppContext";
import UpcomingElection from "./UpcomingElection";
import LetestVideo from "./LetestVideo";
import Mp from "./Mp";
import LetestNews from "./LetestNews";
import PartyWiseLeaders from "./PartyWiseLeaders";
import WhoIsWho from "./WhoIsWho";
import CabinetMinister from "./CabinetMinister";
import Banner1 from "./Banner1";
import Banner2 from "./Banner2";
import Banner3 from "./Banner3";
import CMList from "./CMList";
import Banner4 from "./Banner4";
import Banner5 from "./Banner5";
import RajyaSabhaMP from "./RajyaSabhaMP";
import RajyaSabhaMPList from "./RajyaSabhaMPList";
import MLAData from "./MLAData";
import OurHereosList from "./OurHereosList";
function Home(props) {
  const{sortMLAData}= props;
  const{filteredMLA}= props;
  const{handleItemClick}=props;
  const { setSelectedProduct } = useContext(ProductContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {/* <Header /> */}
      <div>
        <Header />
        <Banner />

        {/* <CMList /> */}
        <LetestVideo />
        <OurHereosList />
        <Banner4 />
        <Banner1 />
        <Banner2 />
        <Banner3 />

        <WhoIsWho />
        <CabinetMinister />

        <AboutUs1 />
        <Mp />
        <RajyaSabhaMP />

        <MLAData />


        <PartyWiseLeaders />

        <LetestNews />
        <UpcomingElection />

        <Footer />
      </div>
    </div>
  );
}

export default Home;
