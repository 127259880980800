import React, { useContext } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { FallbackImage } from "./FallbackImage";
import { ProductContext } from "./context/AppContext";
import { DummyBanner } from "./DummyBanner";

function Banner4() {
    const { bannerData } = useContext(ProductContext);
  const Mannkibaat = bannerData?.response?.records.filter((item) =>
    item.find(
      (field) => field.name === "Title" && field.value === "Our Heroes"
    )
  );
  return (
    <div className="bbb_viewed">
      <div className="container-fluid">
        {Mannkibaat ? (
        <div className="row">
          <h3 className="mv-gov2">Our Heroes</h3>
          <Link to="/our-heroes-list">
            <img src={Mannkibaat?.[0][5]?.value} width="100%" />
          </Link>
        </div>
        ) : (
<></>
        )
}
      </div>
    </div>
  )
}

export default Banner4
