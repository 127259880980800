import React, { Component, useEffect, useState, useRef, useContext } from "react";
import Header from '../Header'
import Footer from '../Footer'
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link, useParams } from "react-router-dom";
import { FallbackImage } from "./FallbackImage";

import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Form from "react-bootstrap/Form";
import Tabs from "react-bootstrap/Tabs";

import ListGroup from "react-bootstrap/ListGroup";
import Tab from "react-bootstrap/Tab";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import Button from "@mui/material/Button";
import { ProductContext } from './context/AppContext';
import Banner from "./Banner";


function SeeAll(props) {
  const {templateID} = props;
   const { selectedProduct2, setSelectedProduct, bannerData } = useContext(ProductContext);

  // const dataprint = useContext(ProductContext);
  //  console.log(allproduct2, "allproduct2 See All");


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
 
 
  const allproduct2 = JSON.parse(localStorage.getItem("AllData"));


  const handleItemClick = (ChiefMinisterCM) => {
    setSelectedProduct(ChiefMinisterCM); // Save the selected product details in the context state.
      localStorage.setItem('SingleData', JSON.stringify(ChiefMinisterCM));
  };
  return (
    <div className="">
        <Header /> 
        <Banner />
                   
     <div className="bbb_viewed See-all2">
     <div>
      <Row xs={1} md={1} lg={1}>
        <Col>
        <div className="bbb_viewed_title_container">
        <h3 className="bbb_viewed_title">
              {allproduct2?.[0][3].value}
              </h3>
           
          </div>
        </Col>
      </Row>
      <Row xs={2} md={4} lg={6}>
      {allproduct2?.map((productitem12, i) => {
                        return (
        <Col>
        <div className="bbb_viewed_item bbb_viewed_item-cabinet-1 discount d-flex flex-column align-items-center justify-content-center text-center">
                <div className="bbb_viewed_image">
                <Link
                          to={`../profileview/${productitem12?.[3]?.value.replace(/ /g, '-')}/${productitem12?.[5]?.value.replace(/ /g, '-')}`}  
                          onClick={() => handleItemClick(productitem12)}
                        >

                {/* <Link to={"../profileview/"} onClick={() => handleItemClick(productitem12)}> */}
                <FallbackImage src={productitem12?.[4]?.value} alt="" />
                  </Link>
                </div>
                <div className="bbb_viewed_content text-center">
                  <div className="bbb_viewed_name">
                  <Link
                          to={`../profileview/${productitem12?.[5]?.value.replace(/ /g, '-')}`}  
                          onClick={() => handleItemClick(productitem12)}
                        >
                      <h5 className="cm-list1">
                      {productitem12?.[5]?.value}
                      <br />{productitem12?.[21]?.value}  {productitem12?.[6]?.value}</h5>
                      </Link>
                      {/* <p className="mp-name">{productitem12?.[21]?.value}</p> */}
                  </div>
                </div>
              </div>
        </Col>
        );
        })}
      </Row>
    </div>  
  </div>
  <br />
      <br />
      <Footer />
    </div>
  );
}


export default SeeAll
