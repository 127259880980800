import React, { createContext, useContext, useState, useEffect } from "react";

const ProductContext = createContext();

function AppContext({ children }) {
  const [selectedProduct, setSelectedProduct] = useState(null);
  let authToken = `${process.env.REACT_APP_BASE_AUTHKEY}`;

  const [upcoming22, setUpcoming22] = useState([]);
  const upcoming21 = {
    NumberOfFieldsView: 50,
    filters: {},
    isDownload: false,
    index: 1,
    pgSize: 100,
    templateID: 7,
  };
  function UpComingData1() {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/api/getTemplateDataList`, {
      method: "POST",
      headers: {
        "Authorization-Token": authToken,
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(upcoming21),
    }).then((result) => {
      result.json().then((resp) => {
        setUpcoming22(resp);
        // const data55 = upcoming22?.response?.records;
          // console.log(resp, "Test upcoming Use");
      });
    });
  }

  const [Formfield, setFormFiels] = useState([]);
  const formfield1 = {
    NumberOfFieldsView: 50,
    filters: {},
    isDownload: false,
    index: 1,
    pgSize: 100,
    templateID: 10,
  };
  function FormFieldAPI() {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/api/getTemplateDataList`, {
      method: "POST",
      headers: {
        "Authorization-Token": "7FaNAN5fgV+7l6gVY6M6vRzNeDPEUJHBDtIibnr1vHQodIJV95ki5i6B/JJCsigzgfA+UO0pJ8Tayd9MfZGV+XLYq26sqtuN7aBKuht7n1kR2AU9Vd37sejq0EZjfLwLVKphTFMzwmWBhcs561HxWQ==",
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(formfield1),
    }).then((result) => {
      result.json().then((resp) => {
        setFormFiels(resp);
        // const data55 = upcoming22?.response?.records;
          //  console.log(resp, "Test FormFieldAPI");
      });
    });
  }

  const [FormfieldCm, setFormFielsCM] = useState([]);
  const formfieldCM1 = {
    NumberOfFieldsView: 50,
    filters: {},
    isDownload: false,
    index: 1,
    pgSize: 100,
    templateID: 14,
  };
  function CMFormFieldAPI() {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/api/getTemplateDataList`, {
      method: "POST",
      headers: {
        "Authorization-Token": "7FaNAN5fgV+7l6gVY6M6vRzNeDPEUJHBDtIibnr1vHQodIJV95ki5i6B/JJCsigzgfA+UO0pJ8Tayd9MfZGV+XLYq26sqtuN7aBKuht7n1kR2AU9Vd37sejq0EZjfLwLVKphTFMzwmWBhcs561HxWQ==",
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(formfieldCM1),
    }).then((result) => {
      result.json().then((resp) => {
        setFormFielsCM(resp);
        // const data55 = upcoming22?.response?.records;
      });
    });
  }
  const [bannerData, setBannerData] = useState([]);
  const bannerdata2 = {
    NumberOfFieldsView: 50,
    filters: {},
    isDownload: false,
    index: 1,
    pgSize: 10,
    templateID: 6,
  };
  function BannerData1() {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/api/getTemplateDataList`, {
      method: "POST",
      headers: {
        "Authorization-Token": authToken,
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(bannerdata2),
    }).then((result) => {
      result.json().then((resp) => {
        setBannerData(resp);
          console.log(resp, "Test setBannerData Store");
      });
    });
  }
  const [OurMinistersData, setOurMinistersData] = useState([]);
  const ministerdata2 = {
    NumberOfFieldsView: 50,
    filters: {},
    isDownload: false,
    index: 1,
    pgSize: 100,
    templateID: 4,
  };
  function OurMinisterData1() {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/api/getTemplateDataList`, {
      method: "POST",
      headers: {
        "Authorization-Token": authToken,
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(ministerdata2),
    }).then((result) => {
      result.json().then((resp) => {
        setOurMinistersData(resp);
          // console.log(resp, "Test OurMinistersData");
      });
    });
  }
  const [politicalData, setPoliticalData] = useState([]);
  const politicaldata2 = {
    NumberOfFieldsView: 50,
    filters: {},
    isDownload: false,
    index: 1,
    pgSize: 100,
    templateID: 9,
  };
  function PoliticalData1() {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/api/getTemplateDataList`, {
      method: "POST",
      headers: {
        "Authorization-Token": authToken,
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(politicaldata2),
    }).then((result) => {
      result.json().then((resp) => {
        setPoliticalData(resp);
      });
    });
  }

  const [aboutus5, setAboutUs5] = useState([]);
  const aboutus2 = {
    NumberOfFieldsView: 50,
    filters: {},
    isDownload: false,
    index: 1,
    pgSize: 100,
    templateID: 12,
  };
  function AboutUsData1() {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/api/getTemplateDataList`, {
      method: "POST",
      headers: {
        "Authorization-Token": authToken,
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(aboutus2),
    }).then((result) => {
      result.json().then((resp) => {
        setAboutUs5(resp);
      });
    });
  }

  const backpackdata1 = {
    NumberOfFieldsView: 50,
    filters: {},
    isDownload: false,
    index: 1,
    pgSize: 5500,
    templateID: 2,
  };
const [productdata, setProductData] = useState([]);

console.log(productdata, "productdata255");

// async function BackpackData(page = 1, ) {
//   const pgSize = 100;
//   let userID = "";
//   let totalProducts = 0;

 

//   try {
//     const response = await fetch(
//       `${process.env.REACT_APP_BASE_URL}/api/api/getTemplateDataList`,
//       {
//         method: "POST",
//         headers: {
//           "Authorization-Token": authToken,
//           Accept: "application/json, text/plain",
//           "Content-Type": "application/json;charset=UTF-8",
//         },
//         body: JSON.stringify({
//           ...backpackdata1,
//           index: page,
//         }),
//       }
//     );

//     const resp = await response.json();
//     // console.log(resp, "Hello resp");
//     totalProducts += resp?.response?.records?.length || 0;
//     setProductData(resp);
  

//     if (resp?.response?.records?.length === pgSize) {
//       //  console.log("Fetched page:", page);
//       totalProducts += await BackpackData(page + 1, productdata);
//     } else {
//       //  console.log("All products fetched!");
//     }

//   } catch (error) {
//     // console.error("Error fetching data:", error);
//   }
//   return { total: totalProducts, products: productdata };
// }

function BackpackData() {
  fetch(`${process.env.REACT_APP_BASE_URL}/api/api/getTemplateDataList`, {
    method: "POST",
    headers: {
      "Authorization-Token": authToken,
      Accept: "application/json, text/plain",
      "Content-Type": "application/json;charset=UTF-8",
    },
    body: JSON.stringify(backpackdata1),
  }).then((result) => {
    result.json().then((resp) => {
      setProductData(resp);
      localStorage.setItem("productdata", JSON.stringify(resp));

    });
  });
}
const [videoData, setVideoData] = useState([]);
  const videodata2 = {
    NumberOfFieldsView: 50,
    filters: {},
    isDownload: false,
    index: 1,
    pgSize: 10,
    templateID: 3,
  };
  function VideoData1() {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/api/getTemplateDataList`, {
      method: "POST",
      headers: {
        "Authorization-Token": authToken,
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(videodata2),
    }).then((result) => {
      result.json().then((resp) => {
        setVideoData(resp);
        //  console.log(resp, "Test setVideoData");
      });
    });
  }
  const [letestNews, setLetestNews] = useState([]);
  const letestNews2 = {
    NumberOfFieldsView: 50,
    filters: {},
    isDownload: false,
    index: 1,
    pgSize: 100,
    templateID: 8,
  };
  function LetestNews1() {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/api/getTemplateDataList`, {
      method: "POST",
      headers: {
        "Authorization-Token": authToken,
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(letestNews2),
    }).then((result) => {
      result.json().then((resp) => {
        setLetestNews(resp);
      });
    });
  }

  const [Surveys1, setSurvey1] = useState([]);
  const surveys2 = {
    NumberOfFieldsView: 50,
    filters: {},
    isDownload: false,
    index: 1,
    pgSize: 100,
    templateID: 15,
  };
  function SurveyAPI() {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/api/getTemplateDataList`, {
      method: "POST",
      headers: {
        "Authorization-Token": authToken,
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(surveys2),
    }).then((result) => {
      result.json().then((resp) => {
        setSurvey1(resp);
      });
    });
  }

  const [headerIcon, setHeader1] = useState([]);
  const header2 = {
    NumberOfFieldsView: 50,
    filters: {},
    isDownload: false,
    index: 1,
    pgSize: 100,
    templateID: 18,
  };
  function HeaderAPI() {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/api/getTemplateDataList`, {
      method: "POST",
      headers: {
        "Authorization-Token": authToken,
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(header2),
    }).then((result) => {
      result.json().then((resp) => {
        setHeader1(resp);
      });
    });
  }
  const [FooerService, setFooerService] = useState([]);
  const footer2 = {
    NumberOfFieldsView: 50,
    filters: {},
    isDownload: false,
    index: 1,
    pgSize: 100,
    templateID: 20,
  };
  function FooerServiceAPI() {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/api/getTemplateDataList`, {
      method: "POST",
      headers: {
        "Authorization-Token": "7FaNAN5fgV+7l6gVY6M6vRzNeDPEUJHBDtIibnr1vHQodIJV95ki5i6B/JJCsigzgfA+UO0pJ8Tayd9MfZGV+XLYq26sqtuN7aBKuht7n1kR2AU9Vd37sejq0EZjfLwLVKphTFMzwmWBhcs561HxWQ==",
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(footer2),
    }).then((result) => {
      result.json().then((resp) => {
        setFooerService(resp);
      });
    });
  }

  const [headerMenu22, setHeaderMenu22] = useState([]);
  const headermenu21 = {
    // NumberOfFieldsView: 50,
    // filters: {},
    // isDownload: false,
    // index: 1,
    // pgSize: 100,
    templateID: 19,
  };
  function HeaderMenuAPINew() {
    fetch("https://api.dialerp.com/api/api/getTemplateFieldsList", {
      method: "POST",
      headers: {
        "Authorization-Token": "7FaNAN5fgV+7l6gVY6M6vRzNeDPEUJHBDtIibnr1vHQodIJV95ki5i6B/JJCsigzgfA+UO0pJ8Tayd9MfZGV+XLYq26sqtuN7aBKuht7n1kR2AU9Vd37sejq0EZjfLwLVKphTFMzwmWBhcs561HxWQ==",
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(headermenu21),
    }).then((result) => {
      result.json().then((resp) => {
        setHeaderMenu22(resp);
      });
    });
  }

  const [Poll11, setPoll11] = useState([]);
  const poll2 = {
    NumberOfFieldsView: 50,
    filters: {},
    isDownload: false,
    index: 1,
    pgSize: 100,
    templateID: 23,
  };
  function PollAPI() {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/api/getTemplateDataList`, {
      method: "POST",
      headers: {
        "Authorization-Token": "7FaNAN5fgV+7l6gVY6M6vRzNeDPEUJHBDtIibnr1vHQodIJV95ki5i6B/JJCsigzgfA+UO0pJ8Tayd9MfZGV+XLYq26sqtuN7aBKuht7n1kR2AU9Vd37sejq0EZjfLwLVKphTFMzwmWBhcs561HxWQ==",
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(poll2),
    }).then((result) => {
      result.json().then((resp) => {
        setPoll11(resp);
         console.log(resp, "poll test");
      });
    });
  }

  const [CreatePoll11, setCreatePoll11] = useState([]);
  const createpoll2 = {
    NumberOfFieldsView: 50,
    filters: {},
    isDownload: false,
    index: 1,
    pgSize: 100,
    templateID: 25,
  };
  function CreatePollGetAPI() {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/api/getTemplateDataList`, {
      method: "POST",
      headers: {
        "Authorization-Token": "7FaNAN5fgV+7l6gVY6M6vRzNeDPEUJHBDtIibnr1vHQodIJV95ki5i6B/JJCsigzgfA+UO0pJ8Tayd9MfZGV+XLYq26sqtuN7aBKuht7n1kR2AU9Vd37sejq0EZjfLwLVKphTFMzwmWBhcs561HxWQ==",
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(createpoll2),
    }).then((result) => {
      result.json().then((resp) => {
        setCreatePoll11(resp);
        // localStorage.setItem("createpoll1", JSON.stringify(resp));

         console.log(resp, "CreatePollGetAPI Check");
      });
    });
  }
  
  useEffect(() => {
    UpComingData1();
    FormFieldAPI();
    BannerData1();
    OurMinisterData1();
    LetestNews1();
    BackpackData();
    VideoData1();
    PoliticalData1();
    CMFormFieldAPI();
    AboutUsData1();
    SurveyAPI();
    HeaderAPI();
    FooerServiceAPI();
    HeaderMenuAPINew();
    PollAPI();
    CreatePollGetAPI();
  }, []);

  return (
    <ProductContext.Provider value={{ selectedProduct, setSelectedProduct, upcoming22, 
    bannerData, OurMinistersData, Formfield, productdata, videoData, letestNews, politicalData, aboutus5, FormfieldCm, Surveys1, 
    headerIcon, headerMenu22, FooerService, Poll11, PollAPI, CreatePoll11, CreatePollGetAPI }}>
      {children}
    </ProductContext.Provider>
  );
}

export { ProductContext, AppContext };
