import React, { Component, useEffect, useState, useContext } from "react";
import CountdownTimer from "react-component-countdown-timer";
import { ProductContext } from "./context/AppContext";
import { Link } from "react-router-dom";



function UpcomingElection() {
    const { selectedProduct, setSelectedProduct, upcoming22 } = useContext(ProductContext);
    const end = new Date(2023, 4, 10);
  const diff = Math.floor((end - new Date()) / 1000);
  return (
    <div className="bbb_viewed">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <h3 className="bbb_viewed_title">{upcoming22?.response?.records[0][3]?.value}</h3>
              </div>
              {upcoming22?.response?.records?.map((upcoming1,i)=>{
                return(
                <div className="col-md-3" key={i}>
                <Link to =""
                  className="upcoming-elelction-1"
                  target="_blank"
                >
                  <div className="election-22">
                    <h4>{upcoming1?.[4]?.value}</h4>
                    <p>{upcoming1?.[5]?.value}</p>
                    {/* <p>{upcoming1?.[4]?.value}</p> */}
                  </div>
                </Link>
              </div>
                )
              })
              }
              
            </div>
          </div>
        </div>
  )
}

export default UpcomingElection
