import React, { useContext } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { FallbackImage } from "./FallbackImage";
import { ProductContext } from "./context/AppContext";
import { DummyBanner } from "./DummyBanner";

function Banner1() {
  const { bannerData } = useContext(ProductContext);
  const ModiStory = bannerData?.response?.records.filter((item) =>
    item.find((field) => field.name === "Title" && field.value === "Rajasthan Election")
  );
  // console.log(ModiStory, "ModiStory");
  return (
    
    <div className="bbb_viewed">
      <div className="container-fluid">
      {ModiStory ? (
        <div className="row">
          <h3 className="mv-gov2">{ModiStory?.[0][3]?.value}</h3>
          <a href=""
            target="_blank"
          >
            <DummyBanner src={ModiStory?.[0][5]?.value} width="100%" />
          </a>
        </div>
        ) : (
          <></>
              )}
      </div>
    </div>
    
  );
}

export default Banner1;
