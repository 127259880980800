import React, { useState, useEffect, useContext } from "react";
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ProductContext } from "./context/AppContext";
import { Link, useParams } from "react-router-dom";

function Login() {
    const [age, setAge] = React.useState('');
    const { headerIcon, headerMenu, headerMenu22 } = useContext(ProductContext);

    const handleChange = (event) => {
      setAge(event.target.value);
    };

    const[Name,setName] = useState();
  const[MobileNo,setMobileNo] = useState();
  const[Email,setEmail] = useState();
  const[Ward,setWard] = useState();
   const[Signup,setSignup] = useState();

   const[Gender,setGender] = useState();
   const[Satet1,setState1] = useState();
    const[District,setDistrict] = useState();
    const[Constituency,setConstituency] = useState();
 

  const signUPpara = {
    templateID: "16",
    "_1": Name,
     "_2": MobileNo,
    "_3": Email,
    "_4": Ward,
    "_5": Signup,
    "_6": Gender,
    "_7": Satet1,
    "_8": Constituency,
  }
  function UpdateFormAPI() {
    fetch('https://api.dialerp.com/api/api/addUpdateTemplateDataWeb', {
      method: "POST",
      headers: {
        "Authorization-Token": "7FaNAN5fgV+7l6gVY6M6vRzNeDPEUJHBDtIibnr1vHQodIJV95ki5i6B/JJCsigzgfA+UO0pJ8Tayd9MfZGV+XLYq26sqtuN7aBKuht7n1kR2AU9Vd37sejq0EZjfLwLVKphTFMzwmWBhcs561HxWQ==",
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(signUPpara),
    }).then((result) => {
      result.json().then((resp) => {
        // setUpdateForm(resp);
        // const data55 = upcoming22?.response?.records;
         window.location.href = "/";
        // handleClickOpen();
      });
    });
  }
  return (
    <section id="about" className="about bbb_viewed bbb_viewed-55">
    <div
      className="container-fluid aos-init aos-animate"
      data-aos="fade-up"
    >
      {/* <div class="section-title">
<h2>The Smart Way for Political Campaign</h2>
</div> */}
      <div className="row content-signup1">
        <div className="col-lg-6 col-md-6 content-signup-div1">
        <div className="">
                  <img className="signupimgae" src="../../images/vote.jpg" width="100%" />
                  </div>
        </div>
        <div className="col-lg-6 col-md-6 election-25">
          
          <div className='signup-form2 login-form2'>
          <h4 className="signup-h4">
            Login
          </h4>
          <p>Login to Rajnitti to continue</p>
          
          <TextField  id="outlined-basic" label="Email" variant="outlined" value={Email}
          onChange={(e) => setEmail(e.target.value)} />

<TextField  id="outlined-basic" label="Password" type="password" variant="outlined" value={Name}
          onChange={(e) => setName(e.target.value)} />

      <div className="clearfix">
            <button
              type="button"
              onclick="document.getElementById('id01').style.display='none'"
              className="cancelbtn"
            //   onClick={handleClose}
            >
              Cancel
            </button>
            <button type="button" className="signupbtn" id="btnsave" onClick={UpdateFormAPI}>
              Sign Up
            </button>
          </div>
          <p>If you don't have an account, would you like to <Link to = "/signup">Register right now?</Link></p>


          </div>
        </div>
      </div>
    </div>
  </section>
  )
}
export default Login
