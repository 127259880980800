import React, {Component,useEffect, useState, useContext} from "react";
  import Header from "../Header";
  import Footer from "../Footer";
  import Container from "react-bootstrap/Container";
  import Row from "react-bootstrap/Row";
  import Col from "react-bootstrap/Col";
  import { Link, useParams } from "react-router-dom";
  import { FallbackImage } from "./FallbackImage";
  
  import Dropdown from "react-bootstrap/Dropdown";
  import DropdownButton from "react-bootstrap/DropdownButton";
  import Form from "react-bootstrap/Form";
  import Tabs from "react-bootstrap/Tabs";
  
  import ListGroup from "react-bootstrap/ListGroup";
  import Tab from "react-bootstrap/Tab";
  import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
  import Button from "@mui/material/Button";
  import { ProductContext } from "./context/AppContext";
  import Banner from "./Banner";

  

function OurHeroes() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    
      const ViewData = JSON.parse(localStorage.getItem("SingleData"));
      // console.log(ViewData, "ViewData");
  return (
    <div>
        <Header />
        <Banner />
      <><br />
      <div className="container">
      <div className="row">
        <div className="col-sm-12">
        <h2 className="mv-gov2 mv-h2-55">
          {ViewData?.[3]?.value}</h2>
            </div>
            </div>
            </div>
  <div className="bbb_viewed footer-1 p-card-1">
    <div className="container-fluid">
    
      <div className="row">
        <div className="col-sm-4">
          <FallbackImage className="bjp-1" src={ViewData?.[4]?.value} width="100%" />
        </div>
        <div className="col-sm-8">
          <h2 className="mv-gov2 mv-h2-55">
          {ViewData?.[5]?.value} ({ViewData?.[6]?.value})
          </h2>
          <p style={{ textAlign: "justify" }}>{ViewData?.[7]?.value}</p>
        </div>
      </div>
    </div>
  </div>
  <br />
  <div className="bbb_viewed footer-1 p-card-1">
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-4">
          <FallbackImage className="bjp-1" src={ViewData?.[12]?.value} width="100%" />
        </div>
        <div className="col-sm-8">
          <h2 className="mv-gov2 mv-h2-55">
          {ViewData?.[8]?.value} ( {ViewData?.[9]?.value} )
          </h2>
          <p style={{ textAlign: "justify" }}>{ViewData?.[10]?.value}</p>
        </div>
      </div>
    </div>
  </div>
  <br />
  {ViewData && ViewData[20]?.value ? (
  <div className="bbb_viewed footer-1 p-card-1">
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-4">
          <FallbackImage className="bjp-1" src={ViewData?.[20]?.value} width="100%" />
        </div>
        <div className="col-sm-8">
          <h2 className="mv-gov2 mv-h2-55">
          {ViewData?.[17]?.value} ( {ViewData?.[18]?.value} )
          </h2>
          <p style={{ textAlign: "justify" }}>{ViewData?.[19]?.value}</p>
        </div>
      </div>
    </div>
  </div>
  ) : (
    <></>
  )
}
  <br />
</>

      <Footer />
    </div>
  )
}

export default OurHeroes
