import React, { useContext, useEffect, useState } from "react";
import Header from "../Header";
import { ProductContext } from "./context/AppContext";
import { FallbackImage } from "./FallbackImage";

function ProfileView() {
  const dataprint = useContext(ProductContext);
  const selectedProduct = JSON.parse(localStorage.getItem("SingleData"));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  
  const phoneNumber = selectedProduct[9]?.value;
  const whatsappLink = `https://api.whatsapp.com/send?phone=+91${phoneNumber}&text=Hi%2CPlz%20whats%20app%20for%20any%20help`;

  const mailto1 = selectedProduct[14]?.value;
  const mailLink = `mailto:${mailto1}`;

  return (
    <div>
      <>
        <Header />

        <div className="container smartpage-container">
          <header>
            <FallbackImage
              src={selectedProduct[4]?.value}
              className="avatar"
              alt=""
              title="Mohit Kwatra Ward No-216 ( BJP Party)"
            />
            <div className="bio-title">
              <h1 align="center">
                {selectedProduct[5]?.value}</h1>
                <h2>
                {selectedProduct[24]?.value}  
                <span className="space-22">{selectedProduct[3]?.value}</span><br /> {selectedProduct[7]?.value}
                <span className="space-22">{selectedProduct[6]?.value}</span>
              </h2>
              <div className="bio-description">
                <p>{selectedProduct[15]?.value}</p>
              </div>
            </div>
          </header>
          <ul className="messengers blocks-num-7">
            <li className="links-block">
              <a
                href={whatsappLink}
                className="block-item layout-button"
                style={{ backgroundImage: "" }}
                target="_blank"
              >
                <div className="block-content">
                  <svg
                    aria-labelledby="svg-inline--fa-title-H0NL3oP3UEqs"
                    data-prefix="fab"
                    data-icon="whatsapp"
                    className="svg-inline--fa fa-whatsapp fa-w-14 fa-network"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <title id="svg-inline--fa-title-H0NL3oP3UEqs">
                      whatsapp
                    </title>
                    <path
                      fill="currentColor"
                      d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"
                    />
                  </svg>
                  <span>WhatsApp</span>
                </div>
              </a>
            </li>
            <li className="links-block">
              <a
                href={selectedProduct[11]?.value}
                className="block-item layout-button"
                style={{ backgroundImage: "" }}
                target="_blank"
              >
                <div className="block-content">
                  <svg
                    aria-labelledby="svg-inline--fa-title-gdE8mWiMmbX7"
                    data-prefix="fab"
                    data-icon="facebook"
                    className="svg-inline--fa fa-facebook fa-w-16 fa-network"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <title id="svg-inline--fa-title-gdE8mWiMmbX7">
                      facebook
                    </title>
                    <path
                      fill="currentColor"
                      d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"
                    />
                  </svg>
                  <span>Facebook</span>
                </div>
              </a>
            </li>
            <li className="links-block">
              <a
                href={selectedProduct[10]?.value}
                className="block-item layout-button"
                style={{ backgroundImage: "" }}
              >
                <div className="block-content">
                  <svg
                    aria-labelledby="svg-inline--fa-title-zYo6PV5kBcZK"
                    data-prefix="fas"
                    data-icon="phone"
                    className="svg-inline--fa fa-phone fa-w-16 fa-network"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <title id="svg-inline--fa-title-zYo6PV5kBcZK">call</title>
                    <path
                      fill="currentColor"
                      d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z"
                    />
                  </svg>
                  <span>Phone</span>
                </div>
              </a>
            </li>
            <li className="links-block">
              <a
                href={selectedProduct[12]?.value}
                className="block-item layout-button"
                style={{ backgroundImage: "" }}
                target="_blank"
              >
                <div className="block-content">
                  <svg
                    aria-labelledby="svg-inline--fa-title-6qGU32B7Vzg0"
                    data-prefix="fab"
                    data-icon="instagram"
                    className="svg-inline--fa fa-instagram fa-w-14 fa-network"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <title id="svg-inline--fa-title-6qGU32B7Vzg0">
                      instagram
                    </title>
                    <path
                      fill="currentColor"
                      d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                    />
                  </svg>
                  <span>Instagram</span>
                </div>
              </a>
            </li>
            <li className="links-block">
              <a
                href={selectedProduct[19]?.value}
                className="block-item layout-button"
                style={{ backgroundImage: "" }}
                target="_blank"
              >
                <div className="block-content">
                  <svg
                    aria-labelledby="svg-inline--fa-title-QbiAJUCPRfv1"
                    data-prefix="fab"
                    data-icon="pinterest-p"
                    className="svg-inline--fa fa-pinterest-p fa-w-12 fa-network"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                  >
                    <title id="svg-inline--fa-title-QbiAJUCPRfv1">
                      pinterest
                    </title>
                    <path
                      fill="currentColor"
                      d="M204 6.5C101.4 6.5 0 74.9 0 185.6 0 256 39.6 296 63.6 296c9.9 0 15.6-27.6 15.6-35.4 0-9.3-23.7-29.1-23.7-67.8 0-80.4 61.2-137.4 140.4-137.4 68.1 0 118.5 38.7 118.5 109.8 0 53.1-21.3 152.7-90.3 152.7-24.9 0-46.2-18-46.2-43.8 0-37.8 26.4-74.4 26.4-113.4 0-66.2-93.9-54.2-93.9 25.8 0 16.8 2.1 35.4 9.6 50.7-13.8 59.4-42 147.9-42 209.1 0 18.9 2.7 37.5 4.5 56.4 3.4 3.8 1.7 3.4 6.9 1.5 50.4-69 48.6-82.5 71.4-172.8 12.3 23.4 44.1 36 69.3 36 106.2 0 153.9-103.5 153.9-196.8C384 71.3 298.2 6.5 204 6.5z"
                    />
                  </svg>
                  <span>Pintrest</span>
                </div>
              </a>
            </li>
            <li className="links-block">
              <a
                href={selectedProduct[13]?.value}
                className="block-item layout-button"
                style={{ backgroundImage: "" }}
                target="_blank"
              >
                <div className="block-content">
                  <svg
                    aria-labelledby="svg-inline--fa-title-CruEdZUm6re7"
                    data-prefix="fab"
                    data-icon="youtube"
                    className="svg-inline--fa fa-youtube fa-w-18 fa-network"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                  >
                    <title id="svg-inline--fa-title-CruEdZUm6re7">
                      youtube
                    </title>
                    <path
                      fill="currentColor"
                      d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"
                    />
                  </svg>
                  <span>Youtube</span>
                </div>
              </a>
            </li>
            <li className="links-block">
              <a
                href={selectedProduct[16]?.value}
                className="block-item layout-button"
                style={{ backgroundImage: "" }}
                target="_blank"
              >
                <div className="block-content">
                  <svg
                    aria-labelledby="svg-inline--fa-title-MOwPHPvB7whY"
                    data-prefix="fab"
                    data-icon="twitter"
                    className="svg-inline--fa fa-twitter fa-w-16 fa-network"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <title id="svg-inline--fa-title-MOwPHPvB7whY">
                      twitter
                    </title>
                    <path
                      fill="currentColor"
                      d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                    />
                  </svg>
                  <span>Twitter</span>
                </div>
              </a>
            </li>
            <li className="links-block">
              <a
                href={selectedProduct[15]?.value}
                className="block-item layout-button"
                style={{ backgroundImage: "" }}
                target="_blank"
              >
                <div className="block-content">
                  <svg
                    aria-labelledby="svg-inline--fa-title-l86nWTiOjjan"
                    data-prefix="fab"
                    data-icon="linkedin-in"
                    className="svg-inline--fa fa-linkedin-in fa-w-14 fa-network"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <title id="svg-inline--fa-title-l86nWTiOjjan">
                      linkedin
                    </title>
                    <path
                      fill="currentColor"
                      d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                    />
                  </svg>
                  <span>LinkedIn</span>
                </div>
              </a>
            </li>
            <li className="links-block">
              <a
                href={mailLink}
                className="block-item layout-button"
                style={{ backgroundImage: "" }}
                target="_blank"
              >
                <div className="block-content">
                  <svg
                    aria-labelledby="svg-inline--fa-title-E28RhdMdMiFk"
                    data-prefix="far"
                    data-icon="envelope"
                    className="svg-inline--fa fa-envelope fa-w-16 fa-network"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <title id="svg-inline--fa-title-E28RhdMdMiFk">email</title>
                    <path
                      fill="currentColor"
                      d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z"
                    />
                  </svg>
                  <span>Email</span>
                </div>
              </a>
            </li>
          </ul>
          <ul className="rich-links">
            <li id="block0" className="links-block loading" />
            <li id="block1" className="links-block">
              <div className="block-item layout-text-1">
                <h2 className="block-title text-11">About Us</h2>
                <div className="block-text">
                  <p>{selectedProduct[8]?.value}</p>
                  <p>{selectedProduct[9]?.value}</p>
                  <p>{selectedProduct[10]?.value}</p>
                  <p>{selectedProduct[11]?.value}</p>
                  <p>{selectedProduct[12]?.value}</p>
                  <p>{selectedProduct[13]?.value}</p>
                  <p>{selectedProduct[14]?.value}</p>
                  <p>{selectedProduct[15]?.value}</p>
                  <p>{selectedProduct[16]?.value}</p>
                  <p>{selectedProduct[17]?.value}</p>
                  <p>{selectedProduct[18]?.value}</p>
                  <p>{selectedProduct[19]?.value}</p>
                  <p>{selectedProduct[20]?.value}</p>

                </div>
              </div>
            </li>
            <li id="block2" className="links-block">
              <a
                href="http://www.youtube.com/"
                title="Plz contact us for Support"
                className="block-item layout-button"
                style={{ backgroundImage: "" }}
              >
                <div className="block-content">
                  <span>Vote for Me, Vote for Growth.</span>
                </div>
              </a>
            </li>
          </ul>
          <div className="powered-by-shorby">
            <a
              href="https://api.whatsapp.com/send?phone=+919999365655&text=Hi%2CPlz%20whats%20app%20for%20any%20help"
              target="_blank"
            >
              made by <strong className="domain-logo">Mohit Kwatra</strong>
            </a>
          </div>
        </div>
      </>
    </div>
  );
}

export default ProfileView;
