import React, { useEffect, useContext, useState } from "react";
import { FallbackImage } from './FallbackImage';
import { Link, useParams } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import { ProductContext } from './context/AppContext';
function PartyWiseLeaders() {
    const [allproduct2, setAllProduct2] = useState();

    const {setSelectedProduct } = useContext(ProductContext);
    const productdata = JSON.parse(localStorage.getItem("productdata"));

const filteredAAPPartyLeader = productdata?.response?.records.filter((item) =>
item.find(
  (field) => field.name === "Category" && field.value === "AAP Party Leader"
)
);
const filteredBJPPartyLeader = productdata?.response?.records.filter((item) =>
item.find(
  (field) => field.name === "Category" && field.value === "BJP Party Leader"
)
);



const filteredBSPPartyLeader = productdata?.response?.records.filter((item) =>
item.find(
  (field) => field.name === "Category" && field.value === "BSP Party Leader"
)
);

const filteredCongressPartyLeader = productdata?.response?.records.filter(
(item) =>
  item.find(
    (field) =>
      field.name === "Category" && field.value === "Congress Party Leader"
  )
);

const filteredSamajwadiPartyLeader = productdata?.response?.records.filter(
(item) =>
  item.find(
    (field) =>
      field.name === "Category" && field.value === "Samajwadi Party Leader"
  )
);

const filteredTMCPartyLeader = productdata?.response?.records.filter((item) =>
item.find(
  (field) => field.name === "Category" && field.value === "TMC Party Leader"
)
);
const sortMainAAPParty = (templateID) => {
    let dataName = [...filteredAAPPartyLeader];
    if (dataName?.length > 0) {
      let resultFilterName = dataName.filter(
        (value) => value.templateID === templateID
      );
      setAllProduct2(resultFilterName);
      localStorage.setItem("AllData", JSON.stringify(resultFilterName));
    }
  };
  const sortMainBJPParty = (templateID) => {
    let dataName = [...filteredBJPPartyLeader];
    if (dataName?.length > 0) {
      let resultFilterName = dataName.filter(
        (value) => value.templateID === templateID
      );
      setAllProduct2(resultFilterName);
      localStorage.setItem("AllData", JSON.stringify(resultFilterName));
    }
  };

  const sortMainBSPParty = (templateID) => {
    let dataName = [...filteredBSPPartyLeader];
    if (dataName?.length > 0) {
      let resultFilterName = dataName.filter(
        (value) => value.templateID === templateID
      );
      setAllProduct2(resultFilterName);
      localStorage.setItem("AllData", JSON.stringify(resultFilterName));
    }
  };

  const sortMainCongressParty = (templateID) => {
    let dataName = [...filteredCongressPartyLeader];
    if (dataName?.length > 0) {
      let resultFilterName = dataName.filter(
        (value) => value.templateID === templateID
      );
      setAllProduct2(resultFilterName);
      localStorage.setItem("AllData", JSON.stringify(resultFilterName));
    }
  };

  const sortMainSPAParty = (templateID) => {
    let dataName = [...filteredSamajwadiPartyLeader];
    if (dataName?.length > 0) {
      let resultFilterName = dataName.filter(
        (value) => value.templateID === templateID
      );
      setAllProduct2(resultFilterName);
      localStorage.setItem("AllData", JSON.stringify(resultFilterName));
    }
  };

  const sortMainTMCParty = (templateID) => {
    let dataName = [...filteredTMCPartyLeader];
    if (dataName?.length > 0) {
      let resultFilterName = dataName.filter(
        (value) => value.templateID === templateID
      );
      setAllProduct2(resultFilterName);
      localStorage.setItem("AllData", JSON.stringify(resultFilterName));
    }
  };
  const handleItemClick = (ChiefMinisterCM) => {
    setSelectedProduct(ChiefMinisterCM); // Save the selected product details in the context state.
    localStorage.setItem("SingleData", JSON.stringify(ChiefMinisterCM));
  };
  return (
    <div>
      <div className="bbb_viewed">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12">
                <div className="bbb_viewed_title_container">
                  <h3 className="bbb_viewed_title bbb_viewed_title-leader">
                    {filteredAAPPartyLeader?.[0][3]?.value}

                    <span>
                    <Link to={`../see-all/${filteredAAPPartyLeader?.[0][3].value.replace(/ /g, '-')}`}   >

                        <span
                          onClick={() =>
                            sortMainAAPParty(filteredAAPPartyLeader?.templateID)
                          }
                        >
                          See All
                        </span>
                      </Link>
                    </span>
                  </h3>
                </div>
                <ul className="images-cat">
                  {filteredAAPPartyLeader?.map((aappartylist, index) => {
                    return (
                      <li key={index}>
                        <Link
                          to={`../profileview/${aappartylist?.[3]?.value.replace(/ /g, '-')}/${aappartylist?.[5]?.value.replace(/ /g, '-')}`} 
                          onClick={() => handleItemClick(aappartylist)}
                        >
                          <FallbackImage
                            src={aappartylist?.[4]?.value}
                            alt=""
                          />
                          <Link to={`../profileview/${aappartylist?.[3]?.value.replace(/ /g, '-')}/${aappartylist?.[5]?.value.replace(/ /g, '-')}`} 
                          onClick={() => handleItemClick(aappartylist)}>

                          {/* <Link
                            to={"../profileview"}
                            onClick={() => handleItemClick(aappartylist)}
                          > */}
                            <p
                              className="mp-name1"
                              onClick={() =>
                                sortMainAAPParty(aappartylist)
                              }
                            >
                              {aappartylist?.[5]?.value}
                            </p>
                          </Link>
                          <p className="mp-name">{aappartylist?.[21]?.value}</p>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="bbb_viewed">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12">
                <div className="bbb_viewed_title_container">
                  <h3 className="bbb_viewed_title bbb_viewed_title-leader">
                    {filteredBJPPartyLeader?.[0][3]?.value}

                    <span>
                    <Link to={`../see-all/${filteredBJPPartyLeader?.[0][3].value.replace(/ /g, '-')}`}   >
                        <span
                          onClick={() =>
                            sortMainBJPParty(filteredAAPPartyLeader?.templateID)
                          }
                        >
                          See All
                        </span>
                      </Link>
                    </span>
                  </h3>
                </div>
                <ul className="images-cat">
                  {filteredBJPPartyLeader?.map((aappartylist, index) => {
                    return (
                      <li key={index}>
                        <Link
                          to={`../profileview/${aappartylist?.[3]?.value.replace(/ /g, '-')}/${aappartylist?.[5]?.value.replace(/ /g, '-')}`} 
                          onClick={() => handleItemClick(aappartylist)}
                        >
                          <FallbackImage
                            src={aappartylist?.[4]?.value}
                            alt=""
                          />
                          <Link
                          to={`../profileview/${aappartylist?.[3]?.value.replace(/ /g, '-')}/${aappartylist?.[5]?.value.replace(/ /g, '-')}`} 
                          onClick={() => handleItemClick(aappartylist)}
                        >
                            <p
                              className="mp-name1"
                              onClick={() =>
                                sortMainBJPParty(aappartylist)
                              }
                            >
                              {aappartylist?.[5]?.value}
                            </p>
                          </Link>
                          <p className="mp-name">{aappartylist?.[21]?.value}</p>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="bbb_viewed">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12">
                <div className="bbb_viewed_title_container">
                  <h3 className="bbb_viewed_title bbb_viewed_title-leader">
                    {filteredBSPPartyLeader?.[0][3]?.value}

                    <span>
                    <Link to={`../see-all/${filteredBSPPartyLeader?.[0][3].value.replace(/ /g, '-')}`}   >
                        <span
                          onClick={() =>
                            sortMainBSPParty(filteredBSPPartyLeader?.templateID)
                          }
                        >
                          See All
                        </span>
                      </Link>
                    </span>
                  </h3>
                </div>
                <ul className="images-cat">
                  {filteredBSPPartyLeader?.map((aappartylist, index) => {
                    return (
                      <li key={index}>
                        <Link
                          to={`../profileview/${aappartylist?.[3]?.value.replace(/ /g, '-')}/${aappartylist?.[5]?.value.replace(/ /g, '-')}`} 
                          onClick={() => handleItemClick(aappartylist)}
                        >
                          <FallbackImage
                            src={aappartylist?.[4]?.value}
                            alt=""
                          />
                          <Link
                          to={`../profileview/${aappartylist?.[3]?.value.replace(/ /g, '-')}/${aappartylist?.[5]?.value.replace(/ /g, '-')}`} 
                          onClick={() => handleItemClick(aappartylist)}
                        >
                            <p
                              className="mp-name1"
                              onClick={() =>
                                sortMainBSPParty(aappartylist)
                              }
                            >
                              {aappartylist?.[5]?.value}
                            </p>
                          </Link>
                          <p className="mp-name">{aappartylist?.[21]?.value}</p>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="bbb_viewed">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12">
                <div className="bbb_viewed_title_container">
                  <h3 className="bbb_viewed_title bbb_viewed_title-leader">
                    {filteredCongressPartyLeader?.[0][3]?.value}

                    <span>
                    <Link to={`../see-all/${filteredCongressPartyLeader?.[0][3].value.replace(/ /g, '-')}`}   >
                        <span
                          onClick={() =>
                            sortMainCongressParty(
                              filteredCongressPartyLeader?.templateID
                            )
                          }
                        >
                          See All
                        </span>
                      </Link>
                    </span>
                  </h3>
                </div>
                <ul className="images-cat">
                  {filteredCongressPartyLeader?.map((aappartylist, index) => {
                    return (
                      <li key={index}>
                        <Link
                          to={`../profileview/${aappartylist?.[3]?.value.replace(/ /g, '-')}/${aappartylist?.[5]?.value.replace(/ /g, '-')}`} 
                          onClick={() => handleItemClick(aappartylist)}
                        >
                          <FallbackImage
                            src={aappartylist?.[4]?.value}
                            alt=""
                          />
                          <Link
                          to={`../profileview/${aappartylist?.[3]?.value.replace(/ /g, '-')}/${aappartylist?.[5]?.value.replace(/ /g, '-')}`} 
                          onClick={() => handleItemClick(aappartylist)}
                        >
                            <p
                              className="mp-name1"
                              onClick={() =>
                                sortMainCongressParty(aappartylist)
                              }
                            >
                              {aappartylist?.[5]?.value}
                            </p>
                          </Link>
                          <p className="mp-name">{aappartylist?.[21]?.value}</p>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="bbb_viewed">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12">
                <div className="bbb_viewed_title_container">
                  <h3 className="bbb_viewed_title bbb_viewed_title-leader">
                    {filteredSamajwadiPartyLeader?.[0][3]?.value}

                    <span>
                    <Link to={`../see-all/${filteredSamajwadiPartyLeader?.[0][3].value.replace(/ /g, '-')}`}   >
                        <span
                          onClick={() =>
                            sortMainSPAParty(
                              filteredSamajwadiPartyLeader?.templateID
                            )
                          }
                        >
                          See All
                        </span>
                      </Link>
                    </span>
                  </h3>
                </div>
                <ul className="images-cat">
                  {filteredSamajwadiPartyLeader?.map((aappartylist, index) => {
                    return (
                      <li key={index}>
                        <Link
                          to={`../profileview/${aappartylist?.[3]?.value.replace(/ /g, '-')}/${aappartylist?.[5]?.value.replace(/ /g, '-')}`} 
                          onClick={() => handleItemClick(aappartylist)}
                        >
                          <FallbackImage
                            src={aappartylist?.[4]?.value}
                            alt=""
                          />
                          <Link
                          to={`../profileview/${aappartylist?.[3]?.value.replace(/ /g, '-')}/${aappartylist?.[5]?.value.replace(/ /g, '-')}`} 
                          onClick={() => handleItemClick(aappartylist)}
                        >
                            <p
                              className="mp-name1"
                              onClick={() =>
                                sortMainSPAParty(aappartylist)
                              }
                            >
                              {aappartylist?.[5]?.value}
                            </p>
                          </Link>
                          <p className="mp-name">{aappartylist?.[21]?.value}</p>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="bbb_viewed">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12">
                <div className="bbb_viewed_title_container">
                  <h3 className="bbb_viewed_title bbb_viewed_title-leader">
                    {filteredTMCPartyLeader?.[0][3]?.value}

                    <span>
                    <Link to={`../see-all/${filteredTMCPartyLeader?.[0][3].value.replace(/ /g, '-')}`}   >
                        <span
                          onClick={() =>
                            sortMainTMCParty(filteredTMCPartyLeader?.templateID)
                          }
                        >
                          See All
                        </span>
                      </Link>
                    </span>
                  </h3>
                </div>
                <ul className="images-cat">
                  {filteredTMCPartyLeader?.map((aappartylist, index) => {
                    return (
                      <li key={index}>
                        <Link
                          to={`../profileview/${aappartylist?.[3]?.value.replace(/ /g, '-')}/${aappartylist?.[5]?.value.replace(/ /g, '-')}`} 
                          onClick={() => handleItemClick(aappartylist)}
                        >
                          <FallbackImage
                            src={aappartylist?.[4]?.value}
                            alt=""
                          />
                          <Link
                          to={`../profileview/${aappartylist?.[3]?.value.replace(/ /g, '-')}/${aappartylist?.[5]?.value.replace(/ /g, '-')}`} 
                          onClick={() => handleItemClick(aappartylist)}
                        >
                            <p
                              className="mp-name1"
                              onClick={() =>
                                sortMainTMCParty(aappartylist)
                              }
                            >
                              {aappartylist?.[5]?.value}
                            </p>
                          </Link>
                          <p className="mp-name">{aappartylist?.[21]?.value}</p>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default PartyWiseLeaders
